<div class="modal-header">
  <h4 class="modal-title">Configuración de Asignación Automática de Envíos</h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="settingsForm" class="needs-validation" novalidate>
  <div class="row">
    <div class="form-group col-md-4 col-xs-12">      
      <label for="inputAssignmentTime">Tiempo (en minutos) <span class="text-danger"> * </span><span class="feather icon-info" ngbTooltip="Tiempo de espera para comenzar la AA"></span></label>
      <input type="number" class="form-control" id="inputAssignmentTime" formControlName="assignmentTime">
      <div *ngIf="settingsForm.get('assignmentTime').hasError('required')">
        <small class="text-danger">Campo requerido.</small>
      </div>
      <div *ngIf="settingsForm.get('assignmentTime').hasError('min')">
        <small class="text-danger">Por favor ingrese un número mayor que 0.</small>
      </div>
    </div>
  </div>
  <div class="row flex-grow-1">
    <div class="form-group col-md-6">
      <label for="inputWhiteListDealer">Seleccione repartidores permitidos</label>
      <select class="form-control custom-select-style" id="inputWhiteListDealer" formControlName="whiteListDealer" multiple>
        <option *ngFor="let dealer of dealers" [value]="dealer?.id">{{dealer?.userName }}</option>
      </select>
    </div>
    <div class="form-group col-md-6">
      
    </div>

  </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary"
    [disabled]="!settingsForm.valid" (click)="SaveSettings()">ACEPTAR</button>
  <button type="button" class="btn btn-danger" (click)="closeModal()">CERRAR</button>
</div>
