/**
 * OskPack.WebApi.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GetBillRP { 
    id?: number;
    stateCatalogId?: number;
    customerStateId?: number;
    senderModel?: string;
    customerModel?: string;
    code?: string;
    serviceCode?: string;
    type?: number;
    stateCatalogName?: string;
    stateCatalogColor?: string;
    stateCatalogIcon?: string;
    file?: string;
    pounds?: number;
    price?: number;
    services?: number;
    total?: number;
    sendSMS?: boolean;
    createAt?: Date;
}