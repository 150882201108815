import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  NgbDatepicker, 
  NgbInputDatepicker, 
  NgbDateStruct, 
  NgbCalendar, 
  NgbDateAdapter,
  NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import {
  CityModel,
  ListFlightByShippingRQ,
  ShippingService,
  FlightService,
  ShippingListModel,
  FlightListModel,
  CityService,
  ListCityByStateRQ,
  StateModel,
  StateService,
  ListStateRQ,
} from "app/open-api";
import { ShippingListRQ } from '../../../open-api/model/ShippingListRQ';
import { SearchShipmentRQ } from '../../../open-api/model/SearchShipmentRQ';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ShippingType, shippingTypes } from '../../../open-api/model/shippingType';
import {
  CatalogBillState
} from "./../../models/catalogBillState";

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

    const now = new Date();
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;
@Component({
  selector: 'app-search-shipments',
  templateUrl: './search-shipments.component.html',
  styleUrls: ['./search-shipments.component.scss']
})
export class SearchShipmentsComponent implements OnInit {
  shipmentForms: FormGroup;
  desde: any;
  hasta: any;
  @ViewChild("d") input: NgbInputDatepicker;  
  states: StateModel[] = [];
  cities: CityModel[] = [];
  shippings: ShippingListModel[] = [];
  flights: FlightListModel[] = [];
  shippingTypes: Array<ShippingType> = [];
  catalogStates: CatalogBillState[] = [];
  selectedProvince = "";
  selectedMunicipality = "";
  selectedShippingType = "";
  selectedBillState = "";
  selectedShipping = "";
  selectedFlight = "";
  startDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  minDate: NgbDateStruct;
  hoveredDate: NgbDateStruct;
  noSearchSelected = false;

  isHovered = (date) =>
    this.desde &&
    !this.hasta &&
    this.hoveredDate &&
    after(date, this.desde) &&
    before(date, this.hoveredDate);
  isInside = (date) => after(date, this.desde) && before(date, this.hasta);
  isFrom = (date) => equals(date, this.desde);
  isTo = (date) => equals(date, this.hasta);
  

  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    public activeModal: NgbActiveModal,
    private stateService: StateService,
    private cityService: CityService,
    private shippingService: ShippingService,
    private flightService: FlightService,
    public formatter: NgbDateParserFormatter,
    private _parserFormatter: NgbDateParserFormatter
  ) {
    this.shippingTypes = shippingTypes;
    this.shipmentForms = fb.group({
      provincia: [null],
      municipio: [null],
      tipoEnvio: [null],
      vuelos: [null],
      envios: [null],
      estadoFactura: [null],     
      desde: [""],
      hasta: [""],      
      clave: [null]       
    });
   }

  async ngOnInit() {
    await this.FetchState();
    await this.FetchShippings();    
    await this.FetchBillStates();
  }


  get f() {
    return this.shipmentForms.controls;
  }

  async FetchState() {
    let params: ListStateRQ = {
      type: 1
    }
    await this.stateService
      .apiStateListGet(params)
      .toPromise()
      .then((response) => {
        this.states = response.list;
      })
      .catch((error) => {});
  }

  async FetchShippings() {   
    
      const request: ShippingListRQ = {     
        shippingType: null 
      }    
  
      await this.shippingService
        .apiShippingListGet(request)
        .toPromise()
        .then((response) => {
          this.shippings = response.list;
        })
        .catch((error) => {});       
  }

  async FetchBillStates(){    
      this.catalogStates = [
        {
          id: 2,
          name: "ASIG. VUELO",
        },
        {
          id: 3,
          name: "ENV. CUBA",
        },
        {
          id: 4,
          name: "RECEP. CUBA",
        },
        {
          id: 5,
          name: "ASIG. REPARTIDOR",
        },
        {
          id: 6,
          name: "RECEP. REPARTIDOR",
        },
        {
          id: 7,
          name: "ENTREGADA",
        },
        {
          id: 8,
          name: "CERR. CONTABLEMENTE",
        },
      ];     
  }

  OnShippingChange() {
    if (this.shipmentForms.get("envios").value != null) {
      this.selectedShipping = this.shippings.find(
        (i) => i.id === +this.shipmentForms.get("envios").value
      ).code;

      const params: ListFlightByShippingRQ = {
        shippingId: this.shipmentForms.get("envios").value,
      };
      this.flightService
        .apiFlightListByShippingPost(params)
        .subscribe((response) => {
          this.flights = response.list;
        });
    } else {
      this.selectedShipping = "";
      this.selectedFlight = "";
      this.flights = [];
      this.shipmentForms.get("vuelos").setValue(null);
    }
  }

  OnProvinceChange() {
    if (this.shipmentForms.get("provincia").value != null) {
      this.selectedProvince = this.states.find(
        (i) => i.id === +this.shipmentForms.get("provincia").value
      ).name;

      console.log(this.selectedProvince)

      const param: ListCityByStateRQ = {
        stateId: this.shipmentForms.get("provincia").value,
      };
      this.cityService.apiCityListByStatePost(param).subscribe((res) => {
        this.cities = res.list;
      });
    } else {
      this.selectedProvince = "";
      this.cities = [];
      this.selectedMunicipality = "";
      this.shipmentForms.get("municipio").setValue(null);
    }    
  }  

  OnMunicipalityChange() {
    if (this.shipmentForms.get("municipio").value != null) {
      this.selectedMunicipality = this.cities.find(
        (i) => i.id === +this.shipmentForms.get("municipio").value
      ).name;
    } else this.selectedMunicipality = "";
  }

 OnTypeShippinChange() {
    if (this.shipmentForms.get("tipoEnvio").value != null) {
      this.selectedShippingType = this.shippingTypes.find(
        (i) => i.id === +this.shipmentForms.get("tipoEnvio").value
      ).id.toString();
    } else this.selectedShippingType = "";
  }

  OnBillStateChange() {
    if (this.shipmentForms.get("estadoFactura").value != null) {
      this.selectedBillState = this.catalogStates.find(
        (i) => i.id === +this.shipmentForms.get("estadoFactura").value
      ).name;
    } else this.selectedBillState = "";
  }

  OnFlightChange() {
    if (this.shipmentForms.get("vuelos").value != null) {
      this.selectedFlight = this.flights.find(
        (i) => i.id === +this.shipmentForms.get("vuelos").value
      ).code;
    } else this.selectedFlight = "";
  }

  FetchShippingsByType() {
    if (this.shipmentForms.get("tipoEnvio").value != null) {
      this.selectedShippingType =
        this.shipmentForms.controls["tipoEnvio"].value.toString();
      this.shippings = [];
      this.flights = [];
      this.selectedShipping = null;
      this.selectedFlight = null;
      const request: ShippingListRQ = {
        shippingType: this.shipmentForms.controls["tipoEnvio"].value,
      };

      this.shippingService.apiShippingListGet(request).subscribe((response) => {
        this.shippings = response.list;
      });      
  }
}

  onDateSelection(date: NgbDateStruct) {
    let parsed = "";
    if (!this.desde && !this.hasta) {
      this.desde = date;
    } else if (this.desde && !this.hasta && after(date, this.desde)) {
      this.hasta = date;
      // this.model = `${this.fromDate.year} - ${this.toDate.year}`;
      this.input.close();
    } else {
      this.hasta = null;
      this.desde = date;
    }
    if (this.desde) {
      parsed += this._parserFormatter.format(this.desde);
    }
    if (this.hasta) {
      parsed += " - " + this._parserFormatter.format(this.hasta);
    }

    //this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', parsed);
  }

  FromDate() {
    if (
      this.shipmentForms.get("desde").value != "" &&
      this.shipmentForms.get("desde").value != undefined
    ) {
      const day = this.shipmentForms.get("desde").value.day;
      const month = this.shipmentForms.get("desde").value.month;
      const year = this.shipmentForms.get("desde").value.year;
      return new Date(year, month - 1, day);
    } else return null;
  }

  ToDate() {
    if (
      this.shipmentForms.get("hasta").value != "" &&
      this.shipmentForms.get("hasta").value != undefined
    ) {
      const day = this.shipmentForms.get("hasta").value.day;
      const month = this.shipmentForms.get("hasta").value.month;
      const year = this.shipmentForms.get("hasta").value.year;
      return new Date(year, month - 1, day);
    } else return null;
  }

  onDateSelect(event) {
    let year = event.year;
    let month = event.month <= 9 ? "0" + event.month : event.month;
    let day = event.day <= 9 ? "0" + event.day : event.day;
    let finalDate = day + "-" + month + "-" + year;
    this.shipmentForms.get("desde").setValue(finalDate);
  }

  checkIfCanSearch(filter: SearchShipmentRQ){
   return (filter.clave != null || filter.desde != null || filter.envios != "" || filter.estadoFactura != "" || filter.hasta != null || filter.municipio != "" || filter.provincia != "" || filter.tipoEnvio != "" || filter.vuelos != "");
  }

  

  resetFilters() {    
    this.shipmentForms.reset();
    const filter: SearchShipmentRQ = {};
    
    this.activeModal.close({
      filter: filter
    });  
  }

  AcceptModal(){        
    const clave = this.shipmentForms.get("clave").value;
    let agencyIds: number[] = [];
    /*if(agency != null)
      {
      if(agency == 1) 
        agencyIds = externalAgencies.map((x) => x.id)
      else {
        agencyIds.push(externalAgencies.find( x => x.id == agency).id);
      }      
    }   */ 
    
    const filter: SearchShipmentRQ = {
      clave: this.shipmentForms.get("clave").value != null && this.shipmentForms.get("clave").value != "" ? this.shipmentForms.get("clave").value : null,
      provincia: this.selectedProvince,
      municipio: this.selectedMunicipality,
      estadoFactura: this.selectedBillState,
      envios: this.selectedShipping,
      vuelos: this.selectedFlight,
      tipoEnvio: this.selectedShippingType,
      //serviceId: this.formSearch.get('servicios').value != null && this.formSearch.get("servicios").value != "" ? +this.formSearch.get('servicios').value : null,
      desde: this.shipmentForms.get('desde').value != null && this.shipmentForms.get("desde").value != "" ? this.FromDate().toDateString() : null, 
      hasta: this.shipmentForms.get('hasta').value != null && this.shipmentForms.get("hasta").value != "" ? this.ToDate().toDateString() : null,
    };    
    //console.log(this.checkIfCanSearch(filter));
    if(this.checkIfCanSearch(filter)){      
      this.activeModal.close({
        filter: filter
      });
    }   
    else {
      this.noSearchSelected = true;
    } 
  }
}
