/**
 * OskPack.WebApi.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AddCustomerWithSenderRQ { 
    senderId?: number;
    customerId?: number;
    cityId?: number;
    senderAgencyId?: number;
    name?: string;
    identityCard?: string;
    phone?: string;
    address?: string;
    referencePoint?: string;
    senderName?: string;
    senderPhone?: string;
}

export interface CustomerRP { 
    id?: number;
    senderId?: number;    
    cityId?: number;
    senderAgencyId?: number;
    name?: string;
    identityCard?: string;
    phone?: string;
    address?: string;
    referencePoint?: string;
    senderName?: string;
    senderPhone?: string;
}
