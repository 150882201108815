import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitText'
})
export class LimitTextPipe implements PipeTransform {

  maxCharacters: number = 12;

  transform(value: string, limit?: number): string {
    if (!value)
    return null;
    if (limit)
    this.maxCharacters = limit;
    if (value.length < this.maxCharacters )
      return value;
    return value.substring(0, this.maxCharacters) + '...';
  }

}
