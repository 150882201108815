/**
 * OskPack.WebApi.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AssigmentModel { 
    id?: number;
    dealerId?: number;
    billId?: number;
    billCode?: string;
    serviceCode?: string;
    billSenderModel?: string;
    billCustomerModel?: string;
    billStateCatalogName?: string;
    billFile?: string;
    flightCode?: string;
    shippingCode?: string;
    shippingType?: number;
    billPounds?: number;
    billPrice?: number;
    billServices?: number;
    billTotal?: number;
    hasBeenSeen?: boolean;
    billCreateAt?: Date;
    billUpdateAt?: Date;
}