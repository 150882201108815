export interface ExternalService {
    id?: number;
    name?: string;
}

export const externalServices: ExternalService[] = [
    {
      id: 10,
      name: "Aerovaradero Aéreo",
    },
    {
      id: 12,
      name: "Aerovaradero Marítimo Domicilio",
    }
  ]

