import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, MinValidator, ValidationErrors, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddDealerTaxRQ, UpdateDealerTaxRQ } from './../../../open-api/model/addDealerTaxRQ'
import { DealerService, CurrencyService, ListCurrenciesRP, CurrencyModel } from 'app/open-api';
import { shippingTypes, ShippingType } from './../../../open-api/model/shippingType';
import { commisionsTypes, CommisionType } from './../../../open-api/model/commisionType';
import { DeleteDealerTaxRQ, DeleteDealerTaxRP } from './../../../open-api/model/deleteDealerTaxRQ';
import {ListDealerTaxRQ, ListDealerTaxRP, DealerTaxModel } from './../../../open-api/model/listDealerTaxRQ'
import { commisionValidator } from "./../../../shared/validators/commision.validator";

@Component({
  selector: 'app-dealers-commisions',
  templateUrl: './dealers-commisions.component.html',
  styleUrls: ['./dealers-commisions.component.scss']
})
export class DealersCommisionsComponent implements OnInit {
  @Input() dealerId: number;  
  @Input() dealerName: string;
  dealerTaxs: DealerTaxModel[] = [];
  dealerForms: FormGroup;
  shippingTypes: Array<ShippingType> = [];
  commisionsTypes: Array<CommisionType> = [];
  selectedShippingType: string;
  currencies: Array<CurrencyModel> = [];
  loading = false;
  commisionSelected: UpdateDealerTaxRQ = null;
  buttonOperation = "Nuevo";
  @ViewChild('addComission', {read: ElementRef, static: false}) btn: ElementRef;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private dealerService: DealerService,
    private currencyService: CurrencyService
  ) { 
    this.shippingTypes = shippingTypes;
    this.commisionsTypes = commisionsTypes;
  }

  ngOnInit() {    
    this.dealerForms = this.formBuilder.group({
      shippingType: [null, Validators.required],      
      min: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(4),
        Validators.pattern(/[\+]?([\-]?([0-9]{1,})?[\.]?[0-9]{1,})/)
      ]],
      max: ['', [
        Validators.required,
        Validators.minLength(50),
        Validators.maxLength(150),
        Validators.pattern(/^[0-9.() -]*$/)        
      ]],      
      taxType: [null, Validators.required],
      amount: ['', Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.pattern(/^[0-9.() -]*$/)
      ])],        
      currencyId: [null, Validators.required],      
    },
    {
      validators: commisionValidator,
    }
  );

    this.FetchModels();
    this.FetchCurrencies();
  }

  getShippingTypeText(id: number){
    return this.shippingTypes.find(p => p.id == id).name;
  }

  FetchCurrencies(){
    this.currencyService.apiCurrencyListGet().subscribe(response => {      
      this.currencies = response.list;
    });
  }

  NumberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      if(charCode != 46)
        return false;
    }
    return true;
  }

  /*const validatePasswordMatch = (control: AbstractControl): {[key: string]: any} | null => {
    const password = this.resetPasswordForm?.get('password')?.value as string;
    const passwordConfirm = control.value as string;
  
    if (password !== passwordConfirm) {
      return {passwordMatch: true};
    }
  
    return null;
  };*/

  checkMin(control): ValidationErrors {
    const min = +(control.value as string);
    return +this.dealerForms.controls['max'].value > min ? { minValue: true } : null;
  }

  checkMax(control): ValidationErrors {
    const max = +(control.value as string);
    return +this.dealerForms.controls['min'].value < max ? { maxValue : true } : null;
  }

  CleanControls() {
    this.dealerForms.reset();
    /*this.dealerForms.controls['shippingType'].setValue(null);
    this.dealerForms.controls['min'].setValue('');
    this.dealerForms.controls['max'].setValue('');
    this.dealerForms.controls['taxType'].setValue(null);
    this.dealerForms.controls['amount'].setValue('');
    this.dealerForms.controls['currencyId'].setValue(null);*/
  }

  
  DisableControls() {
    this.dealerForms.controls['min'].disable();
    this.dealerForms.controls['max'].disable();
    this.dealerForms.controls['amount'].disable();    
  }

  EnableControls() {
    this.dealerForms.controls['min'].enable();
    this.dealerForms.controls['max'].enable();
    this.dealerForms.controls['amount'].enable();    
  }

  AddCommision() {
    if (!this.commisionSelected) {      
      const param: AddDealerTaxRQ = this.dealerForms.value;      
      param.dealerId = this.dealerId;
      this.DisableControls();
      this.dealerService.apiDealerTaxAddPost(param).subscribe(async response => {
        if(response.status == 200){
          Swal.fire({
            title: 'Comisión creada.',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            position: 'top-end'
          });
          await this.FetchModels();
          this.loading = false;
          this.CleanControls(); 
          this.EnableControls();         
        }
        else
        {
          Swal.fire({
            title: response.text,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            position: 'top-end',
            customClass: {
              confirmButton: 'btn btn-primary',
            }
          });
          this.EnableControls(); 
        }        
      }, error => {
        this.loading = false;
        Swal.fire({
          title: 'Error al crear el gasto.',
          icon: 'error',
          showConfirmButton: false,
          timer: 2000,
          position: 'top-end',
          customClass: {
            confirmButton: 'btn btn-primary',
          }
        });
        this.EnableControls();
      });
    }
    else {
      this.loading = true;     

      const param: UpdateDealerTaxRQ = this.commisionSelected;      
      this.DisableControls();
      param.currencyId = this.dealerForms.get('currencyId').value;
      param.amount = this.dealerForms.get('amount').value;
      param.max = this.dealerForms.get('max').value;
      param.min = this.dealerForms.get('min').value;
      param.shippingType = this.dealerForms.get('shippingType').value;
      param.taxType = this.dealerForms.get('taxType').value;
      
      this.dealerService.apiDealerTaxUpdatePost(param).subscribe(async response => {
        if(response){
           if(response.status == 200){
            Swal.fire({
              title: 'Comisión Actualizada.',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000,
              position: 'top-end'
            });
            await this.FetchModels();
            this.loading = false;
            this.CleanControls();
            this.EnableControls();
           }
           else {
            Swal.fire({
              title: 'Error al actualizar la comisión. ' + response.text,
              icon: 'error',
              showConfirmButton: false,
              timer: 2000,
              position: 'top-end',
              customClass: {
                confirmButton: 'btn btn-primary',
              }
            });
            this.EnableControls();
           }
        }
       
      }, error => {
        this.loading = false;
        Swal.fire({
          title: 'Error al actualizar la comisión. ' + error,
          icon: 'error',
          showConfirmButton: false,
          timer: 2000,
          position: 'top-end',
          customClass: {
            confirmButton: 'btn btn-primary',
          }
        });
        this.EnableControls();
      });
      this.commisionSelected = null;
    }
    this.buttonOperation = "Nuevo";
    this.btn.nativeElement.disabled = true;
    this.btn.nativeElement.focus();
  }

  EditCommision(comision: DealerTaxModel) {
    this.buttonOperation = "Actualizar";
    let temp: UpdateDealerTaxRQ = {      
      amount: comision.amount,
      currencyId: comision.currencyId,
      dealerId: comision.dealerId,
      max: comision.max,
      min: comision.min,
      shippingType: comision.shippingType,
      taxType: comision.taxType,
      dealerTaxId: comision.id
    }
    this.commisionSelected = temp;

    this.dealerForms.controls['shippingType'].setValue(comision.shippingType);
    this.dealerForms.controls['min'].setValue(comision.min);
    this.dealerForms.controls['max'].setValue(comision.max);
    this.dealerForms.controls['amount'].setValue(comision.amount);
    this.dealerForms.controls['currencyId'].setValue(comision.currencyId);
    this.dealerForms.controls['taxType'].setValue(comision.taxType);
  }

  FetchModels() {
    const param: ListDealerTaxRQ = {
      dealerId: this.dealerId
    };
    this.dealerService.apiDealerGetListTaxPost(param).subscribe(response => {      
      this.dealerTaxs = response.list;
    });
  }

  DeleteCommision(id) {
    this.loading = true;
    //this.DisableControls();
    const swal = Swal.fire({
      title: '¿Está seguro que desea eliminar la comision?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-primary'
      }
    }).then(result => {
      if (result.isConfirmed) {
        const param: DeleteDealerTaxRQ = {
          dealerTaxId: id,
        };
        this.dealerService.apiDealerTaxDeletePost(param).subscribe(
          async (response) => {
            if (response.status == 200) {
              Swal.fire({
                title: "Comisiónn Eliminada.",
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
                position: "top-end",
              });
              await this.FetchModels();
              this.loading = false;
              this.CleanControls();
              //this.EnableControls();
            } else {
              this.loading = false;
              Swal.fire({
                title: response.text,
                icon: "error",
                showConfirmButton: false,
                timer: 5000,
                position: "top-end",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            }
          },
          (error) => {
            this.loading = false;
            Swal.fire({
              title: "Error al eliminar La comision.",
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
              position: "top-end",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            //this.EnableControls();
          }
        );
      }
      this.loading = false;
    })
  }

  get f() {
    return this.dealerForms.controls;
  }

  AcceptModal(){

  }

}
