import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AddShippingExpenseRQ,
  DeleteShippingExpenseRQ,
  ExpenceCatalogListModel,
  ExpenceCatalogService,
  GetShippingExpenseByShippingRQ,
  ShippingExpenseListModel,
  ShippingExpenseService,
  ShippingListModel,
  UpdateShippingExpenseRQ
} from 'app/open-api';
import Swal from 'sweetalert2';
import { jsPDF } from "jspdf";
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-shipping-expenses-modal',
  templateUrl: './shipping-expenses-modal.component.html',
  styleUrls: ['./shipping-expenses-modal.component.scss']
})
export class ShippingExpensesModalComponent implements OnInit {

  @Input() shipping: ShippingListModel;
  formExpense: FormGroup;
  expenses: ShippingExpenseListModel[] = [];
  catalogs: ExpenceCatalogListModel[] = [];
  catalogSelected: number;
  loading: boolean = false;
  totalCost = 0;
  expenseSelected?: UpdateShippingExpenseRQ = null;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private shippingExpenseService: ShippingExpenseService,
    private expenseCatalogService: ExpenceCatalogService,
    private currencyPipe: CurrencyPipe
  ) { }

  async ngOnInit(): Promise<void> {
    this.formExpense = this.formBuilder.group({
      expenseCatalogId: [null, Validators.required],
      description: [null, Validators.required],
      cost: [null, Validators.required]
    });
    await this.FetchCatalogs();
    await this.FetchModels();
  }

  async FetchModels() {
    const param: GetShippingExpenseByShippingRQ = {
      shippingId: this.shipping.id
    };
    this.expenses = (await this.shippingExpenseService.apiShippingExpenseGetByShippingPost(param).toPromise()).list;
    this.totalCost = this.expenses.reduce((a, b) => a + b.cost, 0);
  }

  async FetchCatalogs() {
    this.catalogs = (await this.expenseCatalogService.apiExpenceCatalogListGet().toPromise()).list;
  }

  AddExpense() {
    if (!this.expenseSelected) {
      this.loading = true;
      this.DisableControls();
      const param: AddShippingExpenseRQ = this.formExpense.value;
      param.shippingId = this.shipping.id;
      this.shippingExpenseService.apiShippingExpenseAddPost(param).subscribe(async response => {
        Swal.fire({
          title: 'Gasto Creado.',
          icon: 'success',
          showConfirmButton: false,
          timer: 2000,
          position: 'top-end'
        });
        await this.FetchModels();
        this.loading = false;
        this.CleanControls();
        this.EnableControls();
      }, error => {
        this.loading = false;
        Swal.fire({
          title: 'Error al crear el gasto.',
          icon: 'error',
          showConfirmButton: false,
          timer: 2000,
          position: 'top-end',
          customClass: {
            confirmButton: 'btn btn-primary',
          }
        });
        this.EnableControls();
      });
    }
    else {
      this.loading = true;
      this.DisableControls();

      const param: UpdateShippingExpenseRQ = this.expenseSelected;
      param.expenseCatalogId = this.formExpense.get('expenseCatalogId').value;
      param.description = this.formExpense.get('description').value;
      param.cost = this.formExpense.get('cost').value;

      this.shippingExpenseService.apiShippingExpenseUpdatePost(param).subscribe(async response => {
        Swal.fire({
          title: 'Gasto Actualizado.',
          icon: 'success',
          showConfirmButton: false,
          timer: 2000,
          position: 'top-end'
        });
        await this.FetchModels();
        this.loading = false;
        this.CleanControls();
        this.EnableControls();
      }, error => {
        this.loading = false;
        Swal.fire({
          title: 'Error al crear el gasto. ' + error,
          icon: 'error',
          showConfirmButton: false,
          timer: 2000,
          position: 'top-end',
          customClass: {
            confirmButton: 'btn btn-primary',
          }
        });
        this.EnableControls();
      });
      this.expenseSelected = null;
    }



  }

  EnableControls() {
    this.formExpense.controls['expenseCatalogId'].enable();
    this.formExpense.controls['description'].enable();
    this.formExpense.controls['cost'].enable();
  }

  DisableControls() {
    this.formExpense.controls['expenseCatalogId'].disable();
    this.formExpense.controls['description'].disable();
    this.formExpense.controls['cost'].disable();
  }

  CleanControls() {
    this.formExpense.controls['expenseCatalogId'].setValue(null);
    this.formExpense.controls['description'].setValue(null);
    this.formExpense.controls['cost'].setValue(null);
  }

  DeleteExpense(id) {
    this.loading = true;
    this.DisableControls();
    const swal = Swal.fire({
      title: '¿Está seguro que desea eliminar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-primary'
      }
    }).then(result => {
      if (result.isConfirmed) {
        const param: DeleteShippingExpenseRQ = {
          id: id
        };
        this.shippingExpenseService.apiShippingExpenseDeletePost(param).subscribe(async response => {
          Swal.fire({
            title: 'Gasto Eliminado.',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            position: 'top-end'
          });
          await this.FetchModels();
          this.loading = false;
          this.CleanControls();
          this.EnableControls();
        }, error => {
          this.loading = false;
          Swal.fire({
            title: 'Error al eliminar el gasto.',
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            position: 'top-end',
            customClass: {
              confirmButton: 'btn btn-primary',
            }
          });
          this.EnableControls();
        });
      }
      this.loading = false;
    });
  }

  EditExpense(expense) {
    let temp: UpdateShippingExpenseRQ = {
      id: expense.id,
      shippingId: expense.shippingId,
      expenseCatalogId: expense.expenseCatalogId,
      description: expense.description,
      cost: expense.cost
    }
    this.expenseSelected = temp;

    this.formExpense.controls['expenseCatalogId'].setValue(expense.expenseCatalogId);
    this.formExpense.controls['description'].setValue(expense.description);
    this.formExpense.controls['cost'].setValue(expense.cost);
  }

  PrintExpense() {
    let yAlign = 50;
    const doc = new jsPDF();
    doc.text('Gasto del Envío: ' + this.shipping?.code, 105, 20, { align: 'center' });
    doc.text('Descripción', 20, 40, { align: 'left' });
    doc.text('Costo', 190, 40, { align: 'right' });
    doc.line(20, 43, 190, 43);
    this.expenses.forEach(e => {
      doc.text(e.description, 20, yAlign, { align: 'left' });
      doc.text(this.currencyPipe.transform(e.cost), 190, yAlign, { align: 'right' });
      yAlign += 10;
    });
    doc.text('Total: ' + this.currencyPipe.transform(this.totalCost), 190, yAlign, { align: 'right' });
    doc.save('Gastos.pdf');
  }
}
