import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DownloadPdfRQ } from 'app/open-api/model/downloadPdfRQ';
import { AssigmentDealerModel, DealersModel, DealerModel, DealerService, BillService, CurrencyModel } from "app/open-api";
import { AssigmentDealerLocalModel } from "app/shared/models/assigment-dealer.model";
import {ListDealerTaxRQ, ListDealerTaxRP, DealerTaxModel } from './../../../open-api/model/listDealerTaxRQ'
import { environment } from "environments/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";

@Component({
  selector: "app-assign-bill-dealer-modal",
  templateUrl: "./assign-bill-dealer-modal.component.html",
  styleUrls: ["./assign-bill-dealer-modal.component.scss"],
})
export class AssignBillDealerModalComponent implements OnInit {
  @Input() bill: AssigmentDealerLocalModel;
  assignForm: FormGroup;
  dealers: DealersModel[] = [];
  currencies: CurrencyModel[] = [];
  urlApi = environment.apiUrl;
  dealerSelected: DealersModel;
  taxSelected: DealerTaxModel;

  constructor(
    public activeModal: NgbActiveModal,
    private dealerService: DealerService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private billService: BillService,
  ) {
    this.assignForm = new FormGroup({
      dealerId: new FormControl(null, Validators.required),
      currencyId: new FormControl(null, Validators.required),
      pounds: new FormControl(null, Validators.required),
      price: new FormControl(null, Validators.required),
      services: new FormControl(null, Validators.required),
      total: new FormControl(null, Validators.required),
    });
  }

  async ngOnInit(): Promise<void> {
    this.spinner.show();
    await this.FetchModels();
        
    if (this.bill?.price) {
      this.dealerSelected = this.dealers.find(
        (dealer) => dealer.id === this.bill.dealerId
      );
            
      if (this.dealerSelected) {
        let currencies = this.GetCurrencies(this.dealerSelected.dealerTaxs)

        if (currencies && currencies.length > 0) {
          this.currencies = currencies;          
        } else {
          //this.assignForm?.get("price")?.setValue(null);
          this.assignForm?.get("currencyId")?.setValue(null);
          this.currencies = [];
          this.toastr.error(
            "El repartidor no cuenta con las monedas asociadas al tipo de envio .",
            "Error!",
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }
      }
      
      this.assignForm.setValue({
        dealerId: this.bill.dealerId,
        currencyId: "",
        pounds: this.bill.billPounds,
        price: this.bill.price,
        services: this.bill.services,
        total: this.bill.total,
      });
      //this.assignForm?.get("currencyId")?.setValue(this.bill.currencyId);
    } else {
      this.assignForm.setValue({
        dealerId: this.bill.dealerId,
        currencyId: null,
        pounds: this.bill.billPounds,
        price: 0,
        services: 0,
        total: 0,
      });
    }
    this.assignForm.updateValueAndValidity();
    this.spinner.hide();
  }

  GetCurrencies(taxes: DealerTaxModel[] ): CurrencyModel[] {    
    let lista: DealerTaxModel[] = [];
    lista = taxes.map((item) => {
      item.currency.name += " " + item.min.toFixed(2) + " - " + item.max.toFixed(2) + " " + (item.taxType == 1 ? "Fijo" : "Por Libras"),
      item.currency.id = item.id
          return item;
    });    
    return lista.filter((item) => item.shippingType == this.bill.shippingType)
          .map((item) => item.currency);
  }

  async FetchModels(): Promise<void> {
    await this.dealerService
      .apiDealersListGet()
      .toPromise()
      .then((res) => {        
        this.dealers = res.list.map((dealer) => {          
          dealer.userName += " - " + dealer.location;
          return dealer;
        });
      });
  }

  OnChangeValue() {
    const pounds: number = +this.assignForm?.get("pounds")?.value?.toFixed(2) || 0;
    const price: number = +this.assignForm?.get("price")?.value || 0;
    const services: number = +this.assignForm?.get("services")?.value || 0;
    if(this.taxSelected?.taxType == 1){
      const total = (this.taxSelected.amount).toFixed(2);
    this.assignForm?.get("total")?.setValue(total);
    this.assignForm?.get("total")?.updateValueAndValidity();
    }
    else if(this.taxSelected?.taxType == 2) {
      const total = (pounds * price + services).toFixed(2);
      this.assignForm?.get("total")?.setValue(total);
      this.assignForm?.get("total")?.updateValueAndValidity();
    }    
  }

  AcceptModal() {
    const comission: number = +this.assignForm.get("services").value;
    if (comission === 0) {
      const swal = Swal.fire({
        title: "El Servicio o Bonus es igual a 0. ¿Está seguro que desea continuar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Asignar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.activeModal.close({
            bill: this.assignForm.value,
            id: this.bill?.id,
          });
        }
      });
    } else {
      this.activeModal.close({
        bill: this.assignForm.value,
        id: this.bill?.id,
      });
    }
  }

  DownloadPdf(billId: number){
    this.spinner.show();
    const param: DownloadPdfRQ = {
      billId: billId
    }

    this.billService.apiDownloadPdfPost(param).subscribe(response => {
      //console.log();
      const fileURL = URL.createObjectURL(response);
      window.open(fileURL, '_blank');

      Swal.fire({
        title: `El pdf se descargó satisfactoriamente.`,
        icon: 'success',
        showConfirmButton: false,
        timer: 5000,
        position: 'top'
      });

      this.spinner.hide();
    }, error => {
      //console.warn(error);
      Swal.fire({
        title: 'Error! ' + error.error,
        icon: 'error',
        showConfirmButton: false,
        timer: 5000,
        position: 'top'
      });
      this.spinner.hide();
    });   
  
  }

  OnDealerSelected() {
    const dealerId = this.assignForm?.get("dealerId")?.value;
    const dealer = this.dealers.find((dealer) => dealer.id === dealerId);
    this.currencies = [];    
    if (dealer) {
      if (dealer.dealerTaxs.length > 0) {
        this.dealerSelected = dealer;
        /*const tax = this.dealerSelected.dealerTaxs.find(
          (currency) => currency.shippingType == this.bill.shippingType
        );*/
        let taxes = this.dealerSelected.dealerTaxs.filter(
          (item) => item.shippingType == this.bill.shippingType
        );

        if (taxes.length > 0) {
          let rangetaxes = taxes.filter(
            (item) =>
              this.bill.billPounds >= item.min &&
              this.bill.billPounds <= item.max
          );

          if (rangetaxes.length > 0) {
            const tax = rangetaxes[0];
            if (tax) {
              this.taxSelected = tax;
              this.assignForm?.get("currencyId")?.setValue(tax.id);
              this.assignForm?.get("price")?.setValue(tax.amount);
              this.OnChangeValue();
              let currencies = this.GetCurrencies(
                this.dealerSelected.dealerTaxs
              );              
              /*let currencies = this.dealerSelected.dealerTaxs
                .filter((item) => item.shippingType == this.bill.shippingType)
                .map((item) => item.currency);*/

              if (currencies && currencies.length > 0) {
                this.currencies = currencies;
              } else {
                this.dealerSelected = null;
                this.assignForm?.get("currencyId")?.setValue(null);
                this.assignForm?.get("price")?.setValue(null);
                this.currencies = [];
                this.toastr.error(
                  "El repartidor no cuenta con las monedas asociadas al tipo de envio .",
                  "Error!",
                  {
                    toastClass: "toast ngx-toastr",
                    closeButton: true,
                  }
                );
              }
            } else {
              this.dealerSelected = null;
              this.assignForm?.get("currencyId")?.setValue(null);
              this.assignForm?.get("price")?.setValue(null);
              this.currencies = [];
              this.toastr.error(
                "El repartidor no cuenta con la comision para el tipo de envío asociado a la factura.",
                "Error!",
                {
                  toastClass: "toast ngx-toastr",
                  closeButton: true,
                }
              );
              this.assignForm?.updateValueAndValidity();
            }
          } else {
            this.taxSelected = null;
            //this.assignForm?.get("currencyId")?.setValue(null);
            // this.assignForm?.get("price")?.setValue(null);
            this.OnChangeValue();
            let currencies = this.GetCurrencies(this.dealerSelected.dealerTaxs);            
            /*let currencies = this.dealerSelected.dealerTaxs
                .filter((item) => item.shippingType == this.bill.shippingType)
                .map((item) => item.currency);*/

            if (currencies && currencies.length > 0) {
              this.currencies = currencies;
            } else {
              this.dealerSelected = null;
              this.assignForm?.get("currencyId")?.setValue(null);
              this.assignForm?.get("price")?.setValue(null);
              this.currencies = [];
              this.toastr.error(
                "El repartidor no cuenta con las monedas asociadas al tipo de envio .",
                "Error!",
                {
                  toastClass: "toast ngx-toastr",
                  closeButton: true,
                }
              );
            }
          }
        } else {
          this.dealerSelected = null;
          this.assignForm?.get("currencyId")?.setValue(null);
          this.assignForm?.get("price")?.setValue(null);
          this.currencies = [];
          this.toastr.error(
            "No existen comisiones coincidentes con el tipo de envío de la factura.",
            "Error!",
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }
      } else {
        this.dealerSelected = null;
        this.assignForm?.get("currencyId")?.setValue(null);
        this.assignForm?.get("price")?.setValue(null);
        this.currencies = [];
        this.toastr.error(
          "El repartidor no cuenta con la parametrización de las monedas.",
          "Error!",
          {
            toastClass: "toast ngx-toastr",
            closeButton: true,
          }
        );
      }
      this.assignForm?.updateValueAndValidity();
    } else {
      this.dealerSelected = null;
      this.assignForm?.get("currencyId")?.setValue(null);
      this.assignForm?.get("price")?.setValue(null);
      this.currencies = [];
      this.toastr.error("Error al obtener el repartidor.", "Error!", {
        toastClass: "toast ngx-toastr",
        closeButton: true,
      });
    }
  }

  OnCurrencySelected() {    
    const currencyId = this.assignForm?.get("currencyId")?.value;
    let tax = this.dealerSelected.dealerTaxs    
    .find((tax) => tax.id == currencyId);
    this.taxSelected = tax;    
    
    this.assignForm?.get("price")?.setValue(tax.amount);
    this.OnChangeValue();
    this.assignForm?.updateValueAndValidity();
  }
}
