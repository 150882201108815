<div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Gasto del Vuelo: {{flight?.code}} </h4>
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
    <form [formGroup]="formExpense">
        <div class="row" style="padding-inline: 10px">
            <div class="col-3">
                <ng-select [items]="catalogs" bindLabel="name" bindValue="id" placeholder="Catálogo"
                    formControlName="expenceCatalogId">
                    <ng-template ng-option-tmp ng-label-tmp let-item="item">
                        <i [class]="item.icon" [style.color]="item.color"></i>
                        {{ item.name }}
                    </ng-template>
                </ng-select>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <input type="text" class="form-control" aria-describedby="description-input"
                        placeholder="Decripción" formControlName="description" />
                </div>
            </div>
            <div class="col-2">
                <div class="form-group">
                    <input type="number" class="form-control" aria-describedby="cost-input" placeholder="Valor"
                        formControlName="cost" />
                </div>
            </div>
            <div class="col-3">
                <button type="button" class="btn btn-outline-primary" rippleEffect
                    [disabled]="loading || formExpense.invalid" (click)="AddExpense()" style="width: 100%;">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                    <span class="ml-25 align-middle">Adicionar</span>
                </button>
            </div>
        </div>
    </form>
    <hr>
    <ul class="list-group" autofocus="" tabindex="1">
        <li class="list-group-item d-flex justify-content-between align-items-center" *ngIf="expenses?.length === 0">
            <span>No hay gastos registrados.</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let expense of expenses">
            <p class="float-left mb-0">
                <i [class]="expense?.expenseCatalogIcon + ' mr-1'" aria-hidden="true"
                    [style.color]="expense.expenseCatalogColor"></i>
                <span>{{expense?.description}}</span>
            </p>
            <div>
                <button 
                    type="button" 
                    (click)="DeleteExpense(expense.id)" 
                    ngbTooltip="Eliminar Gastos"
                    placement="left"
                    class="btn btn-icon btn-outline-danger text-danger cursor-pointer mr-2" 
                    rippleEffect>
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                </button>
                <button 
                    type="button" 
                    (click)="EditExpense(expense)" 
                    ngbTooltip="Editar Gastos"
                    placement="left"
                    class="btn btn-icon btn-outline-info text-info cursor-pointer mr-2" 
                    rippleEffect>
                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
                <span class="badge badge-primary badge-pill">{{expense?.cost | currency}}</span>
            </div>
            
        </li>
    </ul>
</div>
<div class="modal-footer">
    <div class="d-flex justify-content-end align-items-center">
        <button type="button" ngbTooltip="Imprimir Gastos" placement="left"
            class="btn btn-icon btn-outline-info text-info cursor-pointer mr-2" rippleEffect
            [disabled]="expenses.length === 0" (click)="PrintExpense()">
            <i class="fa fa-print" aria-hidden="true"></i>
        </button>
        <span>Total: {{totalCost | currency}}</span>
    </div>
</div>