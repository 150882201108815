import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { jsPDF } from "jspdf";
import { CurrencyPipe } from '@angular/common';
import { LoadExternalShimpmentRQ, LoadExternalShimpmentRP, Shimpment, LoadExternalOperation, InternarOrder, Order, ReceiverModel, SenderModel,OrderId, LoadExternalOrdersRP } from "app/open-api/model/LoadExternalOrdersRP";
import { ExternalAgency, externalAgencies } from './../../../open-api/model/externalAgencies';
import { ExternalService, externalServices } from './../../../open-api/model/externalServices';
import {
  NgbDatepicker, 
  NgbInputDatepicker, 
  NgbDateStruct, 
  NgbCalendar, 
  NgbDateAdapter,
  NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';


  const now = new Date();
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;
@Component({
  selector: 'app-external-search-modal',
  templateUrl: './external-search-modal.component.html',
  styleUrls: ['./external-search-modal.component.scss']
})
export class ExternalSearchModalComponent implements OnInit {
 // @Input() shipping: ShippingListModel;
  formSearch: FormGroup;
  filter: LoadExternalShimpmentRQ = {};
  //expenses: ShippingExpenseListModel[] = [];
  //catalogs: ExpenceCatalogListModel[] = [];
  catalogSelected: number;
  loading: boolean = false;
  totalCost = 0;
  agencies: Array<ExternalAgency> = [];
  services: Array<ExternalService> = [];
  fechainicio: any;
  fechafin: any;
  @ViewChild("d") input: NgbInputDatepicker;
  //expenseSelected?: UpdateShippingExpenseRQ = null;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,    
    private currencyPipe: CurrencyPipe,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,    
    private _parserFormatter: NgbDateParserFormatter
  ) { 
    this.agencies = this.Agencies();
    this.services = externalServices;
    this.formSearch = formBuilder.group({
      'noenvio': ['', Validators.compose([        
        Validators.maxLength(9999),
        Validators.minLength(1),//^(0|[1-9][0-9]?)$
        Validators.pattern(/^[0-9.() -]*$/)
      ])],      
      'fechainicio': [null],
      'fechafin': [null],
      'agencias': [null],
      'servicios': [null],          
    });

  }
  
   ngOnInit() {
    
  }

  get fs() {
    return this.formSearch.controls;
  }

  onDateSelection(date: NgbDateStruct) {
    let parsed = "";
    if (!this.fechainicio && !this.fechafin) {
      this.fechainicio = date;
    } else if (this.fechainicio && !this.fechafin && after(date, this.fechafin)) {
      this.fechafin = date;
      // this.model = `${this.fromDate.year} - ${this.toDate.year}`;
      this.input.close();
    } else {
      this.fechafin = null;
      this.fechainicio = date;
    }
    if (this.fechainicio) {
      parsed += this._parserFormatter.format(this.fechainicio);
    }
    if (this.fechafin) {
      parsed += " - " + this._parserFormatter.format(this.fechafin);
    }

    //this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', parsed);
  }
  
  NumberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  Agencies(): ExternalAgency[]{    
    let temp: ExternalAgency[] = [];
    temp = Object.assign([], externalAgencies);
    
    let item: ExternalAgency = {
      id: 1,
      name: "Todas las agencias"
    }    
    temp.splice(0, 0, item);
    
    return temp;
  }

  FromDate() {
    if (
      this.formSearch.get("fechainicio").value != "" &&
      this.formSearch.get("fechainicio").value != undefined
    ) {
      const day = this.formSearch.get("fechainicio").value.day;
      const month = this.formSearch.get("fechainicio").value.month;
      const year = this.formSearch.get("fechainicio").value.year;
      return new Date(year, month - 1, day);
    } else return null;
  }

  ToDate() {
    if (
      this.formSearch.get("fechafin").value != "" &&
      this.formSearch.get("fechafin").value != undefined
    ) {
      const day = this.formSearch.get("fechafin").value.day;
      const month = this.formSearch.get("fechafin").value.month;
      const year = this.formSearch.get("fechafin").value.year;
      return new Date(year, month - 1, day);
    } else return null;
  }

  AcceptModal(){        
    const agency = this.formSearch.get("agencias").value;
    let agencyIds: number[] = [];
    if(agency != null)
      {
      if(agency == 1) 
        agencyIds = externalAgencies.map((x) => x.id)
      else {
        agencyIds.push(externalAgencies.find( x => x.id == agency).id);
      }      
    }    
    
    const filter: LoadExternalShimpmentRQ = {
      shipmentNumber: this.formSearch.get("noenvio").value != null && this.formSearch.get("noenvio").value != "" ? +this.formSearch.get("noenvio").value : null,
      agencies: this.formSearch.get('agencias').value != null && this.formSearch.get("agencias").value != "" ? agencyIds : [],
      serviceId: this.formSearch.get('servicios').value != null && this.formSearch.get("servicios").value != "" ? +this.formSearch.get('servicios').value : null,
      startDate: this.formSearch.get('fechainicio').value != null && this.formSearch.get("fechainicio").value != "" ? this.FromDate().toDateString() : null, 
      endDate: this.formSearch.get('fechafin').value != null && this.formSearch.get("fechafin").value != "" ? this.ToDate().toDateString() : null,
    };    
    this.activeModal.close({
      filter: filter
    });
  }

}
