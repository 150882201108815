import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AssigmentService } from './api/assigment.service';
import { BillService } from './api/bill.service';
import { CityService } from './api/city.service';
import { CurrencyService } from './api/currency.service';
import { CustomerService } from './api/customer.service';
import { DashboardService } from './api/dashboard.service';
import { DealerService } from './api/dealer.service';
import { DealerBalanceService } from './api/dealerBalance.service';
import { ExpenceCatalogService } from './api/expenceCatalog.service';
import { FlightService } from './api/flight.service';
import { FlightExpenceService } from './api/flightExpence.service';
import { RolesService } from './api/roles.service';
import { SenderService } from './api/sender.service';
import { ShippingService } from './api/shipping.service';
import { ShippingBalanceService } from './api/shippingBalance.service';
import { ShippingExpenseService } from './api/shippingExpense.service';
import { StateService } from './api/state.service';
import { SystemConfigurationService } from './api/systemConfiguration.service';
import { UserService } from './api/user.service';
import { BillBatchService } from './api/billBatch.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AssigmentService,
    BillService,
    BillBatchService,
    CityService,
    CurrencyService,
    CustomerService,
    DashboardService,
    DealerService,
    DealerBalanceService,
    ExpenceCatalogService,
    FlightService,
    FlightExpenceService,
    RolesService,
    SenderService,
    ShippingService,
    ShippingBalanceService,
    ShippingExpenseService,
    StateService,
    SystemConfigurationService,
    UserService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
