/**
 * OskPack.WebApi.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateDealerBalanceRQ } from '../model/createDealerBalanceRQ';
import { DeleteDealerBalanceRQ } from '../model/deleteDealerBalanceRQ';
import { GetDealerBalanceByDealerRP } from '../model/getDealerBalanceByDealerRP';
import { GetDealerBalanceByDealerRQ } from '../model/getDealerBalanceByDealerRQ';
import { GetDealerBalanceRP } from '../model/getDealerBalanceRP';
import { GetDealerBalanceRQ } from '../model/getDealerBalanceRQ';
import { ListDealerBalanceRP } from '../model/listDealerBalanceRP';
import { Unit } from '../model/unit';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DealerBalanceService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDealerBalanceAddPost(body?: CreateDealerBalanceRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiDealerBalanceAddPost(body?: CreateDealerBalanceRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiDealerBalanceAddPost(body?: CreateDealerBalanceRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiDealerBalanceAddPost(body?: CreateDealerBalanceRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/DealerBalance/Add`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDealerBalanceDeletePost(body?: DeleteDealerBalanceRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiDealerBalanceDeletePost(body?: DeleteDealerBalanceRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiDealerBalanceDeletePost(body?: DeleteDealerBalanceRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiDealerBalanceDeletePost(body?: DeleteDealerBalanceRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/DealerBalance/Delete`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDealerBalanceGetDealerBalanceByDealerPost(body?: GetDealerBalanceByDealerRQ, observe?: 'body', reportProgress?: boolean): Observable<GetDealerBalanceByDealerRP>;
    public apiDealerBalanceGetDealerBalanceByDealerPost(body?: GetDealerBalanceByDealerRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetDealerBalanceByDealerRP>>;
    public apiDealerBalanceGetDealerBalanceByDealerPost(body?: GetDealerBalanceByDealerRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetDealerBalanceByDealerRP>>;
    public apiDealerBalanceGetDealerBalanceByDealerPost(body?: GetDealerBalanceByDealerRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GetDealerBalanceByDealerRP>('post',`${this.basePath}/api/DealerBalance/GetDealerBalanceByDealer`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDealerBalanceGetPost(body?: GetDealerBalanceRQ, observe?: 'body', reportProgress?: boolean): Observable<GetDealerBalanceRP>;
    public apiDealerBalanceGetPost(body?: GetDealerBalanceRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetDealerBalanceRP>>;
    public apiDealerBalanceGetPost(body?: GetDealerBalanceRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetDealerBalanceRP>>;
    public apiDealerBalanceGetPost(body?: GetDealerBalanceRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GetDealerBalanceRP>('post',`${this.basePath}/api/DealerBalance/Get`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDealerBalanceListGet(observe?: 'body', reportProgress?: boolean): Observable<ListDealerBalanceRP>;
    public apiDealerBalanceListGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListDealerBalanceRP>>;
    public apiDealerBalanceListGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListDealerBalanceRP>>;
    public apiDealerBalanceListGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ListDealerBalanceRP>('get',`${this.basePath}/api/DealerBalance/List`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
