<div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Asignar a Vuelo </h4>
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="form-group col-12">
        <label class="form-label" for="shipping-type">
            <b class="text-primary">*</b> Tipo de envío:
        </label>
        <ng-select id="shipping-type" [items]="shippingTypes" bindLabel="name" bindValue="id" placeholder="Tipos de envío" (change)="FetchShippingsByType()" [(ngModel)]="selectedShippingType"
            notFoundText="No hay informacion">
        </ng-select>
    </div>
    <div class="form-group col-12">
        <label class="form-label" for="vertical-language">
            <b class="text-primary">*</b> Envíos:
        </label>
        <ng-select [items]="shippings" bindLabel="code" placeholder="Seleccione un envío" [(ngModel)]="selectedShipping"
            (change)="GetFlightByShippingId()" notFoundText="No hay envíos">
        </ng-select>
    </div>
    <div class="form-group col-12">
        <label class="form-label" for="vertical-language">
            <b class="text-primary">*</b> Vuelos:
        </label>
        <ng-select [items]="flights" bindLabel="code" placeholder="Seleccione un vuelo" [(ngModel)]="selectedFlight"
            notFoundText="No hay vuelos">
        </ng-select>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" rippleEffect (click)="AcceptModal()"
        [disabled]="selectedShipping === null || selectedFlight === null">Asignar</button>
    <button type="button" class="btn btn-danger" rippleEffect (click)="activeModal.close()">Cancelar</button>
</div>