import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { UpdateAssigmentSettingRP, UpdateAssigmentSettingRQ } from '../model/updateAssigmentSettingRP';
import { UpdateDealerWhiteListRQ, UpdateDealerWhiteListRP } from '../model/updateDealerWhiteListRQ';
import { GetAssigmentSettingStatusRP, GetAssigmentSettingTimeRP } from '../model/getAssigmentSettingStatusRP';
import { GetWhiteDealerListRP } from '../model/getWhiteDealerListRP';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AutoAssigmentSettingService {
  baseUrl = environment.apiUrl;
  protected basePath = "/";
  constructor(
    private httpClient: HttpClient
  ) { }

  updateAutoAssignAll(request: UpdateAssigmentSettingRQ): Observable<UpdateAssigmentSettingRP> {
    return this.httpClient.post<UpdateAssigmentSettingRP>(`${this.baseUrl}/api/AutoAssigmentSetting/UpdateAutoAssignAll`, request);
  }

  updateAutoAssign(request: { value: boolean }): Observable<UpdateAssigmentSettingRP> {
    return this.httpClient.post<UpdateAssigmentSettingRP>(`${this.baseUrl}/api/AutoAssigmentSetting/UpdateAutoAssign`, request);
  }

  UpdateDealerInWhiteList(request: { value: boolean }): Observable<UpdateDealerWhiteListRP> {
    return this.httpClient.post<UpdateDealerWhiteListRP>(`${this.baseUrl}/api/AutoAssigmentSetting/UpdateDealerInWhiteList`, request);
  }

  getAssigmentSettingStatus(): Observable<GetAssigmentSettingStatusRP> {
    return this.httpClient.get<GetAssigmentSettingStatusRP>(
      `${this.baseUrl}/api/AutoAssigmentSetting/GetAssigmentSettingByKey`
    );
  }

  getAssigmentTime(): Observable<GetAssigmentSettingTimeRP> {
    return this.httpClient.get<GetAssigmentSettingTimeRP>(
      `${this.baseUrl}/api/AutoAssigmentSetting/GetAssigmentTime`
    );
  }

  GetAssigmentTime

  getWhiteDealerList(): Observable<GetWhiteDealerListRP> {
    return this.httpClient.get<GetWhiteDealerListRP>(
      `${this.baseUrl}/api/AutoAssigmentSetting/GetAllDealersInWhiteList`
    );
  }

}
