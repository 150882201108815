<div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Asignar a Repartidor </h4>
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="d-flex justify-content-between bg-light-primary rounded p-2">
        <div>
            <h6 class="font-weight-normal">
                Municipio/Provincia:
                <small class="font-weight-bolder">{{bill.billCustomerModel.Location}}</small>
            </h6>
            <h6 class="font-weight-normal">
                Nombre:
                <small class="font-weight-bolder">{{bill.billCustomerModel.Name}}</small>
            </h6>
            <h6 class="font-weight-normal">
                Telefono:
                <small class="font-weight-bolder">{{bill.billCustomerModel.Phone}}</small>
            </h6>
            <h6 class="font-weight-normal">
                Referencia:
                <small class="font-weight-bolder">{{bill.billCustomerModel.ReferencePoint}}</small>
            </h6>
        </div>
        <div>
            <h6 class="font-weight-normal">
                No Factura:
                <!--<a href="{{urlApi + '/Bills/' + bill.billFile}}" target="_blank">
                    <small class="font-weight-bolder">{{bill.billCode}}</small>
                </a>-->
                <a class="link" (click)="DownloadPdf(bill.billId)" target="_self" placement="left"
                  ngbTooltip="Descargar pdf">
                  <small class="font-weight-bolder">{{bill.billCode}}</small>
                    </a
                  >
            </h6>
            <h6 class="font-weight-normal">
                Libras:
                <small class="font-weight-bolder">{{bill.billPounds}}</small>
            </h6>
            <h6 class="font-weight-normal">
                Precio X Libra:
                <small class="font-weight-bolder">{{bill.billPrice | currency}}</small>
            </h6>
            <h6 class="font-weight-normal">
                Servicio/Bonus:
                <small class="font-weight-bolder">{{bill.billServices | currency}}</small>
            </h6>
            <h6 class="font-weight-normal">
                 Moneda:
                <small class="font-weight-bolder">{{bill.currencyName }}</small>
            </h6>
            
            <div class="divider divider-dashed"></div>

            <h6 class="font-weight-normal">
                Total:
                <small class="font-weight-bolder">{{bill.billTotal | currency}}</small>
            </h6>
        </div>
    </div>
    <form [formGroup]="assignForm">
        <div class="row">
            <div class="form-group col-12 p-2">
                <label class="form-label">
                    <b class="text-primary">*</b> Repartidores:
                </label>
                <ng-select [items]="dealers" bindLabel="userName" bindValue="id" placeholder="Seleccione un repartidor"
                    [markFirst]="true" (change)="OnDealerSelected()" formControlName="dealerId"
                    notFoundText="No hay repartidores">
                </ng-select>
            </div>
            <div class="form-group col-12">
                <label class="form-label">
                    <b class="text-primary">*</b> Comisiones:
                </label>
                <ng-select [items]="currencies" bindLabel="name" bindValue="id"
                    (change)="OnCurrencySelected()" placeholder="Seleccione una comisión" formControlName="currencyId"
                    notFoundText="No hay comisiones">
                </ng-select>
            </div>
            <div class="form-group col-6">
                <label class="form-label" for="code-input">
                    <b class="text-primary">*</b> Libras:
                </label>
                <input type="text" class="form-control" formControlName="pounds" aria-describedby="code-input"
                    autofocus="" tabindex="1" (input)="OnChangeValue()" />
            </div>
            <div class="form-group col-6">
                <label class="form-label" for="code-input">
                    <b class="text-primary">*</b> Precio:
                </label>
                <input type="text" class="form-control" formControlName="price" aria-describedby="code-input"
                    autofocus="" tabindex="1" (input)="OnChangeValue()" />
            </div>
            <div class="form-group col-6">
                <label class="form-label" for="code-input">
                    <b class="text-primary">*</b> Servicios/Bonus:
                </label>
                <input type="text" class="form-control" formControlName="services" aria-describedby="code-input"
                    autofocus="" tabindex="1" (input)="OnChangeValue()" />
            </div>
            <div class="form-group col-6">
                <label class="form-label" for="code-input">Total</label>
                <input type="text" class="form-control" formControlName="total" aria-describedby="code-input"
                    autofocus="" tabindex="1" readonly />
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" rippleEffect (click)="AcceptModal()"
        [disabled]="assignForm.invalid">Asignar</button>
    <button type="button" class="btn btn-danger" rippleEffect (click)="activeModal.close()">Cancelar</button>
</div>