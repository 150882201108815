<div class="modal-header">
  <h2 class="modal-title" id="myModalLabel1">Vista previa <b>{{ operation }}</b> del despacho <b> {{ dispatchName }} </b> para el repartidor <b>{{ dealer.userName }}</b>  </h2>
  <button type="button" class="close" (click)="activeModal.close()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
  <div class="row">
    <div class="col">
     <table class="table table-striped pt-2">
       <thead>
         <tr>
           <th class="text-center" scope="col">#</th>
           <th class="text-center" scope="col">Código</th>
           <th class="text-center" scope="col">Orígen</th>
           <th class="text-center" scope="col">Destino</th>    
           <th class="text-center" scope="col">Peso</th>     
           <th class="text-center" scope="col">Total</th>             
         </tr>
         <tr *ngIf="allAssignments?.length === 0">
           <th class="text-center" scope="col" colspan="7">
             <label>No existen facturas en el despacho</label>
           </th>
         </tr>
       </thead>
       <tbody>
         <tr *ngFor="let bill of alloperations; let i = index">
           <th class="text-center" scope="row">{{i + 1}}</th>
           <td class="text-center">{{ bill?.billCode}}</td>
           <td class="text-center">{{ bill?.billSenderModel.Name}}</td>
           <td class="text-center">{{ bill?.billCustomerModel.Name}}</td>
           <td class="text-center">{{ bill?.pounds | number: '1.2-2'}}</td>   
           <td class="text-center">{{ bill?.total | currency}}</td>                     
         </tr>
       </tbody>
     </table>
     <div class="d-flex justify-content-between p-2" *ngIf="allAssignments.length > 0">
      <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" [maxSize]="4"
        [rotate]="true" [boundaryLinks]="true">
      </ngb-pagination>
      <select class="custom-select" style="width: auto" [(ngModel)]="pageSize">
        <option [ngValue]="1">1 órden por página</option>
        <option [ngValue]="5">5 ordenes por página</option>
        <option [ngValue]="10">10 ordenes por página</option>
      </select>
    </div>
    </div>
   </div>
</div>
<div class="modal-footer">
  <div class="d-flex justify-content-end align-items-center">
    <button type="button" class="btn btn-primary px-2 mx-2" rippleEffect (click)="AcceptModal()"
        [disabled]="allAssignments.length == 0">Aceptar</button>
    <button type="button" class="btn btn-danger" rippleEffect (click)="activeModal.close()">Cancelar</button>
  </div>
</div>

