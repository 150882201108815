<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel1">Filtrar </h4>
  <button type="button" class="close" (click)="activeModal.close()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
  <form [formGroup]="shipmentForms">
    <div class="form-group">              
        <input type="text" class="form-control" placeholder="Palabra clave" formControlName="clave" aria-describedby="code-input" autofocus=""
            tabindex="1" />
        
    </div>
    <div class="form-group">      
      <div class="input-group">
        <input class="form-control" (dateSelect)="onDateSelection($event)" 
      
      outsideDays="hidden"
      
      tabindex="-1"
       placeholder="Fecha inicio" formControlName="desde"
            ngbDatepicker #basicDPD="ngbDatepicker" aria-describedby="date-input"                
            readonly />
        <div class="input-group-append">
            <button class="btn btn-outline-secondary feather icon-calendar" (click)="basicDPD.toggle()"
                type="button" rippleEffect></button>
        </div>
      </div>
      
       
    </div>  
    <div class="form-group">      
      <div class="input-group">
        <input class="form-control" [minDate]="desde!" 
        placeholder="Fecha fin" name="Fecha del Envio" formControlName="hasta"
              ngbDatepicker #basicDPH="ngbDatepicker" aria-describedby="date-input"                
              readonly />
          <div class="input-group-append">
              <button class="btn btn-outline-secondary feather icon-calendar" (click)="basicDPH.toggle()"
                  type="button" rippleEffect></button>
        </div>
      </div>
           
  </div> 
    <div class="form-group">
      <ng-select #provincias placeholder="Provincias" (change)="OnProvinceChange()" [clearable]="true" notFoundText="No hay datos" bindValue="id" [items]="states" bindLabel="name"
      formControlName="provincia" >
                                                </ng-select>
        
    </div>
    <div class="form-group">      
      <ng-select #municipios placeholder="Municipios" (change)="OnMunicipalityChange()" [clearable]="true" notFoundText="No hay datos" bindValue="id" [items]="cities" bindLabel="name"
      formControlName="municipio">
                                                </ng-select>  
    </div>
    <div class="form-group">      
      <ng-select formControlName="tipoEnvio" [clearable]="true" aria-describedby="shippingType-input" (change)="OnTypeShippinChange()" [items]="shippingTypes" bindLabel="name" bindValue="id" placeholder="Tipos de envío"
      notFoundText="No hay información">
     </ng-select>    </div>

     <div class="form-group">      
     
      <ng-select [items]="shippings" notFoundText="No hay información" (change)="OnShippingChange()" [clearable]="true" bindLabel="code" bindValue="id" placeholder="Envío"
                formControlName="envios" aria-describedby="shipping-input">
                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                    {{ item.code }} - {{ item. description }}
                </ng-template>
            </ng-select>  
          </div> 

          <div class="form-group"> 
            <ng-select [clearable]="true" (change)="OnFlightChange()" placeholder="Vuelos" notFoundText="No hay vuelos" bindValue="id" [items]="flights" bindLabel="code"
                                                    formControlName="vuelos" >
                                                </ng-select>    
             </div>

     <div class="form-group">      
      <ng-select formControlName="estadoFactura" (change)="OnBillStateChange()" [clearable]="true" aria-describedby="shippingType-input" [items]="catalogStates" bindLabel="name" bindValue="id" placeholder="Estados"
      notFoundText="No hay información">
     </ng-select>    </div>
    <div class="form-group">    
      <div class="row">
        <div class="col-md-6 col-12">
          <button class="btn btn-primary" type="button" rippleEffec (click)="AcceptModal()"      
      >Buscar</button>
        </div>
        <div class="col-md-6 col-12 text-right">
          <button class="btn btn-outline-primary" type="button" rippleEffect (click)="resetFilters()"      
      >Borrar filtros</button>
        </div>
        <div class="col-md-12 col-12">
          <mat-error class="pt-1" *ngIf="noSearchSelected"
            >Debe seleccionar un parámetro de búsqueda.</mat-error
          >
        </div>
      </div>    
      
      
    </div>
</form>
</div>
<div class="modal-footer">
  <div class="d-flex justify-content-end align-items-center">
  </div>
</div>
