export * from './assigment.service';
import { AssigmentService } from './assigment.service';
export * from './bill.service';
import { BillService } from './bill.service';
export * from './city.service';
import { CityService } from './city.service';
export * from './currency.service';
import { CurrencyService } from './currency.service';
export * from './customer.service';
import { CustomerService } from './customer.service';
export * from './dashboard.service';
import { DashboardService } from './dashboard.service';
export * from './dealer.service';
import { DealerService } from './dealer.service';
export * from './dealerBalance.service';
import { DealerBalanceService } from './dealerBalance.service';
export * from './expenceCatalog.service';
import { ExpenceCatalogService } from './expenceCatalog.service';
export * from './flight.service';
import { FlightService } from './flight.service';
export * from './flightExpence.service';
import { FlightExpenceService } from './flightExpence.service';
export * from './roles.service';
import { RolesService } from './roles.service';
export * from './sender.service';
import { SenderService } from './sender.service';
export * from './shipping.service';
import { ShippingService } from './shipping.service';
export * from './shippingBalance.service';
import { ShippingBalanceService } from './shippingBalance.service';
export * from './shippingExpense.service';
import { ShippingExpenseService } from './shippingExpense.service';
import { SignalRService } from './SignalR.service';
export * from './state.service';
import { StateService } from './state.service';
export * from './systemConfiguration.service';
import { SystemConfigurationService } from './systemConfiguration.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [AssigmentService, BillService, CityService, CurrencyService, CustomerService, DashboardService, DealerService, DealerBalanceService, ExpenceCatalogService, FlightService, FlightExpenceService, RolesService, SenderService, ShippingService, ShippingBalanceService, ShippingExpenseService, StateService, SystemConfigurationService, UserService,SignalRService];
