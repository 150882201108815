import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AssigmentService, FlightListModel, FlightService, GetByFlightAssigmentIdRP, GetByFlightAssigmentIdRQ, ListFlightByShippingRQ, ShippingListModel, ShippingService } from 'app/open-api';
import { ShippingListRQ } from '../../../open-api/model/ShippingListRQ';
import { ShippingType, shippingTypes } from '../../../open-api/model/shippingType';

@Component({
  selector: 'app-edit-flight-assigment-modal',
  templateUrl: './edit-flight-assigment-modal.component.html',
  styleUrls: ['./edit-flight-assigment-modal.component.scss']
})
export class EditFlightAssigmentModalComponent implements OnInit {

  @Input() assigmentId: number;
  shippings: ShippingListModel[] = [];
  flights: FlightListModel[] = [];
  selectedShipping: ShippingListModel = null;
  selectedFlight: FlightListModel = null;
  assignment: GetByFlightAssigmentIdRP;
  shippingTypes: Array<ShippingType> = [];
  selectedShippingType: any;

  constructor(
    public activeModal: NgbActiveModal,
    private shippingService: ShippingService,
    private flightService: FlightService,
    private assigmentService: AssigmentService,
  ) { 
    this.shippingTypes = shippingTypes;
  }

  async ngOnInit(): Promise<void> {
    await this.FetchModels();
  }

  async FetchModels(): Promise<void> {
    const param: GetByFlightAssigmentIdRQ = {
      id: this.assigmentId
    };
    await this.assigmentService.apiAssigmentGetByFlightAssigmentPost(param).toPromise().then(response => {
      this.assignment = response;
    });
    const request: ShippingListRQ = {      
    }
    await this.shippingService.apiShippingListGet(request).toPromise().then(response => {
      this.shippings = response.list;
    });
    this.selectedShipping = this.shippings.find(shippins => shippins.id === this.assignment.shippingId);
    if(this.selectedShipping)
      {
        this.selectedShippingType = this.shippingTypes.find(type => type.id === this.selectedShipping.shippingType).id;
      }
    await this.GetFlightByShippingId();
    this.selectedFlight = this.flights.find(flight => flight.id === this.assignment.flightId);
  }

  FetchShippingsByType() {    
    this.shippings = [];
    this.flights = [];
    this.selectedShipping = null;
    this.selectedFlight = null;
    const request: ShippingListRQ = {  
      shippingType: this.selectedShippingType
    }

    this.shippingService.apiShippingListGet(request).subscribe(response => {
      this.shippings = response.list;      
    });
  }

  async GetFlightByShippingId(): Promise<void> {
    this.selectedFlight = null;
    if (this.selectedShipping) {
      const params: ListFlightByShippingRQ = {
        shippingId: this.selectedShipping.id
      };
      await this.flightService.apiFlightListByShippingPost(params).toPromise().then(response => {
        this.flights = response.list;
      });
    } else {
      this.flights = [];
    }
  }

  AcceptModal() {
    this.assignment.flightId = this.selectedFlight.id;
    this.activeModal.close(this.assignment);
  }
}
