import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { GetDealerBalanceRP } from "app/open-api";
import domToImage from "dom-to-image";
import jsPDF, { jsPDFOptions } from "jspdf";
import moment from "moment";

@Component({
  selector: "app-dealer-balance-detail",
  templateUrl: "./dealer-balance-detail.component.html",
  styleUrls: ["./dealer-balance-detail.component.scss"],
})
export class DealerBalanceDetailComponent implements OnInit {
  @Input() data: GetDealerBalanceRP;
  @ViewChild("modalPrint", { static: false }) dataToExport: ElementRef;
  currenciesResumen: any[] = [];
  totalPound: number = 0;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.totalPound = this.data.billIds.reduce((accumulator, assigment) => {
      return accumulator + assigment.billPounds;
    }, 0);
    this.currenciesResumen = JSON.parse(this.data.currenciesSummary);
  }

  exportAsPDF() {
    const width = this.dataToExport.nativeElement.clientWidth;
    const height = this.dataToExport.nativeElement.clientHeight + 40;
    domToImage
      .toPng(this.dataToExport.nativeElement, {
        width: width,
        height: height,
      })
      .then((result) => {
        let jsPdfOptions: jsPDFOptions = {
          orientation: width > height ? "l" : "p",
          unit: "pt",
          format: [width + 50, height + 220],
        };
        const pdf = new jsPDF(jsPdfOptions);
        pdf.addImage(result, "PNG", 25, 185, width, height);
        pdf.setFontSize(16);
        pdf.setTextColor("#131523");
        pdf.text("Fecha del reporte: " + moment().format("ll"), 25, 115);
        pdf.save(
          this.data.dealerName + " " + moment().format("yyyyMMDD") + ".pdf"
        );
      })
      .catch((error) => {});
  }
}
