import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AssigmentService, FlightListModel, FlightService, ListFlightByShippingRQ, ShippingListModel, ShippingService } from 'app/open-api';
import { ShippingType, shippingTypes } from '../../../open-api/model/shippingType';
import { ShippingListRQ } from '../../../open-api/model/ShippingListRQ';

@Component({
  selector: 'app-assign-bill-flight-modal',
  templateUrl: './assign-bill-flight-modal.component.html',
  styleUrls: ['./assign-bill-flight-modal.component.scss']
})
export class AssignBillFlightModalComponent implements OnInit {

  shippings: ShippingListModel[] = [];
  flights: FlightListModel[] = [];
  selectedShipping: ShippingListModel = null;
  selectedFlight: FlightListModel = null;
  shippingTypes: Array<ShippingType> = [];
  selectedShippingType: any;
  constructor(
    public activeModal: NgbActiveModal,
    private shippingService: ShippingService,
    private flightService: FlightService
  ) { 
    this.shippingTypes = shippingTypes;
  }

  ngOnInit(): void {
    this.FetchModels();
  }

  FetchModels() {
    const request: ShippingListRQ = {  

    }

    this.shippingService.apiShippingListGet(request).subscribe(response => {
      this.shippings = response.list;
    });
  }

  FetchShippingsByType() {    
    this.shippings = [];
    this.flights = [];
    const request: ShippingListRQ = {  
      shippingType: this.selectedShippingType
    }

    this.shippingService.apiShippingListGet(request).subscribe(response => {
      this.shippings = response.list;
    });
  }

  GetFlightByShippingId() {    
    if (this.selectedShipping) {
      const params: ListFlightByShippingRQ = {
        shippingId: this.selectedShipping.id
      };
      this.flightService.apiFlightListByShippingPost(params).subscribe(response => {
        this.flights = response.list;
      });
    } else {
      this.flights = [];
    }
  }

  AcceptModal() {
    this.activeModal.close(this.selectedFlight);
  }
}
