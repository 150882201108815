/**
 * OskPack.WebApi.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ShippingListModel { 
    id?: number;
    code?: string;
    shippingTypeName?: string;    
    description?: string;
    createAt?: Date;
    totalCost?: number; 
    isActive?: boolean;   
    closeAt?: Date;
    closeBalanceAt?: Date;    
    shippingType?: number;
    hasFlights?: boolean;
}

export interface ShippingListExportModel {     
    CODIGO?: string;
    TIPO_ENVIO?: string;   
    FECHA_CREACION?: string;
    COSTO?: string; 
    DESCRIPCION?: string;    
    ACTIVO?: boolean;   
    FECHA_CIERRE?: string;
    FECHA_BALANCE?: string;     
}