<div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Vuelos del Envío: {{shipping?.code}} </h4>
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
    <form [formGroup]="flightForms">
        <div class="row">
            <div class="col-4">
                <div class="form-group">
                    <label class="form-label" for="code-input">
                        <b class="text-primary">*</b> Código:
                    </label>
                    <input type="text" class="form-control" formControlName="code" aria-describedby="code-input"
                        autofocus="" tabindex="1"
                        [ngClass]="{ 'is-invalid': f.code.errors && (f.code.touched || f.code.dirty) }" />
                    <div *ngIf="f.code.errors && (f.code.touched || f.code.dirty)" class="invalid-feedback">
                        <div *ngIf="f.code.errors.required">El código es requerido.</div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label class="form-label" for="date-input">
                        <b class="text-primary">*</b> Fecha del Vuelo:
                    </label>
                    <div class="input-group">
                        <input class="form-control" placeholder="yyyy-mm-dd" name="Fecha del Vuelo"
                            formControlName="flightDate" ngbDatepicker #basicDP="ngbDatepicker"
                            aria-describedby="date-input" [ngClass]="{ 'is-invalid': f.flightDate.errors }" readonly />
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary feather icon-calendar" (click)="basicDP.toggle()"
                                type="button" rippleEffect></button>
                        </div>
                    </div>
                    <div *ngIf="f.flightDate.errors && (f.flightDate.touched || f.flightDate.dirty)"
                        class="invalid-feedback">
                        <div *ngIf="f.flightDate.errors.required">La fecha es requerida.</div>
                    </div>
                </div>
            </div>
            <div class="col-4 d-flex justify-content-center align-items-start">
                <button type="button" class="btn btn-primary mt-2" rippleEffect (click)="AcceptModal()"
                    [disabled]="flightForms.invalid">
                    Adicionar Vuelo
                </button>
            </div>
        </div>
    </form>
    <ul class="list-group">
        <li class="list-group-item d-flex justify-content-between align-items-center" *ngIf="flights?.length === 0">
            <span>No hay vuelos registrados.</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let flight of flights">
            <span>{{flight?.code}}</span>
            <div class="">
                <span class="badge badge-primary badge-pill mr-2">{{flight?.flightDate | date: 'dd/MM/YYYY'}}</span>
                <button type="button" ngbTooltip="Eliminar" placement="left" (click)="Delete(flight.id)"
                    class="btn btn-icon btn-outline-danger text-danger cursor-pointer mr-2" rippleEffect>
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                </button>
                <button type="button" ngbTooltip="Editar" placement="left" (click)="Edit(flight)"
                    class="btn btn-icon btn-outline-info text-info cursor-pointer mr-2" rippleEffect>
                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
            </div>
        </li>
    </ul>
</div>
<div class="modal-footer">
</div>