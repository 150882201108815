<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel1">Comisiones del distribuidor:  : <b>{{dealerName}}</b></h4>
  <button type="button" class="close" (click)="activeModal.close()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>    
  <form [formGroup]="dealerForms">
      <div class="row pl-1 pt-1">
        <div class="col-md-2 col-12">
            <div class="form-group">    
                <label for="shippingType">
                    <b class="text-primary">*</b> Tipo envío:
                </label>           
                <ng-select formControlName="shippingType" [clearable]="true" aria-describedby="shippingType-input" [items]="shippingTypes" bindLabel="name" bindValue="id"
                notFoundText="No hay información">
               </ng-select>                        
            </div>
        </div>
          <div class="col-md-2 col-12">
              <div class="form-group"> 
                <label for="min">
                    <b class="text-primary">*</b> Min:
                </label>                 
                  <input type="number" min="0" minlength="1" (keypress)="NumberOnly($event)"  maxlength="5" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" class="form-control" formControlName="min" aria-describedby="code-input"
                      autofocus="" tabindex="1"
                      [ngClass]="{ 'is-invalid': f.min.errors && (f.min.touched || f.min.dirty) }" />
                  <div *ngIf="f.min.errors && (f.min.touched || f.min.dirty)" class="invalid-feedback">
                      <div *ngIf="f.min.errors.required">El mínimo es requerido.</div>
                  </div>
                  <div *ngIf="f.min.errors?.['minValue']">
                    El mínimo es requerido.
                  </div>
              </div>
          </div>
          <div class="col-md-2 col-12">
            <div class="form-group">  
                <label for="Max">
                    <b class="text-primary">*</b> Max:
                </label>               
                <input type="number" min="1" minlength="1" (keypress)="NumberOnly($event)"  maxlength="5" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" class="form-control" formControlName="max" aria-describedby="code-input"
                    autofocus="" tabindex="1"
                    [ngClass]="{ 'is-invalid': f.max.errors && (f.max.touched || f.max.dirty) }" />
                <div *ngIf="f.max.errors && (f.max.touched || f.max.dirty)" class="invalid-feedback">
                    <div *ngIf="f.max.errors.required">El máximo es requerido.</div>
                </div>
                <div *ngIf="f.max.errors?.['maxValue']">
                    El máximo es requerido.
                  </div>
            </div>
        </div>
        <div class="col-md-2 col-12">
            <div class="form-group">    
                <label for="Tipo">
                    <b class="text-primary">*</b> Tipo de pago:
                </label>                 
                <ng-select formControlName="taxType" [clearable]="true" aria-describedby="shippingType-input" [items]="commisionsTypes" bindLabel="name" bindValue="id"
                notFoundText="No hay información">
               </ng-select>                        
            </div>
        </div>
        <div class="col-md-2 col-12">
            <div class="form-group">  
                <label for="amount">
                    <b class="text-primary">*</b> Valor:
                </label>               
                <input type="text" class="form-control" (keypress)="NumberOnly($event)" formControlName="amount" aria-describedby="code-input"
                    autofocus="" tabindex="1"
                    [ngClass]="{ 'is-invalid': f.amount.errors && (f.amount.touched || f.amount.dirty) }" />
                <div *ngIf="f.amount.errors && (f.amount.touched || f.amount.dirty)" class="invalid-feedback">
                    <div *ngIf="f.amount.errors.required">El cantidad es requerida.</div>
                </div>
            </div>
        </div>
        <div class="col-md-2 col-12">
            <div class="form-group">     
                <label for="currency">
                    <b class="text-primary">*</b> Moneda:
                </label>             
                <ng-select formControlName="currencyId" [clearable]="true" aria-describedby="shippingType-input" [items]="currencies" bindLabel="name" bindValue="id"
                notFoundText="No hay información">
               </ng-select>                  
            </div>
        </div>
        <div class="col-md-2 col-12">           
            <button class="btn btn-primary" [disabled]="dealerForms.invalid" #addComission rippleEffect (click)="AddCommision()">
                <i data-feather="plus" class="mr-0 mr-sm-1"></i><span class="d-none d-sm-inline-block">
                    {{buttonOperation}}</span>
            </button>        
        </div>  
        <div class="col-md-10 col-12">          
           
            <mat-error *ngIf="dealerForms.errors?.['forbiddenCommision']"
            >El valor mínimo debe ser menor que el valor máximo.</mat-error
          >
        </div>     
      </div>
  </form>  
  <div class="row pl-1 pt-1">
    <div class="col-md-12 col-12">
        <ul class="list-group">
            <li class="list-group-item d-flex justify-content-between align-items-center" *ngIf="dealerTaxs?.length === 0">
                <span>No hay comisiones registradas.</span>
            </li>        
            <li class="list-group-item d-flex justify-content-between align-items-center mr-2 pr-2" *ngFor="let tax of dealerTaxs">
                <span class="col-2 text-left">{{getShippingTypeText(tax.shippingType)}}</span>
                <span class="col-1 text-left">{{tax.min}}</span>
                <span class="col-1 text-left">{{tax.max}}</span>
                <span class="col-2 text-center" *ngIf="tax.taxType == 1">Fijo</span>
                <span class="col-2 text-center" *ngIf="tax.taxType == 2">Por Libras</span>
                <span class="col-2 text-center">{{tax.amount}}</span>
                <span class="col text-center">{{tax.currency?.name}}</span>
                
                <div class="col-2 text-center">
                    <span class="badge badge-primary badge-pill mr-2"></span>
                    <button type="button" ngbTooltip="Editar" placement="left" (click)="EditCommision(tax)"
                        class="btn btn-icon btn-outline-info text-info cursor-pointer mr-2" rippleEffect>
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </button>
                    <button type="button" ngbTooltip="Eliminar" placement="left" (click)="DeleteCommision(tax.id)"
                        class="btn btn-icon btn-outline-danger text-danger cursor-pointer mr-2" rippleEffect>
                        <i class="fa fa-trash-o" aria-hidden="true"></i>
                    </button>
                    
                </div>
            </li>
        </ul>
    </div>
    
  </div>
    
  

</div>
<div class="modal-footer">
</div>