import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  ElementRef,
  Input,
  Output,
  OnInit,
  ViewChild,
  Renderer2
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  CityModel,
  ListFlightByShippingRQ,
  ShippingService,
  FlightService,
  ShippingListModel,
  FlightListModel,
  CityService,
  ListCityByStateRQ,
  StateModel,
  StateService,
  ListStateRQ
} from "app/open-api";
import {
  FilterSearchModel,
  FilterSearchObj,
} from "./../../models/filter-search.model";
import {
  CatalogBillState
} from "./../../models/catalogBillState";

import { FilterService } from "./../../services/filter.service";
import { take } from "rxjs/operators";
import { Observable } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import {
  NgbDatepicker, 
  NgbInputDatepicker, 
  NgbDateStruct, 
  NgbCalendar, 
  NgbDateAdapter,
  NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {NgModel} from "@angular/forms";
import {Subscription} from 'rxjs';
import { ShippingListRQ } from '../../../open-api/model/ShippingListRQ';
import { ShippingType, shippingTypes } from '../../../open-api/model/shippingType';

const now = new Date();
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;


@Component({
  selector: "app-multiple-filters",
  templateUrl: "./multiple-filters.component.html",
  styleUrls: ["./multiple-filters.component.scss"],
})
export class MultipleFiltersComponent implements OnInit {
  @ViewChild("provincias") private provincias: ElementRef;
  @ViewChild("municipios") private municipios: ElementRef;
  selectedProvince: string = "";
  selectedMunicipality: string = "";
  selectedState: string = "";
  selectedShipping: string = "";
  selectedFlight: string = "";
  
  filterForm: FormGroup;
  date = new Date();
  states: StateModel[] = [];
  cities: CityModel[] = [];
  shippings: ShippingListModel[] = [];
  flights: FlightListModel[] = [];
  catalogStates: CatalogBillState[] = [];
  today = {
    year: this.date.getFullYear(),
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  @Output() filterResult: EventEmitter<any> = new EventEmitter();
  @Output() filterReset: EventEmitter<boolean> = new EventEmitter();
  @Input() father: string;
  loadedFilter$: Observable<any>;
  currentFilter: FilterSearchObj;
  startDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  minDate: NgbDateStruct;
  hoveredDate: NgbDateStruct;
  desde: any;
  hasta: any;
  model: any;
  private _subscription: Subscription;
  private _selectSubscription: Subscription;
  @ViewChild("d") input: NgbInputDatepicker;
  @ViewChild(NgModel) datePick: NgModel;
  @ViewChild("myRangeInput") myRangeInput: ElementRef;
  shippingTypes: Array<ShippingType> = [];
  selectedShippingType: string;

  isHovered = (date) =>
    this.desde &&
    !this.hasta &&
    this.hoveredDate &&
    after(date, this.desde) &&
    before(date, this.hoveredDate);
  isInside = (date) => after(date, this.desde) && before(date, this.hasta);
  isFrom = (date) => equals(date, this.desde);
  isTo = (date) => equals(date, this.hasta);

  constructor(
    private fb: FormBuilder,
    private stateService: StateService,
    private cityService: CityService,
    private calendar: NgbCalendar,
    private shippingService: ShippingService,
    private flightService: FlightService,
    private filterService: FilterService,
    private spinner: NgxSpinnerService,
    public formatter: NgbDateParserFormatter,
    element: ElementRef,
    private renderer: Renderer2,
    private _parserFormatter: NgbDateParserFormatter
  ) {
    this.shippingTypes = shippingTypes;
    this.loadedFilter$ = filterService.getFilterObservable;
    this.filterForm = fb.group({
      provincia: [null],
      municipio: [null],
      desde: [""],
      hasta: [""],
      vuelos: [null],
      envios: [null],
      shippingType: [null],
      telefono: [""],
      states:[null]
    });
  }

  async ngOnInit() {
    this.spinner.show();
    await this.FetchState();
    await this.FetchShippings();
    await this.UpdateFilterFields();
    await this.FetchBillStates();
    this.spinner.hide();
  }
  
  resetProvincias() {
    this.filterForm.controls["provincia"].setValue(null);
    this.filterForm.controls["municipio"].setValue(null);
    this.cities = [];
    this.selectedProvince = "";
    this.selectedMunicipality = "";

    if(this.currentFilter != undefined)
    if (
      this.currentFilter.provincia != null &&
      this.currentFilter.provincia != ""
    ) this.applyFilters();
   
  }

  FetchShippingsByType() {
    if (this.filterForm.get("shippingType").value != null) {
      this.selectedShippingType =
        this.filterForm.controls["shippingType"].value.toString();
      this.shippings = [];
      this.flights = [];
      this.selectedShipping = null;
      this.selectedFlight = null;
      const request: ShippingListRQ = {
        shippingType: this.filterForm.controls["shippingType"].value,
      };

      this.shippingService.apiShippingListGet(request).subscribe((response) => {
        this.shippings = response.list;
      });      
  }
}

  resetMunicipios() {
    this.filterForm.controls["municipio"].setValue(null);
    //this.cities = [];
    this.selectedMunicipality = ""; 

    if(this.currentFilter != undefined)
    if (this.currentFilter.municipio != null &&
      this.currentFilter.municipio != ""
    ) this.applyFilters();   
  }

  resetStateCatalog() {
    this.filterForm.controls["states"].setValue(null);
    //this.cities = [];
    this.selectedState = ""; 

    if(this.currentFilter != undefined)
    if (this.currentFilter.state != null &&
      this.currentFilter.state != ""
    ) this.applyFilters();   
  }

  resetEnvios() {
    this.selectedShipping = "";
    this.selectedFlight = "";
    this.filterForm.controls["envios"].setValue(null); 
    this.filterForm.controls["vuelos"].setValue(null);      

    if(this.currentFilter != undefined)
    if (this.currentFilter.envios != null &&
      this.currentFilter.envios != ""
    ) this.applyFilters();        
  }

  resetTiposEnvio() {
    this.selectedShipping = "";
    this.selectedFlight = "";
    this.selectedShippingType = "";
    this.filterForm.controls["shippingType"].setValue(null);
    this.filterForm.controls["envios"].setValue(null); 
    this.filterForm.controls["vuelos"].setValue(null);      

    if(this.currentFilter != undefined)
    if (this.currentFilter.tipoEnvio != null &&
      this.currentFilter.tipoEnvio != ""
    ) this.applyFilters();        
  }

  resetVuelos() {
    this.selectedFlight = "";
    this.filterForm.controls["vuelos"].setValue(null);     

    if(this.currentFilter != undefined)
    if (this.currentFilter.vuelos != null &&
      this.currentFilter.vuelos != ""
    ) this.applyFilters();        
  }

  async UpdateFilterFields() {
    const data = await this.loadedFilter$.pipe(take(1)).toPromise();
    if (data !== undefined) {
      let filter: FilterSearchObj = (this.currentFilter = data);
      console.log(filter)
      if (filter.father == this.father) {
        if (filter.provincia !== "" && filter.provincia != null) {
          this.selectedProvince =
            this.states.find((i) => i.id === +filter.provincia)?.name ??
            this.selectedProvince;
          await this.FetchCities();
        }

        if (filter.tipoEnvio !== "" && filter.tipoEnvio != null) {
          this.selectedShippingType = this.shippingTypes.find(
            (i) => i.id === +filter.tipoEnvio
          ).id.toString();
          await this.FetchShippings();
        }

        if (filter.envios !== "" && filter.envios != null) {
          this.selectedShipping = this.shippings.find(
            (i) => i.id === +filter.envios
          ).code;
          await this.FetchFlights();
        }        

        if (filter.vuelos !== "" && filter.vuelos != null) {
          this.selectedFlight = this.flights.find(
            (i) => i.id === +filter.vuelos
          ).code;
        }

        if (filter.municipio !== "" && filter.municipio != null) {
          this.selectedMunicipality = this.cities.find(
            (i) => i.id === +filter.municipio
          ).name;
        }

        this.filterForm.controls["provincia"].setValue(filter.provincia);
        this.filterForm.controls["municipio"].setValue(filter.municipio);
        this.filterForm.controls["states"].setValue(filter.state);
        
        this.filterForm.controls["shippingType"].setValue(filter.tipoEnvio ? +filter.tipoEnvio : filter.tipoEnvio);
        this.filterForm.controls["vuelos"].setValue(filter.vuelos);
        this.filterForm.controls["envios"].setValue(filter.envios);
        this.filterForm.controls["telefono"].setValue(filter.telefono);
      } else {
        this.filterService.setFilterObservable = undefined;
      }
    }
  }

  onDateSelection(date: NgbDateStruct) {
    let parsed = "";
    if (!this.desde && !this.hasta) {
      this.desde = date;
    } else if (this.desde && !this.hasta && after(date, this.desde)) {
      this.hasta = date;
      // this.model = `${this.fromDate.year} - ${this.toDate.year}`;
      this.input.close();
    } else {
      this.hasta = null;
      this.desde = date;
    }
    if (this.desde) {
      parsed += this._parserFormatter.format(this.desde);
    }
    if (this.hasta) {
      parsed += " - " + this._parserFormatter.format(this.hasta);
    }

    //this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', parsed);
  }

  ResetFields() {
    this.filterForm.controls["provincia"].setValue(null);
    this.filterForm.controls["municipio"].setValue(null);
    this.filterForm.controls["desde"].setValue("");
    this.filterForm.controls["hasta"].setValue("");
    this.filterForm.controls["vuelos"].setValue(null);
    this.filterForm.controls["envios"].setValue(null);
    this.filterForm.controls["telefono"].setValue("");
    this.filterForm.controls["shippingType"].setValue(null);
    this.filterForm.controls["states"].setValue(null);
  }

  async FetchState() {
    let params: ListStateRQ = {
      type: 1
    }
    await this.stateService
      .apiStateListGet(params)
      .toPromise()
      .then((response) => {
        this.states = response.list;
      })
      .catch((error) => {});
  }

  async FetchCities() {
    if (
      this.currentFilter.provincia != null &&
      this.currentFilter.provincia != ""
    ) {
      const param: ListCityByStateRQ = {
        stateId: +this.currentFilter.provincia,
      };
      await this.cityService
        .apiCityListByStatePost(param)
        .toPromise()
        .then((res) => {
          this.cities = res.list;
        });
    }
  }

  async FetchBillStates(){
    if(this.father == "flight"){
      this.catalogStates = [
        {
          id: 2,
          name: "ASIG. VUELO",
        },
        {
          id: 4,
          name: "RECEP. CUBA",
        }
      ];       
    }
    else {
      this.catalogStates = [
        {
          id: 2,
          name: "ASIG. VUELO",
        },
        {
          id: 3,
          name: "ENV. CUBA",
        },
        {
          id: 4,
          name: "RECEP. CUBA",
        },
        {
          id: 5,
          name: "ASIG. REPARTIDOR",
        },
        {
          id: 6,
          name: "RECEP. REPARTIDOR",
        },
        {
          id: 7,
          name: "ENTREGADA",
        },
        {
          id: 8,
          name: "CERR. CONTABLEMENTE",
        },
      ];     
    }
  }

  async FetchShippings() {    
    if(this.currentFilter){
      const request: ShippingListRQ = {     
        shippingType: this.currentFilter.tipoEnvio ? +this.currentFilter.tipoEnvio : null 
      }    
  
      await this.shippingService
        .apiShippingListGet(request)
        .toPromise()
        .then((response) => {
          this.shippings = response.list;
        })
        .catch((error) => {});
    }    
  }

  async FetchFlights() {
    const params: ListFlightByShippingRQ = {
      shippingId: +this.currentFilter.envios,
    };
    await this.flightService
      .apiFlightListByShippingPost(params)
      .toPromise()
      .then((response) => {
        this.flights = response.list;
      });
  }

  OnShippingTypeChange(){
    if (this.filterForm.get("shippingType").value != null) {
      this.selectedFlight = this.flights.find(
        (i) => i.id === +this.filterForm.get("vuelos").value
      ).code;
    } else this.selectedFlight = "";
    const request: ShippingListRQ = {  
      shippingType: this.filterForm.controls["shippingType"].value
    }
  }

  OnFlightChange() {
    if (this.filterForm.get("vuelos").value != null) {
      this.selectedFlight = this.flights.find(
        (i) => i.id === +this.filterForm.get("vuelos").value
      ).code;
    } else this.selectedFlight = "";
  }

  OnCatalogStateChange() {
    if (this.filterForm.get("states").value != null) {
      this.selectedState = this.catalogStates.find(
        (i) => i.id === +this.filterForm.get("states").value
      ).id.toString();
    } else this.selectedState = "";
  }

  OnShippingChange() {
    if (this.filterForm.get("envios").value != null) {
      this.selectedShipping = this.shippings.find(
        (i) => i.id === +this.filterForm.get("envios").value
      ).code;

      const params: ListFlightByShippingRQ = {
        shippingId: this.filterForm.get("envios").value,
      };
      this.flightService
        .apiFlightListByShippingPost(params)
        .subscribe((response) => {
          this.flights = response.list;
        });
    } else {
      this.selectedShipping = "";
      this.selectedFlight = "";
      this.flights = [];
      this.filterForm.get("vuelos").setValue(null);
    }
  }

  OnCityChange() {
    if (this.filterForm.get("municipio").value != null) {
      let mun = this.cities.find((i) => i.id === +this.filterForm.get("municipio").value);
      this.selectedMunicipality = mun.name;
    } else this.selectedMunicipality = "";
  }

  OnStateChange() {
    if (this.filterForm.get("provincia").value != null) {
      this.selectedMunicipality = "";
      this.filterForm.get("municipio").setValue(null);
      this.selectedProvince = this.states.find(
        (i) => i.id === +this.filterForm.get("provincia").value
      ).name;

      const param: ListCityByStateRQ = {
        stateId: this.filterForm.get("provincia").value,
      };
      this.cityService.apiCityListByStatePost(param).subscribe((res) => {
        this.cities = res.list;
      });
    } else {
      this.selectedProvince = "";
      this.cities = [];
      this.selectedMunicipality = "";
      this.filterForm.get("municipio").setValue(null);
    }
  }

  resetFilters() {
    this.filterService.setFilterObservable = undefined;
    this.ResetFields();
    this.FetchState();
    this.FetchShippings();
    this.filterReset.emit(true);
  }

  FromDate() {
    if (
      this.filterForm.get("desde").value != "" &&
      this.filterForm.get("desde").value != undefined
    ) {
      const day = this.filterForm.get("desde").value.day;
      const month = this.filterForm.get("desde").value.month;
      const year = this.filterForm.get("desde").value.year;
      return new Date(year, month - 1, day);
    } else return null;
  }

  ToDate() {
    if (
      this.filterForm.get("hasta").value != "" &&
      this.filterForm.get("hasta").value != undefined
    ) {
      const day = this.filterForm.get("hasta").value.day;
      const month = this.filterForm.get("hasta").value.month;
      const year = this.filterForm.get("hasta").value.year;
      return new Date(year, month - 1, day);
    } else return null;
  }

  onDateSelect(event) {
    let year = event.year;
    let month = event.month <= 9 ? "0" + event.month : event.month;
    let day = event.day <= 9 ? "0" + event.day : event.day;
    let finalDate = day + "-" + month + "-" + year;
    this.filterForm.get("desde").setValue(finalDate);
  }
  
  NumberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  applyFilters() {
    //const createAt = new Date(this.filterForm.get("desde").value);
    //const date = { year: createAt.getFullYear(), month: createAt.getMonth() + 1, day: createAt.getDate() };

    const filter: FilterSearchModel = {
      desde: this.FromDate(),
      envios: this.selectedShipping, //this.filterForm.get('envios').value,
      tipoEnvio: this.selectedShippingType,
      telefono: this.filterForm.get("telefono").value !== null
          ? this.filterForm.get("telefono").value.toString()
          : "",
      hasta: this.ToDate(),
      municipio: this.selectedMunicipality,
      provincia: this.selectedProvince,
      vuelos: this.selectedFlight, //this.filterForm.get('vuelos').value
      state: this.selectedState
    };

    const filterObj: FilterSearchObj = {
      desde: this.FromDate()?.toDateString(),
      envios: this.filterForm.get("envios").value,
      telefono:
        this.filterForm.get("telefono").value !== null
          ? this.filterForm.get("telefono").value.toString()
          : "",
      hasta: this.ToDate()?.toDateString(),
      municipio: this.filterForm.get("municipio").value,
      provincia: this.filterForm.get("provincia").value,
      vuelos: this.filterForm.get("vuelos").value,
      father: this.father,
      state: this.filterForm.get("states").value,
      tipoEnvio: this.filterForm.get("shippingType").value ? this.filterForm.get("shippingType").value.toString() : null
    };    
 
    this.filterService.setFilterObservable = filterObj;

    this.filterResult.emit(filter);
  }
}
