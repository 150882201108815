import {
  Component,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectorRef,
  NgZone,
} from "@angular/core";
import { MediaObserver } from "@angular/flex-layout";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { CoreConfigService } from "@core/services/config.service";
import { CoreMediaService } from "@core/services/media.service";
import { TranslateService } from "@ngx-translate/core";
import { User } from "app/authentication/models/user.model";
import { AuthService } from "app/authentication/services/auth.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AutoAssigmentSettingService } from "app/open-api/api/autoAssigmentSetting.service";
import {
  SystemConfigurationService,
  UpdateSystemConfigurationRQ,
} from "app/open-api";
import { SignalRService } from "app/open-api/api/SignalR.service";
import * as _ from "lodash";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AutoassignBillDealerModalComponent } from "app/shared/components/autoassign-bill-dealer-modal/autoassign-bill-dealer-modal.component";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit, OnDestroy {
  @ViewChild("content") content: TemplateRef<any>;  
  public horizontalMenu: boolean;
  public hiddenMenu: boolean;
  public coreConfig: any;
  public currentSkin: string;
  public prevSkin: string;
  public currentUser: User;
  public languageOptions: any;
  public navigation: any;
  public selectedLanguage: any;
  public stateSMS: boolean;
  public stateSMSId: number;
  public autoAssigment: boolean = false;
  public lastActivationTimestamp = null;
  public assignUsername = null;

  @HostBinding("class.fixed-top")
  public isFixed = false;

  @HostBinding("class.navbar-static-style-on-scroll")
  public windowScrolled = false;

  // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop > 100) &&
      this.coreConfig.layout.navbar.type == "navbar-static-top" &&
      this.coreConfig.layout.type == "horizontal"
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreMediaService} _coreMediaService
   * @param {MediaObserver} _mediaObserver
   * @param {TranslateService} _translateService
   * @param {SignalRService} _signalRService
   */
  constructor(
    private _router: Router,
    private _authenticationService: AuthService,
    private _coreConfigService: CoreConfigService,
    private _coreMediaService: CoreMediaService,
    private _coreSidebarService: CoreSidebarService,
    private _mediaObserver: MediaObserver,
    public _translateService: TranslateService,
    private _systemConfigurationService: SystemConfigurationService,
    private _spinnerService: NgxSpinnerService,
    private _signalRService: SignalRService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private autoAssigmentService: AutoAssigmentSettingService,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this._authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );

    this.languageOptions = {
      en: {
        title: "English",
        flag: "us",
      },
      fr: {
        title: "French",
        flag: "fr",
      },
      de: {
        title: "German",
        flag: "de",
      },
      pt: {
        title: "Portuguese",
        flag: "pt",
      },
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  /**
   * Set the language
   *
   * @param language
   */
  setLanguage(language): void {
    // Set the selected language for the navbar on change
    this.selectedLanguage = language;

    // Use the selected language id for translations
    this._translateService.use(language);

    this._coreConfigService.setConfig(
      { app: { appLanguage: language } },
      { emitEvent: true }
    );
  }

  /**
   * Toggle Dark Skin
   */
  toggleDarkSkin() {
    // Get the current skin
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.currentSkin = config.layout.skin;
      });

    // Toggle Dark skin with prevSkin skin
    this.prevSkin = localStorage.getItem("prevSkin");

    if (this.currentSkin === "dark") {
      this._coreConfigService.setConfig(
        { layout: { skin: this.prevSkin ? this.prevSkin : "default" } },
        { emitEvent: true }
      );
    } else {
      localStorage.setItem("prevSkin", this.currentSkin);
      this._coreConfigService.setConfig(
        { layout: { skin: "dark" } },
        { emitEvent: true }
      );
    }
  }

  /**
   * Logout method
   */
  logout() {
    this._authenticationService.logout();
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.getStateSMS();
    this._signalRService.autoAssignmentStatusChanged$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response) => {
        this.ngZone.run(() => {
          this.lastActivationTimestamp = response.lastActivationTimestamp
            ? this.formatDate(response.lastActivationTimestamp)
            : "Nunca";
          this.assignUsername = response.user;
        this.autoAssigment = response.autoAssignValue;
          this.changeDetectorRef.detectChanges();
        });
      });
    this.getStateAutoAssigment();
    // get the currentUser details from localStorage
    this.currentUser = this._authenticationService.currentUserValue;
    // Subscribe to the config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
        this.horizontalMenu = config.layout.type === "horizontal";
        this.hiddenMenu = config.layout.menu.hidden === true;
        this.currentSkin = config.layout.skin;

        // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
        if (this.coreConfig.layout.type === "vertical") {
          setTimeout(() => {
            if (this.coreConfig.layout.navbar.type === "fixed-top") {
              this.isFixed = true;
            }
          }, 0);
        }
      });

    // Horizontal Layout Only: Add class fixed-top to navbar below large screen
    if (this.coreConfig.layout.type == "horizontal") {
      // On every media(screen) change
      this._coreMediaService.onMediaUpdate
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
          const isFixedTop = this._mediaObserver.isActive("bs-gt-xl");
          if (isFixedTop) {
            this.isFixed = false;
          } else {
            this.isFixed = true;
          }
        });
    }

    // Set the selected language from default languageOptions
    this.selectedLanguage = _.find(this.languageOptions, {
      id: this._translateService.currentLang,
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getStateAutoAssigment(){    
    this._spinnerService.show();
    this.autoAssigmentService
      .getAssigmentSettingStatus()
      .subscribe((res) => {
        //this.autoAssigment = res.;
        this.lastActivationTimestamp = res.item.lastActivationTimestamp
            ? this.formatDate(res.item?.lastActivationTimestamp)
            : "Nunca";
          this.assignUsername = res.item.user;
        this.autoAssigment = res.item.autoAssignValue;
        this._spinnerService.hide();
      });
  }

  getStateSMS() {
    this._spinnerService.show();
    this._systemConfigurationService
      .apiSystemConfigurationConfigurationGet()
      .subscribe((res) => {
        this.stateSMS = res.sendSMS;
        this.stateSMSId = res.id;
        this._spinnerService.hide();
      });
  }

  openModal() {
    const modalRef = this.modalService.open(AutoassignBillDealerModalComponent, {
      size: "lg",
      backdrop: "static",
      centered: true,
    });

    modalRef.componentInstance.modalClosed
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result) => {        
        if (result?.state === "confirm") {
          this._spinnerService.hide();                  
                  //this.autoAssigment = response?.isActive;
                  this.toastr.success('Asignación automática activada');
                  
                  this.autoAssigment = true;
                  this.assignUsername = result?.assignUsername;
                  this.lastActivationTimestamp =
                  result?.lastActivationTimestamp
                      ? this.formatDate(result?.lastActivationTimestamp)
                      : "Nunca";
        }
        else if (result?.state === "cancel") {
          this.autoAssigment = false;
        }
      });
  }

  onChangeAutoAssigment() {
    console.log(this.autoAssigment)
    if(this.autoAssigment)
    {
      this.openModal();      
    }      
    else {      
      const modalRef = this.modalService.open(this.content, { centered: true });
      modalRef.result.then(
        (result) => {
          if (result === "Aceptar") {
            this._spinnerService.show();
            this.autoAssigmentService
              .updateAutoAssign({ value: false })
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(
                (response) => {
                  this._spinnerService.hide();                   
                  this.autoAssigment = response?.isActive;
                  this.assignUsername = response?.item?.user;
                  this.lastActivationTimestamp =
                  response?.item?.lastActivationTimestamp
                      ? this.formatDate(response?.item?.lastActivationTimestamp)
                      : "Nunca";
                  this.toastr.success('Asignación automática desactivada');
                },
                (error) => {
                  this._spinnerService.hide();
                  console.error(
                    "Error al actualizar la autoasignación:",
                    error
                  );
                }
              );
          }
          else if(result === "Cancelar"){
            this.autoAssigment = true;
          }
        },
        (reason) => {
          // Handle modal dismissed (e.g., by clicking outside the modal)
        }
      );
    }
  }

  formatDate(stringTime: string): string {
    const timestamp = new Date(stringTime);
    const options: any = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
      timeZoneName: "short",
    };
    return timestamp.toLocaleString();
  }

  onChangeState() {
    const model: UpdateSystemConfigurationRQ = {
      id: this.stateSMSId,
      sendSMS: this.stateSMS,
    };
    this._spinnerService.show();
    this._systemConfigurationService
      .apiSystemConfigurationUpdatePost(model)
      .subscribe((res) => {
        this.getStateSMS();
        this._spinnerService.hide();
      });
  }
}
