import { Injectable, Inject, Optional } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';
import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { AutoAssigmentSettingModel, SmsSettingModel } from '../model/getAssigmentSettingStatusRP';

@Injectable()
export class SignalRService {
  private hubConnection!: HubConnection;
  public autoAssignmentStatusChanged$ = new Subject<AutoAssigmentSettingModel>();
  public smsStatusChanged$ = new Subject<SmsSettingModel>();
  private connectionEstablished$ = new Subject<boolean>();
  private notificationReceived$ = new Subject<any>();
  protected basePath = '/';
  public configuration = new Configuration();

  constructor(
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }

    this.initializeConnection();
  }

  /**
   * Initialize SignalR connection
   */
  private initializeConnection(): void {
    const hubUrl = `${this.basePath}/hubs/notification`;

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(hubUrl)
      .configureLogging(LogLevel.Information)
      .build();

    this.hubConnection.onclose(() => {
      console.warn('SignalR connection closed. Attempting to reconnect...');
      this.startConnection();
    });

    this.hubConnection.on('ReceiveNotification', (message: any) => {
      this.notificationReceived$.next(message);
    });

    this.startConnection();
  }

  /**
   * Starts the SignalR connection
   */
  private startConnection(): void {
    this.hubConnection
      .start()
      .then(() => {
        console.log('SignalR connected.');
        this.addAutoAssignmentStatusChangedListener();
        this.addSmsStatusChangedListener();
        this.connectionEstablished$.next(true);
      })
      .catch((err) => {
        console.error('Error starting SignalR connection:', err);
        setTimeout(() => this.startConnection(), 5000); // Retry connection
      });
  }

  private addAutoAssignmentStatusChangedListener() {
    this.hubConnection.on(
      "AutoAssignmentStatusChanged",
      (autoAssignInfo: AutoAssigmentSettingModel) => {
        // Cambia el tipo de parámetro
        this.autoAssignmentStatusChanged$.next(autoAssignInfo);        
      }
    );
  }

  private addSmsStatusChangedListener() {
    this.hubConnection.on(
      "SmsStatusChanged",
      (smsInfo: SmsSettingModel) => {
        // Cambia el tipo de parámetro
        this.smsStatusChanged$.next(smsInfo);        
      }
    );
  }


  /**
   * Exposes an observable for connection status
   */
  public getConnectionStatus(): Observable<boolean> {
    return this.connectionEstablished$.asObservable();
  }

  /**
   * Exposes an observable for received notifications
   */
  public getNotification(): Observable<any> {
    return this.notificationReceived$.asObservable();
  }

  /**
   * Manually stop the SignalR connection
   */
  public stopConnection(): void {
    if (this.hubConnection) {
      this.hubConnection.stop().then(() => {
        console.log('SignalR connection stopped.');
      });
    }
  }
}
