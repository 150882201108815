<!-- <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Configurar a Repartidor </h4>
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" rippleEffect (click)="AcceptModal()"
        [disabled]="assignForm.invalid">Asignar</button>
    <button type="button" class="btn btn-danger" rippleEffect (click)="activeModal.close()">Cancelar</button>
</div> -->
<div class="modal-header">
    <h5>Comisión al repartidor</h5>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
    <form action="#" class="invoice-repeater">
        <div *ngFor="let currencies of currenciesDealer; let i = index">
            <div class="row d-flex align-items-end">
                <div class="col-md-3 col-12">
                    <div class="form-group">
                        <label for="itemName{{ i }}">
                            <b class="text-primary">*</b> Tipo envío:
                        </label>
                        <ng-select formControlName="shippingType" [clearable]="true" aria-describedby="shippingType-input" [items]="shippingTypes" bindLabel="name" bindValue="id" placeholder="Tipos de envío"
                        notFoundText="No hay información">
                       </ng-select>                        
                    </div>
                </div>
                <div class="col-md-3 col-12">
                    <div class="form-group">
                        <label for="itemName{{ i }}">
                            <b class="text-primary">*</b> Moneda:
                        </label>
                        <input type="text" readonly class="form-control" [(ngModel)]="currencies.currencyName"
                            name="itemName{{ i }}" id="itemName{{ i }}" placeholder="Moneda"
                            [ngModelOptions]="{standalone: true}" />
                    </div>
                </div>
                <div class="col-md-3 col-12">
                    <div class="form-group">
                        <label for="itemCost">
                            <b class="text-primary">*</b> Precio x Libra:
                        </label>
                        <input type="number" class="form-control" [(ngModel)]="currencies.price" name="itemCost{{ i }}"
                            id="itemCost{{ i }}" placeholder="32" [ngModelOptions]="{standalone: true}" />
                    </div>
                </div>
                <div class="col-md-3 col-12">
                    <div class="form-group">
                        <div class="custom-control custom-control-primary custom-switch">
                            <label class="pb-1">Principal</label>
                            <br>
                            <input type="checkbox" (change)="changePrincipal(currencies.currencyId)"
                                [checked]="currencies.principal ? 'checked' : ''" class="custom-control-input"
                                name="itemPrincipal{{ i }}" id="itemPrincipal{{i}}" [value]="currencies.principal" />
                            <label class="custom-control-label" for="itemPrincipal{{ i }}"></label>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" rippleEffect (click)="SaveCurrenciesDealer()">Guardar</button>
    <button type="button" class="btn btn-danger" rippleEffect (click)="activeModal.close()">Cancelar</button>
</div>