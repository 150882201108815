/**
 * OskPack.WebApi.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FlightListModel { 
    id?: number;
    shippingId?: number;
    shippingCode?: string;
    shippingDescription?: string;
    code?: string;
    totalCost?: number;
    shippingCreateAt?: Date;
    flightDate?: Date;
    isActive?: boolean;
    shippingType?: number;
    hasBills?: boolean;
}

export interface FlightListModelToExport {     
    CODIGO?: string;
    CODIGO_ENVIO?: string;
    DESCRIPCION_ENVIO?: string;    
    COSTO_TOTAL?: string;
    FECHA_ENVIO?: string;
    FECHA_VUELO?: string;
    ACTIVO?: boolean;
    TIPO_ENVIO?: string;
}