<div class="modal-body" tabindex="0" ngbAutofocus>
<form [formGroup]="filterForm"  id="accountInformationForm">
<div class="col-md-12">
  <div class="row">
    <div class="col-md-4">
      <label for="provincia">Provincia:</label>
      <ng-select #provincias (clear)="resetProvincias();" placeholder="Provincias" [clearable]="true" notFoundText="No hay datos" bindValue="id" [items]="states" bindLabel="name"
      formControlName="provincia" (change)="OnStateChange()">
                                                </ng-select>
      <!--<select #provincias id="provincia" class="form-control" formControlName="provincia" (change)="OnStateChange($event)">
        <option [value]="null">Seleccionar</option>       
        <option *ngFor="let state of states" value="{{state.id}}">{{state.name}}</option>
      </select>-->
    
    </div>
    <div class="col-md-4">
      <label for="municipio">Municipio:</label>
      <ng-select #municipios (clear)="resetMunicipios();" placeholder="Municipios" [clearable]="true" notFoundText="No hay datos" bindValue="id" [items]="cities" bindLabel="name"
      formControlName="municipio" (change)="OnCityChange()">
                                                </ng-select>     

    </div>
    <div class="col-md-4">

    </div>
  </div>  
  <div class="row pt-1">    
    <div class="col-md-6">
      <div class="form-group">
        <label class="form-label" for="fecha-input">
            <b class="text-primary">*</b> Desde: 
        </label>
        <div class="input-group">
          <input class="form-control" (dateSelect)="onDateSelection($event)" 
          
					outsideDays="hidden"
					
					tabindex="-1"
           placeholder="mm/dd/yyyy" name="Fecha del Envio" formControlName="desde"
                ngbDatepicker #basicDPD="ngbDatepicker" aria-describedby="date-input"                
                readonly />
            <div class="input-group-append">
                <button class="btn btn-outline-secondary feather icon-calendar" (click)="basicDPD.toggle()"
                    type="button" rippleEffect></button>
            </div>
           
        </div>        
    </div>   

    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label class="form-label" for="fecha-input">
            <b class="text-primary">*</b> Hasta: 
        </label>
        <div class="input-group">
          <input class="form-control" [minDate]="desde!" 
          placeholder="mm/dd/yyyy" name="Fecha del Envio" formControlName="hasta"
                ngbDatepicker #basicDPH="ngbDatepicker" aria-describedby="date-input"                
                readonly />
            <div class="input-group-append">
                <button class="btn btn-outline-secondary feather icon-calendar" (click)="basicDPH.toggle()"
                    type="button" rippleEffect></button>
          </div>
        </div>
             
    </div>    

    </div>
  </div>
 
  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
      <label class="form-label" for="telefono">
        Teléfono
      </label>
      <input type="number" (keypress)="NumberOnly($event)" id="telefono" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="10" placeholder="555-5555" class="form-control"
         formControlName="telefono" />    </div>
    <div class="col-md-4">

    </div>
    </div>
    <div class="col-md-4">

    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
        <label class="form-label" for="shippingType-input">
          Tipo de envío: 
        </label>
        <ng-select formControlName="shippingType" (clear)="resetTiposEnvio();" [clearable]="true" aria-describedby="shippingType-input" [items]="shippingTypes" (change)="FetchShippingsByType()" bindLabel="name" bindValue="id" placeholder="Tipos de envío"
        notFoundText="No hay información">
       </ng-select>    </div>
    
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label class="form-label" for="states-input">
          Estados: 
        </label>
        <ng-select formControlName="states" (clear)="resetTiposEnvio();" [clearable]="true" aria-describedby="shippingType-input" [items]="catalogStates" (change)="OnCatalogStateChange()" bindLabel="name" bindValue="id" placeholder="Estados"
        notFoundText="No hay información">
       </ng-select>    </div>
    </div>
    <div class="col-md-4">

    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">      
      <label for="envios">Envíos</label>
      <ng-select [items]="shippings" notFoundText="No hay información" (clear)="resetEnvios();" [clearable]="true" (change)="OnShippingChange()" bindLabel="code" bindValue="id" placeholder="Envío"
                formControlName="envios" aria-describedby="shipping-input">
                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                    {{ item.code }} - {{ item. description }}
                </ng-template>
            </ng-select>  
          </div>    
    </div>
    <div class="col-md-6">
      <label for="vuelos">Vuelos</label>
      <ng-select (clear)="resetVuelos();" [clearable]="true" placeholder="Vuelos" notFoundText="No hay vuelos" bindValue="id" [items]="flights" bindLabel="code"
                                                    formControlName="vuelos" (change)="OnFlightChange()">
                                                </ng-select>      
    </div>
    
  </div>
  <div class="row">
    <div class="col-md-4">

    </div>
    <div class="col-md-4">

    </div>
    <div class="col-md-4">

    </div>
  </div>
  <div class="row">
    <div class="col-md-4">

    </div>
    <div class="col-md-4">

    </div>
    <div class="col-md-4">

    </div>
  </div>
  <div class="row pt-2 pb-2">
    <div class="col-md-4">
      <button class="btn btn-outline-primary" type="button" rippleEffect (click)="resetFilters()"      
      >Borrar filtros</button>
    </div>
    <div class="col-md-4">

    </div>
    <div class="col-md-4">
      <button class="btn btn-primary" type="button" rippleEffec (click)="applyFilters()"      
      >Buscar</button>
    </div>
  </div>
</div>
</form></div>