<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel1">Introduzca los parámetros de búsqueda</h4>
  <button type="button" class="close" (click)="activeModal.close()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
  <form [formGroup]="formSearch">
    <div class="row" style="padding-inline: 10px">
      <div class="col-5">
        <div class="form-group">
          <input type="number" min="1" minlength="1"  maxlength="4" class="form-control" aria-describedby="description-input" placeholder="No envío"
            formControlName="noenvio" (keypress)="NumberOnly($event)" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" />
        </div>
        <ngb-alert [type]="'danger'"
                                            *ngIf="fs.noenvio.errors?.min  && (fs.noenvio.touched || fs.noenvio.dirty)"
                                            class="mt-0" [dismissible]="false">
                                            <div class="alert-body">
                                                <span [data-feather]="'info'" [class]="'mr-50 align-middle'"></span>
                                                <span>El valor minimo es 1.</span>
                                            </div>
                                        </ngb-alert>
      </div>
      <div class="col-5"></div>
      <div class="col-2"></div>
    </div>
    <div class="row" style="padding-inline: 10px">
      <div class="col-5">
        <ng-select formControlName="agencias" [searchable]="false" aria-describedby="shippingType-input"
          [items]="agencies" bindLabel="name" bindValue="id" placeholder="Agencias" notFoundText="No hay información">
        </ng-select>
      </div>
      <div class="col-5">
        <ng-select formControlName="servicios" [searchable]="false" aria-describedby="shippingType-input"
          [items]="services" bindLabel="name" bindValue="id" placeholder="Servicios" notFoundText="No hay información">
        </ng-select>
      </div>
      <div class="col-2"></div>
    </div>
  
    <div class="row pt-2" style="padding-inline: 10px">
      <div class="col-5">
        <div class="form-group">
          <div class="input-group">
          <input class="form-control" (dateSelect)="onDateSelection($event)" outsideDays="hidden" tabindex="-1"
            placeholder="mm/dd/yyyy" name="Fecha del Envio" formControlName="fechainicio" ngbDatepicker
            #basicDPD="ngbDatepicker" aria-describedby="date-input" readonly />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary feather icon-calendar" (click)="basicDPD.toggle()" type="button"
              rippleEffect></button>
          </div>
          </div>
        </div>
      </div>
      <div class="col-5">
        <div class="form-group">
          <div class="input-group">
            <input class="form-control" [minDate]="fechainicio!" placeholder="mm/dd/yyyy" name="Fecha del Envio"
              formControlName="fechafin" ngbDatepicker #basicDPH="ngbDatepicker" aria-describedby="date-input" readonly />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary feather icon-calendar" (click)="basicDPH.toggle()" type="button"
                rippleEffect></button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2"></div>
    </div>
    <div class="row pt-3" style="padding-inline: 10px">
      <div class="col-5">
        <button type="button" class="btn btn-outline-primary" rippleEffect [disabled]="formSearch.invalid" (click)="AcceptModal()"
          style="width: 100%;">
          <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span class="ml-25 align-middle">Buscar</span>
        </button>
      </div>
      <div class="col-5"></div>
      <div class="col-2">
  
      </div>
    </div>
  
  </form>
  
</div>
