export const commisionsTypes: CommisionType[] = [
    {
      id: 1,
      name: "Fijo",
    },
    {
      id: 2,
      name: "Por Libras",
    },    
  ]


export interface CommisionType {
    id?: number;
    name?: string;
}
