import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Role } from '../models/role.model';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public currentUser: Observable<User>;
  private currentUserSubject: BehaviorSubject<User>;

  constructor() {
    this.currentUserSubject = new BehaviorSubject<User>(null);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public set currentUserValue(value: User) {
    this.currentUserSubject.next(value);
  }

  get isAdministrator() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Administrador;
  }

  get isAgency() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Agencia;
  }

  public logout() {
    this.currentUserSubject.next(null);
    location.reload();
  }
}
