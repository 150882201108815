export const locale = {
  lang: 'en',
  data: {
    MENU: {
      SHIPPING: 'Envíos',
      FLIGHT: 'Vuelos',
      BILL: {
        COLLAPSIBLE: 'Facturas',
        INTERNAS: 'Paqueteria',
        EXTERNAS: 'Apacargo'
      },
      ASSIGNMENT: {
        COLLAPSIBLE: 'Asignaciones',
        TOFLIGHT: 'A Vuelo',
        TODEALER: 'A Repartidor'
      },
      DEALER: 'Repartidores',
      BALANCE: {
        COLLAPSIBLE: 'Cierres',
        DEALER: 'Repartidores',
        SHIPPING: 'Envíos'
      },
      SCANNER: 'Escanear',
    }
  }
}
