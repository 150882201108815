import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'shipping',
    title: 'Envíos',
    translate: 'MENU.SHIPPING',
    type: 'item',
    icon: 'shopping-cart',
    url: 'pages/shipping'
  },
  {
    id: 'flight',
    title: 'Vuelos',
    translate: 'MENU.FLIGHT',
    type: 'item',
    icon: 'send',
    url: 'pages/flight'
  },
  {
    id: 'bill',
    title: 'Facturas',
    translate: 'MENU.BILL',
    type: 'collapsible',
    icon: 'credit-card',    
    children: [
      {
        id: 'internas',
        title: 'Facturas paquetería',
        translate: 'MENU.BILL.INTERNAS',
        type: 'item',
        icon: 'circle',
        url: 'pages/bill'
      },
      {
        id: 'externas',
        title: 'Órdenes externas',
        translate: 'MENU.BILL.EXTERNAS',
        type: 'item',
        icon: 'circle',
        url: 'pages/orders'
      }
    ]
  },
  {
    id: 'assignment',
    title: 'Asignaciones',
    translate: 'MENU.ASSIGNMENT.COLLAPSIBLE',
    type: 'collapsible',
    icon: 'layers',
    children: [
      {
        id: 'toflight',
        title: 'A Vuelo',
        translate: 'MENU.ASSIGNMENT.TOFLIGHT',
        type: 'item',
        icon: 'circle',
        url: 'pages/assignment/toflight'
      },
      {
        id: 'todealer',
        title: 'A Repartidor',
        translate: 'MENU.ASSIGNMENT.TODEALER',
        type: 'item',
        icon: 'circle',
        url: 'pages/assignment/todealer'
      }
    ]
  },
  {
    id: 'dealer',
    title: 'Repartidores',
    translate: 'MENU.DEALER',
    type: 'item',
    icon: 'users',
    url: 'pages/dealers'
  },
  {
    id: 'balance',
    title: 'Cierres',
    translate: 'MENU.BALANCE.COLLAPSIBLE',
    type: 'collapsible',
    icon: 'archive',
    children: [
      {
        id: 'dealerbalance',
        title: 'Repartidores',
        translate: 'MENU.BALANCE.DEALER',
        type: 'item',
        icon: 'circle',
        url: 'pages/dealer-balance'
      },
      {
        id: 'shippingbalance',
        title: 'Envíos',
        translate: 'MENU.BALANCE.SHIPPING',
        type: 'item',
        icon: 'circle',
        url: 'pages/shipping-balance'
      }
    ]
  },
  {
    id: 'scanner',
    title: 'Escanear',
    translate: 'MENU.SCANNER',
    type: 'item',
    icon: 'grid',
    url: 'pages/scanner'
  },
]
