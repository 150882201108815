/**
 * OskPack.WebApi.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UserMenuModel } from './userMenuModel';

export interface GetAdminRP { 
    id?: number;
    name?: string;
    userName?: string;
    email?: string;
    phone?: string;
    lastActivity?: Date;
    menus?: Array<UserMenuModel>;
}