import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbDatepickerModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { CreateFlightModalComponent } from './components/create-flight-modal/create-flight-modal.component';
import { CreateShippingModalComponent } from './components/create-shipping-modal/create-shipping-modal.component';
import { FlightExpensesModalComponent } from './components/flight-expenses-modal/flight-expenses-modal.component';
import { ShippingExpensesModalComponent } from './components/shipping-expenses-modal/shipping-expenses-modal.component';
import { ShippingFlightModalComponent } from './components/shipping-flight-modal/shipping-flight-modal.component';
import { DealersCommisionsComponent } from './components/dealers-commisions/dealers-commisions.component';
import { DispatchBillsPreviewComponent } from './components/dispatch-bills-preview/dispatch-bills-preview.component';
import { DisableControlDirective } from './directives/disable-control.directive';
import { UploadService } from './services/upload.service';
import { AssignBillFlightModalComponent } from './components/assign-bill-flight-modal/assign-bill-flight-modal.component';
import { EditFlightAssigmentModalComponent } from './components/edit-flight-assigment-modal/edit-flight-assigment-modal.component';
import { AssignBillDealerModalComponent } from './components/assign-bill-dealer-modal/assign-bill-dealer-modal.component';
import { ConfigureCurrencyDealerModalComponent } from './components/configure-currency-dealer-modal/configure-currency-dealer-modal.component';
import { LimitTextPipe } from './pipes/limit-text.pipe';
import { ToastrModule } from 'ngx-toastr';
import { AutoassignBillDealerModalComponent } from './components/autoassign-bill-dealer-modal/autoassign-bill-dealer-modal.component';
import { DealerBalanceDetailComponent } from './components/dealer-balance-detail/dealer-balance-detail.component';
import { ShippingBalanceDetailComponent } from './components/shipping-balance-detail/shipping-balance-detail.component';
import { MultipleFiltersComponent } from './components/multiple-filters/multiple-filters.component';
import { DealerCitiesComponent } from './components/dealer-cities/dealer-cities.component';
import { NgbDateFRParserFormatter } from './directives/NgbDateFRParserFormatter.directive';
import { NgbUTCStringAdapter} from './directives/NgbUTCStringAdapter.directive';
import { SearchShipmentsComponent } from './components/search-shipments/search-shipments.component';
import { ExternalSearchModalComponent} from './components/external-search-modal/external-search-modal.component';
import { LoadExternalOperationsModalComponent } from './components/load-external-operations-modal/load-external-operations-modal.component'
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { CustomPaginator } from './directives/paginator-es';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { NgxMaskModule } from 'ngx-mask';

import {
  NgbDropdownModule,
  NgbModule,
  NgbPopover,  
  NgbDatepicker,  
  NgbDatepickerConfig,
  NgbDateParserFormatter,
  NgbDateAdapter
} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [
    CreateShippingModalComponent,
    ShippingExpensesModalComponent,
    ShippingFlightModalComponent,
    DealersCommisionsComponent,
    DispatchBillsPreviewComponent,
    SearchShipmentsComponent,
    DisableControlDirective,
    CreateFlightModalComponent,
    FlightExpensesModalComponent,
    AssignBillFlightModalComponent,
    EditFlightAssigmentModalComponent,
    AssignBillDealerModalComponent,
    ConfigureCurrencyDealerModalComponent,
    LimitTextPipe,
    DealerBalanceDetailComponent,
    ShippingBalanceDetailComponent,
    MultipleFiltersComponent,
    DealerCitiesComponent,
    AutoassignBillDealerModalComponent,
    ExternalSearchModalComponent,
    LoadExternalOperationsModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbToastModule,
    NgSelectModule,
    NgbDatepickerModule,
    ToastrModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMaskModule.forRoot(),
    NgbModule
  ],
  providers: [
    UploadService, CurrencyPipe,
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateFRParserFormatter
    },
    {
        provide: NgbDateAdapter,
        useClass: NgbUTCStringAdapter
    },
    {
      provide: MatPaginatorIntl,
      useFactory: CustomPaginator
    }
  ],  
  exports: [
    LimitTextPipe,
    MultipleFiltersComponent,
    MatInputModule,
    MatFormFieldModule
  ],
})
export class SharedModule { }
