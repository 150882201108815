import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomBreakPointsProvider } from 'app/layout/custom-breakpoints';
import { HorizontalLayoutModule } from 'app/layout/horizontal/horizontal-layout.module';
import { VerticalLayoutModule } from 'app/layout/vertical/vertical-layout.module';
import { RouterModule } from '@angular/router';
import { BlankComponent } from './blank/blank.component';

@NgModule({
  imports: [
    FlexLayoutModule.withConfig({ disableDefaultBps: true }),
    VerticalLayoutModule,
    HorizontalLayoutModule,
    RouterModule],
  providers: [CustomBreakPointsProvider],
  exports: [VerticalLayoutModule, HorizontalLayoutModule],
  declarations: [
    BlankComponent
  ]
})
export class LayoutModule { }
