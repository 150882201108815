/**
 * OskPack.WebApi.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpErrorResponse  }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable, throwError }                                        from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ProcessScanedOrders, ChangeBillStateRP, ChangeBillStateRQ, ScannedBillsPreview, ScannedBill } from  '../model/processScanedOrders';   
import { AddBillRQ } from '../model/addBillRQ';
import { CheckStateRP } from '../model/checkStateRP';
import { CheckStateRQ } from '../model/checkStateRQ';
import { DeleteBillRQ, DeleteBillBatchRQ } from '../model/deleteBillRQ';
import { DeleteSMSNotificationRQ } from '../model/deleteSMSNotificationRQ';
import { GetBillRP } from '../model/getBillRP';
import { GetBillRQ } from '../model/getBillRQ';
import { ListBillsByFlightRQ } from '../model/listBillsByFlightRQ'
import { ListBillByAgencyRP } from '../model/listBillByAgencyRP';
import { ListBillByAgencyRQ } from '../model/listBillByAgencyRQ';
import { BillSummaryShipmentRQ, BillSummaryShipmentRP } from '../model/billSummaryShipmentRQ';
import { ListBillRP } from '../model/listBillRP';
import { ListFlightBillByAgencyRP } from '../model/listFlightBillByAgencyRP';
import { ListFlightBillByAgencyRQ } from '../model/listFlightBillByAgencyRQ';
import { ListOpenBillByAgencyRP } from '../model/listOpenBillByAgencyRP';
import { ListOpenBillByAgencyRQ } from '../model/listOpenBillByAgencyRQ';
import { Unit } from '../model/unit';
import { LoadExternalShimpmentRP, LoadExternalShimpmentRQ, LoadExternalOrdersRP, LoadExternalOperation} from 'app/open-api/model/LoadExternalOrdersRP';
import { LoadExternalOrdersRQ } from 'app/open-api/model/LoadExternalOrdersRQ';
import { DownloadPdfRQ, DownloadPdfCodeRQ } from 'app/open-api/model/downloadPdfRQ';
import { UpdateBillRQ } from '../model/updateBillRQ';
import { CSVBillRQ } from '../model/csvBillRQ';
import { TemporaryBillRQ } from "app/open-api/model/temporaryBillRQ";
import { LoadExternalOperationDto, GetExternalOperationsRQ } from "app/open-api/model/loadExternalOperationDto";

import { ProccessTemporaryBills, ProccessSingleTemporaryBill } from "app/open-api/model/proccessTemporaryBills";
import { BillOperationRP, GetBillOperationsRP, ProccessSingleTemporaryBillRP } from "app/open-api/model/billOperation"
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { Console } from 'console';
import { throttleable } from '@swimlane/ngx-datatable';


@Injectable()
export class BillService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiBillAddPost(body?: AddBillRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiBillAddPost(body?: AddBillRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiBillAddPost(body?: AddBillRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiBillAddPost(body?: AddBillRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/Bill/Add`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiBillCheckStatePost(body?: CheckStateRQ, observe?: 'body', reportProgress?: boolean): Observable<CheckStateRP>;
    public apiBillCheckStatePost(body?: CheckStateRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CheckStateRP>>;
    public apiBillCheckStatePost(body?: CheckStateRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CheckStateRP>>;
    public apiBillCheckStatePost(body?: CheckStateRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CheckStateRP>('post',`${this.basePath}/api/Bill/CheckState`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
public processScanedOrdersPost(body?: ChangeBillStateRQ, observe?: 'body', reportProgress?: boolean): Observable<ChangeBillStateRP>;
public processScanedOrdersPost(body?: ChangeBillStateRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChangeBillStateRP>>;
public processScanedOrdersPost(body?: ChangeBillStateRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChangeBillStateRP>>;
public processScanedOrdersPost(body?: ChangeBillStateRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
        'text/plain',
        'application/json',
        'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
        'application/json',
        'text/json',
        'application/_*+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ChangeBillStateRP>('post',`${this.basePath}/api/Bill/ChangeStatus`,
        {
            body: body,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        }
    );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDownloadPdfPost(body?: DownloadPdfRQ, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiDownloadPdfPost(body?: DownloadPdfRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiDownloadPdfPost(body?: DownloadPdfRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiDownloadPdfPost(body?: DownloadPdfRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'application/pdf',
            "application/octet-stream",
            'text/json',
            'blob'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'blob'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        var mediaType = 'application/pdf';

        const url = `${this.basePath}/api/Bill/DownloadPdfBill?billId=${body.billId}`;
        return this.httpClient
            .get(url, { responseType: 'blob' })            
            .pipe(map(res => {                
                var blob = new Blob([res], { type: mediaType })
                /*const filePath = window.URL.createObjectURL(blob)
                const downloadLink = document.createElement('a')
                downloadLink.href = filePath
                downloadLink.setAttribute('download', `${body.billId}.pdf`)
                document.body.appendChild(downloadLink)
                downloadLink.click()*/
                return blob;
            //return new Blob([res], { type: mediaType });
        }),catchError((err) => {   
           // console.log('error', err)         
            err.error = "No se encuetra el pdf de la factura."
            //console.log('error1', err)
          return throwError(err)
       }))
       
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDownloadPdfCodePost(body?: DownloadPdfCodeRQ, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiDownloadPdfCodePost(body?: DownloadPdfCodeRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiDownloadPdfCodePost(body?: DownloadPdfCodeRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiDownloadPdfCodePost(body?: DownloadPdfCodeRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'application/pdf',
            "application/octet-stream",
            'text/json',
            'blob'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'blob'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        var mediaType = 'application/pdf';

        const url = `${this.basePath}/api/Bill/DownloadPdfBillCode?billCode=${body.billCode}`;
        return this.httpClient
            .get(url, { responseType: 'blob' })            
            .pipe(map(res => {                
                var blob = new Blob([res], { type: mediaType })
                /*const filePath = window.URL.createObjectURL(blob)
                const downloadLink = document.createElement('a')
                downloadLink.href = filePath
                downloadLink.setAttribute('download', `${body.billId}.pdf`)
                document.body.appendChild(downloadLink)
                downloadLink.click()*/
                return blob;
            //return new Blob([res], { type: mediaType });
        }),catchError((err) => {   
           // console.log('error', err)         
            err.error = "No se encuetra el pdf de la factura."
            //console.log('error1', err)
          return throwError(err)
       }))
       
    }

    catchErrorFromBackend(error) : Observable<Response>{
        if (error.status === 404) {
             error.error = "No se encuetra el pdf de la factura"
        }

        console.log('error', error)
        return throwError(error)
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDownloadPdfGet(param?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiDownloadPdfGet(param?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiDownloadPdfGet(param?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiDownloadPdfGet(param?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'application/pdf',
            "application/octet-stream",
            'text/json',
            'blob'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'blob'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        var mediaType = 'application/pdf';

        const url = `${this.basePath}/api/Bill/DownloadPdf?billId=${param}`;

        return this.httpClient
        .get(url, { responseType: 'blob' })
        .pipe(map(res => {
            //console.log(res);
            var blob = new Blob([res], { type: mediaType })
            /*const filePath = window.URL.createObjectURL(blob)
            const downloadLink = document.createElement('a')
            downloadLink.href = filePath
            downloadLink.setAttribute('download', `${param}.pdf`)
            document.body.appendChild(downloadLink)
            downloadLink.click()*/
            return blob;
        }),catchError((err) => {   
            // console.log('error', err)         
             err.error = "No se encuentra el pdf de la factura."
             //console.log('error1', err)
           return throwError(err)
        }));        
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiBillDeletePost(body?: DeleteBillRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiBillDeletePost(body?: DeleteBillRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiBillDeletePost(body?: DeleteBillRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiBillDeletePost(body?: DeleteBillRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/Bill/Delete`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiBillBatchDeletePost(body?: DeleteBillBatchRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiBillBatchDeletePost(body?: DeleteBillBatchRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiBillBatchDeletePost(body?: DeleteBillBatchRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiBillBatchDeletePost(body?: DeleteBillBatchRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/Bill/DeleteBatchBills`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiBillDeleteSMSNotificationPost(body?: DeleteSMSNotificationRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiBillDeleteSMSNotificationPost(body?: DeleteSMSNotificationRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiBillDeleteSMSNotificationPost(body?: DeleteSMSNotificationRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiBillDeleteSMSNotificationPost(body?: DeleteSMSNotificationRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/Bill/DeleteSMSNotification`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiBillGetBillPost(body?: GetBillRQ, observe?: 'body', reportProgress?: boolean): Observable<GetBillRP>;
    public apiBillGetBillPost(body?: GetBillRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetBillRP>>;
    public apiBillGetBillPost(body?: GetBillRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetBillRP>>;
    public apiBillGetBillPost(body?: GetBillRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GetBillRP>('post',`${this.basePath}/api/Bill/GetBill`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiBillGetFlightBillByAgencyPost(body?: ListFlightBillByAgencyRQ, observe?: 'body', reportProgress?: boolean): Observable<ListFlightBillByAgencyRP>;
    public apiBillGetFlightBillByAgencyPost(body?: ListFlightBillByAgencyRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListFlightBillByAgencyRP>>;
    public apiBillGetFlightBillByAgencyPost(body?: ListFlightBillByAgencyRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListFlightBillByAgencyRP>>;
    public apiBillGetFlightBillByAgencyPost(body?: ListFlightBillByAgencyRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ListFlightBillByAgencyRP>('post',`${this.basePath}/api/Bill/GetFlightBillByAgency`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    //cargar envios
    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiGetExternalShipmentsPost(body?: LoadExternalShimpmentRQ, observe?: 'body', reportProgress?: boolean): Observable<LoadExternalShimpmentRP>;
    public apiGetExternalShipmentsPost(body?: LoadExternalShimpmentRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LoadExternalShimpmentRP>>;
    public apiGetExternalShipmentsPost(body?: LoadExternalShimpmentRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LoadExternalShimpmentRP>>;
    public apiGetExternalShipmentsPost(body?: LoadExternalShimpmentRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<LoadExternalShimpmentRP>('post',`${this.basePath}/api/Bill/Shipments`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    
    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiGetExternOrdersPost(body?: LoadExternalOrdersRQ, observe?: 'body', reportProgress?: boolean): Observable<LoadExternalOrdersRP>;
    public apiGetExternOrdersPost(body?: LoadExternalOrdersRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LoadExternalOrdersRP>>;
    public apiGetExternOrdersPost(body?: LoadExternalOrdersRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LoadExternalOrdersRP>>;
    public apiGetExternOrdersPost(body?: LoadExternalOrdersRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<LoadExternalOrdersRP>('post',`${this.basePath}/api/Bill/Orders`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    //process orders
    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProccessExternOrdersPost(body?: any, observe?: 'body', reportProgress?: boolean): Observable<LoadExternalOperation>;
    public apiProccessExternOrdersPost(body?: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LoadExternalOperation>>;
    public apiProccessExternOrdersPost(body?: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LoadExternalOperation>>;
    public apiProccessExternOrdersPost(body?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<LoadExternalOperation>('post',`${this.basePath}/api/Bill/ProccessExternOrders`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiBillGetOpenBillByAgencyPost(body?: ListOpenBillByAgencyRQ, observe?: 'body', reportProgress?: boolean): Observable<ListOpenBillByAgencyRP>;
    public apiBillGetOpenBillByAgencyPost(body?: ListOpenBillByAgencyRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListOpenBillByAgencyRP>>;
    public apiBillGetOpenBillByAgencyPost(body?: ListOpenBillByAgencyRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListOpenBillByAgencyRP>>;
    public apiBillGetOpenBillByAgencyPost(body?: ListOpenBillByAgencyRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ListOpenBillByAgencyRP>('post',`${this.basePath}/api/Bill/GetOpenBillByAgency`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTemporaryBillByFilterPost(body?: TemporaryBillRQ, observe?: 'body', reportProgress?: boolean): Observable<Array<CSVBillRQ>>;
    public getTemporaryBillByFilterPost(body?: TemporaryBillRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CSVBillRQ>>>;
    public getTemporaryBillByFilterPost(body?: TemporaryBillRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CSVBillRQ>>>;
    public getTemporaryBillByFilterPost(body?: TemporaryBillRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<CSVBillRQ>>('post',`${this.basePath}/api/Bill/GetTemporaryBills`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public proccessSingleTemporaryBill(body?: ProccessSingleTemporaryBill, observe?: 'body', reportProgress?: boolean): Observable<ProccessSingleTemporaryBillRP>;
    public proccessSingleTemporaryBill(body?: ProccessSingleTemporaryBill, observe?: 'response', reportProgress?: boolean): Observable<ProccessSingleTemporaryBillRP>;
    public proccessSingleTemporaryBill(body?: ProccessSingleTemporaryBill, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProccessSingleTemporaryBillRP>>;
    public proccessSingleTemporaryBill(body?: ProccessSingleTemporaryBill, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BillOperationRP>('post',`${this.basePath}/api/Bill/ProccessSingleTemporaryBill`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public proccessTemporaryBill(body?: ProccessTemporaryBills, observe?: 'body', reportProgress?: boolean): Observable<BillOperationRP>;
    public proccessTemporaryBill(body?: ProccessTemporaryBills, observe?: 'response', reportProgress?: boolean): Observable<BillOperationRP>;
    public proccessTemporaryBill(body?: ProccessTemporaryBills, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BillOperationRP>>;
    public proccessTemporaryBill(body?: ProccessTemporaryBills, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BillOperationRP>('post',`${this.basePath}/api/Bill/ProccessTemporaryBills`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    //get external operations
    public getExternalOperations(body?: GetExternalOperationsRQ, observe?: 'body', reportProgress?: boolean): Observable<Array<LoadExternalOperationDto>>;
    public getExternalOperations(body?: GetExternalOperationsRQ, observe?: 'response', reportProgress?: boolean): Observable<Array<LoadExternalOperationDto>>;
    public getExternalOperations(body?: GetExternalOperationsRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LoadExternalOperationDto>>>;
    public getExternalOperations(body?: GetExternalOperationsRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<LoadExternalOperationDto>>('post',`${this.basePath}/api/Bill/GetExternalOrders`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    //aqui
    public getTemporaryOperations(body?: GetBillOperationsRP, observe?: 'body', reportProgress?: boolean): Observable<Array<BillOperationRP>>;
    public getTemporaryOperations(body?: GetBillOperationsRP, observe?: 'response', reportProgress?: boolean): Observable<Array<BillOperationRP>>;
    public getTemporaryOperations(body?: GetBillOperationsRP, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BillOperationRP>>>;
    public getTemporaryOperations(body?: GetBillOperationsRP, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<BillOperationRP>>('post',`${this.basePath}/api/Bill/GetTemporaryOperations`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiBillSummaryByShipmentPost(body?: BillSummaryShipmentRQ, observe?: 'body', reportProgress?: boolean): Observable<BillSummaryShipmentRP>;
    public apiBillSummaryByShipmentPost(body?: BillSummaryShipmentRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BillSummaryShipmentRP>>;
    public apiBillSummaryByShipmentPost(body?: BillSummaryShipmentRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BillSummaryShipmentRP>>;
    public apiBillSummaryByShipmentPost(body?: BillSummaryShipmentRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BillSummaryShipmentRP>('post',`${this.basePath}/api/Bill/BillSummaryByShipment`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiBillListByAgencyPost(body?: ListBillByAgencyRQ, observe?: 'body', reportProgress?: boolean): Observable<ListBillByAgencyRP>;
    public apiBillListByAgencyPost(body?: ListBillByAgencyRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListBillByAgencyRP>>;
    public apiBillListByAgencyPost(body?: ListBillByAgencyRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListBillByAgencyRP>>;
    public apiBillListByAgencyPost(body?: ListBillByAgencyRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ListBillByAgencyRP>('post',`${this.basePath}/api/Bill/ListByAgency`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiBillListGet(observe?: 'body', reportProgress?: boolean): Observable<ListBillRP>;
    public apiBillListGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListBillRP>>;
    public apiBillListGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListBillRP>>;
    public apiBillListGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ListBillRP>('get',`${this.basePath}/api/Bill/List`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiBillListByFlight(body?: ListBillsByFlightRQ, observe?: 'body', reportProgress?: boolean): Observable<ListBillRP>;
    public apiBillListByFlight(body?: ListBillsByFlightRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListBillRP>>;
    public apiBillListByFlight(body?: ListBillsByFlightRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListBillRP>>;
    public apiBillListByFlight(body?: ListBillsByFlightRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {   
       
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CheckStateRP>('post',`${this.basePath}/api/Bill/ListByFlight`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );        
    }

    

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiBillUpdatePost(body?: UpdateBillRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiBillUpdatePost(body?: UpdateBillRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiBillUpdatePost(body?: UpdateBillRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiBillUpdatePost(body?: UpdateBillRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/Bill/Update`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiBillUploadPostForm(file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiBillUploadPostForm(file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiBillUploadPostForm(file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiBillUploadPostForm(file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Bill/Upload`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
