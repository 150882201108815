/**
 * OskPack.WebApi.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddUserRQ } from '../model/addUserRQ';
import { DeleteUserRQ } from '../model/deleteUserRQ';
import { GetAdminRP } from '../model/getAdminRP';
import { GetAdminRQ } from '../model/getAdminRQ';
import { GetAgencyRP } from '../model/getAgencyRP';
import { GetAgencyRQ } from '../model/getAgencyRQ';
import { GetDealerRP } from '../model/getDealerRP';
import { GetDealerRQ } from '../model/getDealerRQ';
import { LoginRP } from '../model/loginRP';
import { LoginRQ } from '../model/loginRQ';
import { Unit } from '../model/unit';
import { UpdateUserRQ } from '../model/updateUserRQ';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUserAddPost(body?: AddUserRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiUserAddPost(body?: AddUserRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiUserAddPost(body?: AddUserRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiUserAddPost(body?: AddUserRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/User/Add`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUserDeletePost(body?: DeleteUserRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiUserDeletePost(body?: DeleteUserRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiUserDeletePost(body?: DeleteUserRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiUserDeletePost(body?: DeleteUserRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/User/Delete`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUserGetAdminPost(body?: GetAdminRQ, observe?: 'body', reportProgress?: boolean): Observable<GetAdminRP>;
    public apiUserGetAdminPost(body?: GetAdminRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetAdminRP>>;
    public apiUserGetAdminPost(body?: GetAdminRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetAdminRP>>;
    public apiUserGetAdminPost(body?: GetAdminRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GetAdminRP>('post',`${this.basePath}/api/User/GetAdmin`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUserGetAgencyPost(body?: GetAgencyRQ, observe?: 'body', reportProgress?: boolean): Observable<GetAgencyRP>;
    public apiUserGetAgencyPost(body?: GetAgencyRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetAgencyRP>>;
    public apiUserGetAgencyPost(body?: GetAgencyRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetAgencyRP>>;
    public apiUserGetAgencyPost(body?: GetAgencyRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GetAgencyRP>('post',`${this.basePath}/api/User/GetAgency`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUserGetDealerPost(body?: GetDealerRQ, observe?: 'body', reportProgress?: boolean): Observable<GetDealerRP>;
    public apiUserGetDealerPost(body?: GetDealerRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetDealerRP>>;
    public apiUserGetDealerPost(body?: GetDealerRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetDealerRP>>;
    public apiUserGetDealerPost(body?: GetDealerRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GetDealerRP>('post',`${this.basePath}/api/User/GetDealer`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUserLoginPost(body?: LoginRQ, observe?: 'body', reportProgress?: boolean): Observable<LoginRP>;
    public apiUserLoginPost(body?: LoginRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LoginRP>>;
    public apiUserLoginPost(body?: LoginRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LoginRP>>;
    public apiUserLoginPost(body?: LoginRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<LoginRP>('post',`${this.basePath}/api/User/Login`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUserUpdatePost(body?: UpdateUserRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiUserUpdatePost(body?: UpdateUserRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiUserUpdatePost(body?: UpdateUserRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiUserUpdatePost(body?: UpdateUserRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/User/Update`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
