import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit, TestabilityRegistry } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddFlightRQ, DeleteFlightRQ, FlightListModel, FlightService, ListFlightByShippingRQ, ShippingListModel, UpdateFlightRQ } from 'app/open-api';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-shipping-flight-modal',
  templateUrl: './shipping-flight-modal.component.html',
  styleUrls: ['./shipping-flight-modal.component.scss']
})
export class ShippingFlightModalComponent implements OnInit {

  @Input() shipping: ShippingListModel;
  flights: FlightListModel[] = [];
  flightSelected: UpdateFlightRQ;
  flightForms: FormGroup;
  date = new Date();
  today = { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() };

  constructor(
    public activeModal: NgbActiveModal,
    private flightService: FlightService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.flightForms = this.initFormBuilder();
    this.FetchModels();
  }

  initFormBuilder() {
    return this.formBuilder.group({
      code: [null, Validators.required],
      flightDate: [this.today, Validators.required]
    });
  }

  FetchModels() {
    const param: ListFlightByShippingRQ = {
      shippingId: this.shipping.id
    };
    this.flightService.apiFlightListByShippingPost(param).subscribe(response => {
      this.flights = response.list;
    });
  }

  get f() {
    return this.flightForms.controls;
  }

  AcceptModal() {
    this.spinner.show();
    const day = this.f.flightDate.value.day;
    const month = this.f.flightDate.value.month;
    const year = this.f.flightDate.value.year;
    const date = new Date(year, month - 1, day);

    if (this.flightSelected) {
      const model: UpdateFlightRQ = {
        id: this.flightSelected.id,
        code: this.f.code.value,
        flightDate: date,
        shippingId: this.shipping.id,
      };
      this.flightService.apiFlightUpdatePost(model).subscribe(response => {
        Swal.fire({
          title: 'Vuelo Actualizado.',
          icon: 'success',
          showConfirmButton: false,
          timer: 2000,
          position: 'top-end'
        });
        this.FetchModels();
        this.flightForms.reset();
        this.flightForms.get('flightDate').setValue(this.today);
        this.flightForms.get('flightDate').updateValueAndValidity();
        this.spinner.hide();
      }, error => {
        Swal.fire({
          title: 'Error al actualizar vuelo. ' + error,
          icon: 'error',
          showConfirmButton: false,
          timer: 2000,
          position: 'top-end',
          customClass: {
            confirmButton: 'btn btn-primary',
          }
        });
        this.spinner.hide();
      });
    } else {
      const model: AddFlightRQ = {
        code: this.f.code.value,
        flightDate: date,
        shippingId: this.shipping.id
      };
      this.flightService.apiFlightAddPost(model).subscribe(response => {
        Swal.fire({
          title: 'Vuelo Creado.',
          icon: 'success',
          showConfirmButton: false,
          timer: 2000,
          position: 'top-end'
        });
        this.FetchModels();
        this.flightForms.reset();
        this.flightForms.get('flightDate').setValue(this.today);
        this.flightForms.get('flightDate').updateValueAndValidity();
        this.spinner.hide();
      }, error => {
        Swal.fire({
          title: 'Error al crear vuelo. ' + error,
          icon: 'error',
          showConfirmButton: false,
          timer: 2000,
          position: 'top-end',
          customClass: {
            confirmButton: 'btn btn-primary',
          }
        });
        this.spinner.hide();
      });
    }
    this.flightSelected = null;
  }

  Delete(flightId: number) {
    const swal = Swal.fire({
      title: '¿Está seguro de desea eliminar el vuelo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-primary'
      }
    }).then(result => {
      if (result.isConfirmed) {
        this.spinner.show();
        const param: DeleteFlightRQ = {
          id: flightId
        };
        this.flightService.apiFlightDeletePost(param).subscribe(response => {
          Swal.fire({
            title: 'Vuelo Eliminado',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            position: 'top-end'
          });
          this.FetchModels();
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
          Swal.fire({
            title: 'Error al eliminar el vuelo. ' + error,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            position: 'top-end',
            customClass: {
              confirmButton: 'btn btn-primary',
            }
          });
        });
      }
    });
  }

  Edit(flight: FlightListModel) {
    let temp: UpdateFlightRQ = {
      id: flight.id,
      shippingId: flight.shippingId,
      code: flight.code,
      flightDate: flight.flightDate
    }
    this.flightSelected = temp;
    const flightDate = new Date(flight.flightDate);
    const date = {
      year: flightDate.getFullYear(),
      month: flightDate.getMonth() + 1,
      day: flightDate.getDate()
    };
    this.flightForms.controls['code'].setValue(flight.code);
    this.flightForms.controls['flightDate'].setValue(date);
    this.flightForms.updateValueAndValidity();
  }
}
