<div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">{{flight ? 'Editar' : 'Crear'}} Vuelo </h4>
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
    <form [formGroup]="flightForms">
        <div class="form-group">
            <label class="form-label" for="shipping-type">
                <b class="text-primary">*</b> Tipo Envio:
            </label>
            <ng-select formControlName="shippingType" id="shipping-type" [items]="shippingTypes" bindLabel="name" bindValue="id" placeholder="Tipos de envío" (change)="FetchShippingsByType()"
                notFoundText="No hay informacion">
            </ng-select>
        </div>
        <div class="form-group">
            <label class="form-label" for="shipping-input">
                <b class="text-primary">*</b> Envío: 
            </label>
            <ng-select [items]="shippings" bindLabel="code" bindValue="id" placeholder="Envío"
                formControlName="shippingId" aria-describedby="shipping-input">
                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                    {{ item.code }} - {{ item. description }}
                </ng-template>
            </ng-select>
            <div *ngIf="f.shippingId.errors && (f.shippingId.touched || f.shippingId.dirty)" class="invalid-feedback">
                <div *ngIf="f.shippingId.errors.required">El envío es requerido.</div>
            </div>
        </div>
        <div class="form-group">
            <label class="form-label" for="code-input">
                <b class="text-primary">*</b> Código: 
            </label>
            <input type="text" class="form-control" formControlName="code" aria-describedby="code-input" autofocus=""
                tabindex="1" [ngClass]="{ 'is-invalid': f.code.errors && (f.code.touched || f.code.dirty)}" />
            <div *ngIf="f.code.errors && (f.code.touched || f.code.dirty)" class="invalid-feedback">
                <div *ngIf="f.code.errors.required">El código es requerido.</div>
            </div>
        </div>
        <div class="form-group">
            <label class="form-label" for="date-input">
                <b class="text-primary">*</b> Fecha del Vuelo: 
            </label>
            <div class="input-group">
                <input class="form-control" placeholder="yyyy-mm-dd" name="Fecha del Vuelo" formControlName="flightDate"
                    ngbDatepicker #basicDP="ngbDatepicker" aria-describedby="date-input"
                    [ngClass]="{ 'is-invalid': f.flightDate.errors }" readonly />
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary feather icon-calendar" (click)="basicDP.toggle()"
                        type="button" rippleEffect></button>
                </div>
            </div>
            <div *ngIf="f.flightDate.errors && (f.flightDate.touched || f.flightDate.dirty)" class="invalid-feedback">
                <div *ngIf="f.flightDate.errors.required">La fecha es requerida.</div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="AcceptModal()" rippleEffect [disabled]="flightForms.invalid">
        {{flight ? 'Editar' : 'Crear'}}
    </button>
</div>