import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if ([401, 403].indexOf(err.status) !== -1) {
          this._router.navigate(['/auth/not-authorized']);
          // ? Can also logout and reload if needed
          // this._authenticationService.logout();
          // location.reload(true);
        }else if(err.status = '404')
         console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
        // throwError
        const error = err.error || err.statusText;
        return throwError(error);
      })
    );
  }
}
