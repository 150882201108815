import { ShippingService } from './../../../open-api/api/shipping.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FlightListModel, ShippingListModel } from 'app/open-api';
import { ShippingListRQ } from '../../../open-api/model/ShippingListRQ';
import { ShippingType, shippingTypes } from '../../../open-api/model/shippingType';

@Component({
  selector: 'app-create-flight-modal',
  templateUrl: './create-flight-modal.component.html',
  styleUrls: ['./create-flight-modal.component.scss']
})
export class CreateFlightModalComponent implements OnInit {

  @Input() flight?: FlightListModel;
  shippings: ShippingListModel[] = [];
  flightForms: FormGroup;
  date = new Date();
  today = { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() };
  selectedShippingType: any;
  shippingTypes: Array<ShippingType> = [];

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private shippingService: ShippingService
  ) { 
    this.shippingTypes = shippingTypes;
  }

  ngOnInit(): void {
    this.FetchModels();    
    if (this.flight) {
      this.date = new Date(this.flight.flightDate);
      this.today = { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() };
    }
    this.flightForms = this.formBuilder.group({
      shippingId: [this.flight ? this.flight.shippingId : null, Validators.required],      
      shippingType: [this.flight ? this.flight.shippingType : null, Validators.required],
      code: [this.flight ? this.flight.code : null, Validators.required],
      flightDate: [this.today, Validators.required]
    });
  }

  FetchModels() {
    const request: ShippingListRQ = {      
    }

    this.shippingService.apiShippingListGet(request).subscribe(response => {
      this.shippings = response.list;
    });
  }

  FetchShippingsByType() {    
    this.shippings = [];
    //this.flights = [];
    const request: ShippingListRQ = {  
      shippingType: this.f.shippingType.value
    }

    this.shippingService.apiShippingListGet(request).subscribe(response => {
      this.shippings = response.list;
      this.f.shippingId.setValue(null);
    });
  }

  get f() {
    return this.flightForms.controls;
  }

  AcceptModal() {
    const day = this.f.flightDate.value.day;
    const month = this.f.flightDate.value.month;
    const year = this.f.flightDate.value.year;
    const date = new Date(year, month - 1, day);
    this.f.flightDate.setValue(date);
    if (this.flight) {
      this.flight.shippingId = this.f.shippingId.value;
      this.flight.code = this.f.code.value;
      this.flight.flightDate = date;
      this.activeModal.close(this.flight);
    }
    this.activeModal.close(this.flightForms.value);
  }
}
