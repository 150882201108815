import { Component, OnInit, Input, EventEmitter, Output, ViewChild, AfterViewInit, AfterViewChecked } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DealerModel, DealersModelDetails } from 'app/open-api';
import { DealerInWhiteList } from 'app/open-api/model/getWhiteDealerListRP';
import { AutoAssigmentSettingService } from 'app/open-api/api/autoAssigmentSetting.service';
import { DealerService } from 'app/open-api';
import { UpdateAssigmentSettingRQ, AutoAssignModel } from 'app/open-api/model/updateAssigmentSettingRP';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-autoassign-bill-dealer-modal',
  templateUrl: './autoassign-bill-dealer-modal.component.html',
  styleUrls: ['./autoassign-bill-dealer-modal.component.scss']
})
export class AutoassignBillDealerModalComponent implements OnInit {
  @Output() modalClosed = new EventEmitter<{ state: string, assignUsername: string, lastActivationTimestamp: string }>();
  settingsForm: FormGroup;
  //dealers: DealerInWhiteList[] = [];  
  dealers: DealersModelDetails[] = [];
  @Input() whitelistedDealers: DealerInWhiteList[];  
  
  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,  
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private autoAssigmentService: AutoAssigmentSettingService,
    private dealerService: DealerService,
  ) { 
    this.settingsForm = this.fb.group({
      assignmentTime: ['', [Validators.required, Validators.min(1)]],      
      whiteListDealer: [[]],      
    }, {});
  }

  async ngOnInit(): Promise<void> {
    this.spinner.show();
    await this.GetWhiteListDealers();
    await this.FetchDealers();
    await this.GetAssigmentTime();
    this.spinner.hide();
  }

  closeModal() {
    this.modalClosed.emit({ state: 'cancel', assignUsername: null, lastActivationTimestamp: null });
    this.activeModal.close();
  }

  async FetchDealers(): Promise<void> {     
  
      await this.dealerService
        .apiDealerDetailsListGet()
        .toPromise()
        .then((response) => {          
          this.dealers = response.list;
        }),
        (error) => {
          Swal.fire({
            title: "Error!. " + error,
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
            position: "top-end",
          });
          this.spinner.hide();
        };
    }

  async GetWhiteListDealers(): Promise<void> {
    this.autoAssigmentService.getWhiteDealerList().toPromise()
    .then((whitelistedDealers) => {         
      this.whitelistedDealers = whitelistedDealers?.dealers;
      // Inicializa el control whiteListDealer con los repartidores permitidos
      this.settingsForm.get('whiteListDealer').setValue(
        this.whitelistedDealers.map((dealer) => dealer.id)
      );
    });
  }

  SaveSettings(){
    if (this.settingsForm.valid) {
      this.spinner.show();
      const formValues = this.settingsForm.value;
      const settings: AutoAssignModel[] = [
        {
          key: 'AutoAssign',
          value: 'true',
        },
        {
          key: 'TimeToAssign',
          value: String(formValues.assignmentTime),
        },       
        {
          key: 'WhiteListDealers',
          value: Array.isArray(formValues.whiteListDealer) ? formValues.whiteListDealer.join() : formValues.whiteListDealer,
        },        
      ];

      const resquest: UpdateAssigmentSettingRQ = {
        items: settings
      }

    this.autoAssigmentService
              .updateAutoAssignAll(resquest)              
              .subscribe(
                (response) => {
                  this.spinner.hide(); 
                             
                  this.modalClosed.emit({ state: 'confirm', assignUsername: response?.item?.user, lastActivationTimestamp: response?.item?.lastActivationTimestamp });
                  this.activeModal.close();                  
                },
                (error) => {
                  this.spinner.hide();
                  console.error(
                    "Error al actualizar la autoasignación:",
                    error
                  );
                }
              );
          }
  }

 async GetAssigmentTime(): Promise<void> {
    this.autoAssigmentService.getAssigmentTime().toPromise()
    .then((response) => { 
        this.settingsForm.get('assignmentTime').setValue(response.time);
      },
      (error) => {
        // Maneja los errores si es necesario
      }
    );
  }

}
