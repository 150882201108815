/**
 * OskPack.WebApi.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddAssigmentToDealerRQ } from '../model/addAssigmentToDealerRQ';
import { AddAssigmentToFlightRQ } from '../model/addAssigmentToFlightRQ';
import { CheckAsSeenRQ } from '../model/checkAsSeenRQ';
import { DeleteBillFromFlightRQ } from '../model/deleteBillFromFlightRQ';
import { EditFlightAssigmentRQ } from '../model/editFlightAssigmentRQ';
import { GetByFlightAssigmentIdRP } from '../model/getByFlightAssigmentIdRP';
import { GetByFlightAssigmentIdRQ } from '../model/getByFlightAssigmentIdRQ';
import { ListAssigmentByDealerRP } from '../model/listAssigmentByDealerRP';
import { ListAssigmentByDealerRQ } from '../model/listAssigmentByDealerRQ';
import { GetBillsForDispatchRQ } from './../model/getBillsForDispatchRQ';
import { AddDispatchToDealerRQ, AddDispatchToDealerRP } from './../model/addDispatchToDealerRQ';
import { ListAssigmentByShippingDealerRP } from '../model/listAssigmentByShippingDealerRP';
import { ListAssigmentByShippingDealerRQ } from '../model/listAssigmentByShippingDealerRQ';
import { ListAssignToDealerPagedRP } from '../model/listAssignToDealerRP';
import { ListAssigmentByShippingRP } from '../model/listAssigmentByShippingRP';
import { GetDealerAssignmentPagedRQ } from '../model/getDealerAssignmentPagedRQ';
import { ListAssigmentByShippingRQ } from '../model/listAssigmentByShippingRQ';
import { ListAssignToDealerRP } from '../model/listAssignToDealerRP';
import { GetDispatchByIdRQ, GetDispatchByIdRP } from '../model/getDispatchByIdRQ';
import { ListFlightAssigmentByAgencyRP } from '../model/listFlightAssigmentByAgencyRP';
import { ListFlightAssigmentByAgencyRQ } from '../model/listFlightAssigmentByAgencyRQ';
import { UpdateDispatchToDealerRQ, UpdateDispatchToDealerRP } from '../model/updateDispatchToDealerRQ';
import { ProcessScanedOrders, ChangeBillStateRP, ChangeBillStateRQ, ScannedBillsPreview, ScannedBill } from  '../model/processScanedOrders';   
import { FilterSearchModel } from '../../shared/models/filter-search.model';
import { ListReceivedByDealerRP } from '../model/listReceivedByDealerRP';
import { ListDispatchByParamsRP, ListDispatchByParamsViewRP, ListDispatchByParamsRQ } from '../model/listDispatchByParamsRQ';
import { DeleteDispatchRP, DeleteDispatchRQ, DeleteBillDispatchRP, DeleteBillDispatchRQ } from '../model/deleteDispatchRQ'
import { ListReceivedByDealerRQ } from '../model/listReceivedByDealerRQ';
import { ListReceivedInCubaRP } from '../model/listReceivedInCubaRP';
import { MarkAsDeliveryBashRQ } from '../model/markAsDeliveryBashRQ';
import { MarkAsDeliveryRQ } from '../model/markAsDeliveryRQ';
import { MarkAsReceivedBashRQ } from '../model/markAsReceivedBashRQ';
import { MarkAsReceivedByDealerBashRQ } from '../model/markAsReceivedByDealerBashRQ';
import { MarkAsReceivedByDealerRQ } from '../model/markAsReceivedByDealerRQ';
import { MarkAsReceivedRQ } from '../model/markAsReceivedRQ';
import { RollbackRQ } from '../model/rollbackRQ';
import { RoolbackReceivedInCubaBashRQ } from '../model/roolbackReceivedInCubaBashRQ';
import { RoolbackReceivedInCubaRQ } from '../model/roolbackReceivedInCubaRQ';
import { UnassignDealerRQ } from '../model/unassignDealerRQ';
import { Unit } from '../model/unit';
import { UpdateAssigmentToDealerRQ } from '../model/updateAssigmentToDealerRQ';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AssigmentService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentAddToDealerPost(body?: AddAssigmentToDealerRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiAssigmentAddToDealerPost(body?: AddAssigmentToDealerRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiAssigmentAddToDealerPost(body?: AddAssigmentToDealerRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiAssigmentAddToDealerPost(body?: AddAssigmentToDealerRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/Assigment/AddToDealer`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentAddToFlightPost(body?: AddAssigmentToFlightRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiAssigmentAddToFlightPost(body?: AddAssigmentToFlightRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiAssigmentAddToFlightPost(body?: AddAssigmentToFlightRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiAssigmentAddToFlightPost(body?: AddAssigmentToFlightRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/Assigment/AddToFlight`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }    

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentCheckAsSeenPost(body?: CheckAsSeenRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiAssigmentCheckAsSeenPost(body?: CheckAsSeenRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiAssigmentCheckAsSeenPost(body?: CheckAsSeenRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiAssigmentCheckAsSeenPost(body?: CheckAsSeenRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/Assigment/CheckAsSeen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentDeleteBillFromFlightPost(body?: DeleteBillFromFlightRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiAssigmentDeleteBillFromFlightPost(body?: DeleteBillFromFlightRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiAssigmentDeleteBillFromFlightPost(body?: DeleteBillFromFlightRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiAssigmentDeleteBillFromFlightPost(body?: DeleteBillFromFlightRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/Assigment/DeleteBillFromFlight`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentEditFlitghAssigmentPost(body?: EditFlightAssigmentRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiAssigmentEditFlitghAssigmentPost(body?: EditFlightAssigmentRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiAssigmentEditFlitghAssigmentPost(body?: EditFlightAssigmentRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiAssigmentEditFlitghAssigmentPost(body?: EditFlightAssigmentRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/Assigment/EditFlitghAssigment`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentGetByFlightAssigmentPost(body?: GetByFlightAssigmentIdRQ, observe?: 'body', reportProgress?: boolean): Observable<GetByFlightAssigmentIdRP>;
    public apiAssigmentGetByFlightAssigmentPost(body?: GetByFlightAssigmentIdRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetByFlightAssigmentIdRP>>;
    public apiAssigmentGetByFlightAssigmentPost(body?: GetByFlightAssigmentIdRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetByFlightAssigmentIdRP>>;
    public apiAssigmentGetByFlightAssigmentPost(body?: GetByFlightAssigmentIdRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GetByFlightAssigmentIdRP>('post',`${this.basePath}/api/Assigment/GetByFlightAssigment`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentListAssigmentByShippingDealerPost(body?: ListAssigmentByShippingDealerRQ, observe?: 'body', reportProgress?: boolean): Observable<ListAssigmentByShippingDealerRP>;
    public apiAssigmentListAssigmentByShippingDealerPost(body?: ListAssigmentByShippingDealerRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListAssigmentByShippingDealerRP>>;
    public apiAssigmentListAssigmentByShippingDealerPost(body?: ListAssigmentByShippingDealerRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListAssigmentByShippingDealerRP>>;
    public apiAssigmentListAssigmentByShippingDealerPost(body?: ListAssigmentByShippingDealerRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ListAssigmentByShippingDealerRP>('post',`${this.basePath}/api/Assigment/ListAssigmentByShippingDealer`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentListAssigmentByShippingPost(body?: ListAssigmentByShippingRQ, observe?: 'body', reportProgress?: boolean): Observable<ListAssigmentByShippingRP>;
    public apiAssigmentListAssigmentByShippingPost(body?: ListAssigmentByShippingRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListAssigmentByShippingRP>>;
    public apiAssigmentListAssigmentByShippingPost(body?: ListAssigmentByShippingRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListAssigmentByShippingRP>>;
    public apiAssigmentListAssigmentByShippingPost(body?: ListAssigmentByShippingRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ListAssigmentByShippingRP>('post',`${this.basePath}/api/Assigment/ListAssigmentByShipping`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentListAssignToDealerPost(body?: GetDealerAssignmentPagedRQ, observe?: 'body', reportProgress?: boolean): Observable<ListAssignToDealerPagedRP>;
    public apiAssigmentListAssignToDealerPost(body?: GetDealerAssignmentPagedRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListAssignToDealerPagedRP>>;
    public apiAssigmentListAssignToDealerPost(body?: GetDealerAssignmentPagedRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListAssignToDealerPagedRP>>;
    public apiAssigmentListAssignToDealerPost(body?: GetDealerAssignmentPagedRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ListAssignToDealerPagedRP>('post',`${this.basePath}/api/Assigment/ListAssignToDealer`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentListAssignToDealerGet(observe?: 'body', reportProgress?: boolean): Observable<ListAssignToDealerRP>;
    public apiAssigmentListAssignToDealerGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListAssignToDealerRP>>;
    public apiAssigmentListAssignToDealerGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListAssignToDealerRP>>;
    public apiAssigmentListAssignToDealerGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ListAssignToDealerRP>('get',`${this.basePath}/api/Assigment/ListAssignToDealer`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentGetDispatchByIdPost(body?: GetDispatchByIdRQ, observe?: 'body', reportProgress?: boolean): Observable<GetDispatchByIdRP>;
    public apiAssigmentGetDispatchByIdPost(body?: GetDispatchByIdRQ, observe?: 'body', reportProgress?: boolean): Observable<HttpResponse<GetDispatchByIdRP>>;
    public apiAssigmentGetDispatchByIdPost(body?: GetDispatchByIdRQ, observe?: 'body', reportProgress?: boolean): Observable<HttpEvent<GetDispatchByIdRP>>;
    public apiAssigmentGetDispatchByIdPost(body?: GetDispatchByIdRQ, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
       
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GetDispatchByIdRP>('post',`${this.basePath}/api/Assigment/GetDispatchById`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentListForDispatchPost(body?: GetBillsForDispatchRQ, observe?: 'body', reportProgress?: boolean): Observable<ListAssignToDealerRP>;
    public apiAssigmentListForDispatchPost(body?: GetBillsForDispatchRQ, observe?: 'body', reportProgress?: boolean): Observable<HttpResponse<ListAssignToDealerRP>>;
    public apiAssigmentListForDispatchPost(body?: GetBillsForDispatchRQ, observe?: 'body', reportProgress?: boolean): Observable<HttpEvent<ListAssignToDealerRP>>;
    public apiAssigmentListForDispatchPost(body?: GetBillsForDispatchRQ, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
       
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ListAssignToDealerRP>('post',`${this.basePath}/api/Assigment/ListBillForDispatch`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAddAssigmentForDispatchPost(body?: AddDispatchToDealerRQ, observe?: 'body', reportProgress?: boolean): Observable<AddDispatchToDealerRP>;
    public apiAddAssigmentForDispatchPost(body?: AddDispatchToDealerRQ, observe?: 'body', reportProgress?: boolean): Observable<HttpResponse<AddDispatchToDealerRP>>;
    public apiAddAssigmentForDispatchPost(body?: AddDispatchToDealerRQ, observe?: 'body', reportProgress?: boolean): Observable<HttpEvent<AddDispatchToDealerRP>>;
    public apiAddAssigmentForDispatchPost(body?: AddDispatchToDealerRQ, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
       
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AddDispatchToDealerRP>('post',`${this.basePath}/api/Assigment/AddDispatch`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public apiUpdateAssigmentForDispatchPost(body?: UpdateDispatchToDealerRQ, observe?: 'body', reportProgress?: boolean): Observable<UpdateDispatchToDealerRP>;
    public apiUpdateAssigmentForDispatchPost(body?: UpdateDispatchToDealerRQ, observe?: 'body', reportProgress?: boolean): Observable<HttpResponse<UpdateDispatchToDealerRP>>;
    public apiUpdateAssigmentForDispatchPost(body?: UpdateDispatchToDealerRQ, observe?: 'body', reportProgress?: boolean): Observable<HttpEvent<UpdateDispatchToDealerRP>>;
    public apiUpdateAssigmentForDispatchPost(body?: UpdateDispatchToDealerRQ, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
       
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UpdateDispatchToDealerRP>('post',`${this.basePath}/api/Assigment/UpdateDispatch`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentListByDealerPost(body?: ListAssigmentByDealerRQ, observe?: 'body', reportProgress?: boolean): Observable<ListAssigmentByDealerRP>;
    public apiAssigmentListByDealerPost(body?: ListAssigmentByDealerRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListAssigmentByDealerRP>>;
    public apiAssigmentListByDealerPost(body?: ListAssigmentByDealerRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListAssigmentByDealerRP>>;
    public apiAssigmentListByDealerPost(body?: ListAssigmentByDealerRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ListAssigmentByDealerRP>('post',`${this.basePath}/api/Assigment/ListByDealer`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    
    
/**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
public checkScanedOrdersPost(body?: ProcessScanedOrders, observe?: 'body', reportProgress?: boolean): Observable<ScannedBillsPreview>;
public checkScanedOrdersPost(body?: ProcessScanedOrders, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ScannedBillsPreview>>;
public checkScanedOrdersPost(body?: ProcessScanedOrders, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ScannedBillsPreview>>;
public checkScanedOrdersPost(body?: ProcessScanedOrders, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
        'text/plain',
        'application/json',
        'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
        'application/json',
        'text/json',
        'application/_*+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ScannedBillsPreview>('post',`${this.basePath}/api/Assigment/ScanOrders`,
        {
            body: body,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        }
    );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentListFlightsByAgencyPost(body?: ListFlightAssigmentByAgencyRQ, observe?: 'body', reportProgress?: boolean): Observable<ListFlightAssigmentByAgencyRP>;
    public apiAssigmentListFlightsByAgencyPost(body?: ListFlightAssigmentByAgencyRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListFlightAssigmentByAgencyRP>>;
    public apiAssigmentListFlightsByAgencyPost(body?: ListFlightAssigmentByAgencyRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListFlightAssigmentByAgencyRP>>;
    public apiAssigmentListFlightsByAgencyPost(body?: ListFlightAssigmentByAgencyRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ListFlightAssigmentByAgencyRP>('post',`${this.basePath}/api/Assigment/ListFlightsByAgency`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    
    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentListFlightsByParams(body?: FilterSearchModel, observe?: 'body', reportProgress?: boolean): Observable<ListFlightAssigmentByAgencyRP>;
    public apiAssigmentListFlightsByParams(body?: FilterSearchModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListFlightAssigmentByAgencyRP>>;
    public apiAssigmentListFlightsByParams(body?: FilterSearchModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListFlightAssigmentByAgencyRP>>;
    public apiAssigmentListFlightsByParams(body?: FilterSearchModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ListFlightAssigmentByAgencyRP>('post',`${this.basePath}/api/Assigment/ListFlightsByParams`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentListDealersByCodeBill(body?: FilterSearchModel, observe?: 'body', reportProgress?: boolean): Observable<ListAssignToDealerRP>;
    public apiAssigmentListDealersByCodeBill(body?: FilterSearchModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListAssignToDealerRP>>;
    public apiAssigmentListDealersByCodeBill(body?: FilterSearchModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListAssignToDealerRP>>;
    public apiAssigmentListDealersByCodeBill(body?: FilterSearchModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ListAssignToDealerRP>('post',`${this.basePath}/api/Assigment/ListDealerAssigmentsByCode`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

/**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
public apiAssigmentListDealersByParams(body?: FilterSearchModel, observe?: 'body', reportProgress?: boolean): Observable<ListAssignToDealerRP>;
public apiAssigmentListDealersByParams(body?: FilterSearchModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListAssignToDealerRP>>;
public apiAssigmentListDealersByParams(body?: FilterSearchModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListAssignToDealerRP>>;
public apiAssigmentListDealersByParams(body?: FilterSearchModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
        'text/plain',
        'application/json',
        'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
        'application/json',
        'text/json',
        'application/_*+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ListAssignToDealerRP>('post',`${this.basePath}/api/Assigment/ListDealersByParams`,
        {
            body: body,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        }
    );
}
    
    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentListReceivedByDealerPost(body?: ListReceivedByDealerRQ, observe?: 'body', reportProgress?: boolean): Observable<ListReceivedByDealerRP>;
    public apiAssigmentListReceivedByDealerPost(body?: ListReceivedByDealerRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListReceivedByDealerRP>>;
    public apiAssigmentListReceivedByDealerPost(body?: ListReceivedByDealerRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListReceivedByDealerRP>>;
    public apiAssigmentListReceivedByDealerPost(body?: ListReceivedByDealerRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ListReceivedByDealerRP>('post',`${this.basePath}/api/Assigment/ListReceivedByDealer`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDispatchListViewPost(body?: ListDispatchByParamsRQ, observe?: 'body', reportProgress?: boolean): Observable<ListDispatchByParamsViewRP>;
    public apiDispatchListViewPost(body?: ListDispatchByParamsRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListDispatchByParamsViewRP>>;
    public apiDispatchListViewPost(body?: ListDispatchByParamsRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListDispatchByParamsViewRP>>;
    public apiDispatchListViewPost(body?: ListDispatchByParamsRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ListDispatchByParamsViewRP>('post',`${this.basePath}/api/Assigment/DispatchListView`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDispatchListPost(body?: ListDispatchByParamsRQ, observe?: 'body', reportProgress?: boolean): Observable<ListDispatchByParamsRP>;
    public apiDispatchListPost(body?: ListDispatchByParamsRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListDispatchByParamsRP>>;
    public apiDispatchListPost(body?: ListDispatchByParamsRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListDispatchByParamsRP>>;
    public apiDispatchListPost(body?: ListDispatchByParamsRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ListDispatchByParamsRP>('post',`${this.basePath}/api/Assigment/DispatchList`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public apiDispatchBillDeletePost(body?: DeleteBillDispatchRQ, observe?: 'body', reportProgress?: boolean): Observable<DeleteBillDispatchRP>;
    public apiDispatchBillDeletePost(body?: DeleteBillDispatchRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeleteBillDispatchRP>>;
    public apiDispatchBillDeletePost(body?: DeleteBillDispatchRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeleteBillDispatchRP>>;
    public apiDispatchBillDeletePost(body?: DeleteBillDispatchRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DeleteBillDispatchRP>('post',`${this.basePath}/api/Assigment/DeleteBillDispatch`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDeleteDispatchPost(body?: DeleteDispatchRQ, observe?: 'body', reportProgress?: boolean): Observable<DeleteDispatchRP>;
    public apiDeleteDispatchPost(body?: DeleteDispatchRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeleteDispatchRP>>;
    public apiDeleteDispatchPost(body?: DeleteDispatchRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeleteDispatchRP>>;
    public apiDeleteDispatchPost(body?: DeleteDispatchRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DeleteDispatchRP>('post',`${this.basePath}/api/Assigment/DeleteDispatch`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentListReceivedInCubaGet(observe?: 'body', reportProgress?: boolean): Observable<ListReceivedInCubaRP>;
    public apiAssigmentListReceivedInCubaGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListReceivedInCubaRP>>;
    public apiAssigmentListReceivedInCubaGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListReceivedInCubaRP>>;
    public apiAssigmentListReceivedInCubaGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ListReceivedInCubaRP>('get',`${this.basePath}/api/Assigment/ListReceivedInCuba`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentMarkAsDeliveryBashPost(body?: MarkAsDeliveryBashRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiAssigmentMarkAsDeliveryBashPost(body?: MarkAsDeliveryBashRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiAssigmentMarkAsDeliveryBashPost(body?: MarkAsDeliveryBashRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiAssigmentMarkAsDeliveryBashPost(body?: MarkAsDeliveryBashRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/Assigment/MarkAsDeliveryBash`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentMarkAsDeliveryPost(body?: MarkAsDeliveryRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiAssigmentMarkAsDeliveryPost(body?: MarkAsDeliveryRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiAssigmentMarkAsDeliveryPost(body?: MarkAsDeliveryRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiAssigmentMarkAsDeliveryPost(body?: MarkAsDeliveryRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/Assigment/MarkAsDelivery`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentMarkAsReceivedBashPost(body?: MarkAsReceivedBashRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiAssigmentMarkAsReceivedBashPost(body?: MarkAsReceivedBashRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiAssigmentMarkAsReceivedBashPost(body?: MarkAsReceivedBashRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiAssigmentMarkAsReceivedBashPost(body?: MarkAsReceivedBashRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/Assigment/MarkAsReceivedBash`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentMarkAsReceivedByDealerBashPost(body?: MarkAsReceivedByDealerBashRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiAssigmentMarkAsReceivedByDealerBashPost(body?: MarkAsReceivedByDealerBashRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiAssigmentMarkAsReceivedByDealerBashPost(body?: MarkAsReceivedByDealerBashRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiAssigmentMarkAsReceivedByDealerBashPost(body?: MarkAsReceivedByDealerBashRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/Assigment/MarkAsReceivedByDealerBash`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentMarkAsReceivedByDealerPost(body?: MarkAsReceivedByDealerRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiAssigmentMarkAsReceivedByDealerPost(body?: MarkAsReceivedByDealerRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiAssigmentMarkAsReceivedByDealerPost(body?: MarkAsReceivedByDealerRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiAssigmentMarkAsReceivedByDealerPost(body?: MarkAsReceivedByDealerRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/Assigment/MarkAsReceivedByDealer`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentMarkAsReceivedPost(body?: MarkAsReceivedRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiAssigmentMarkAsReceivedPost(body?: MarkAsReceivedRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiAssigmentMarkAsReceivedPost(body?: MarkAsReceivedRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiAssigmentMarkAsReceivedPost(body?: MarkAsReceivedRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/Assigment/MarkAsReceived`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentRollbackPost(body?: RollbackRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiAssigmentRollbackPost(body?: RollbackRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiAssigmentRollbackPost(body?: RollbackRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiAssigmentRollbackPost(body?: RollbackRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/Assigment/Rollback`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentRoolbackReceivedInCubaBashPost(body?: RoolbackReceivedInCubaBashRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiAssigmentRoolbackReceivedInCubaBashPost(body?: RoolbackReceivedInCubaBashRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiAssigmentRoolbackReceivedInCubaBashPost(body?: RoolbackReceivedInCubaBashRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiAssigmentRoolbackReceivedInCubaBashPost(body?: RoolbackReceivedInCubaBashRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/Assigment/RoolbackReceivedInCubaBash`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentRoolbackReceivedInCubaPost(body?: RoolbackReceivedInCubaRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiAssigmentRoolbackReceivedInCubaPost(body?: RoolbackReceivedInCubaRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiAssigmentRoolbackReceivedInCubaPost(body?: RoolbackReceivedInCubaRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiAssigmentRoolbackReceivedInCubaPost(body?: RoolbackReceivedInCubaRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/Assigment/RoolbackReceivedInCuba`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentUnassingDealerPost(body?: UnassignDealerRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiAssigmentUnassingDealerPost(body?: UnassignDealerRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiAssigmentUnassingDealerPost(body?: UnassignDealerRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiAssigmentUnassingDealerPost(body?: UnassignDealerRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/Assigment/UnassingDealer`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssigmentUpdateToDealerPost(body?: UpdateAssigmentToDealerRQ, observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiAssigmentUpdateToDealerPost(body?: UpdateAssigmentToDealerRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiAssigmentUpdateToDealerPost(body?: UpdateAssigmentToDealerRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiAssigmentUpdateToDealerPost(body?: UpdateAssigmentToDealerRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Unit>('post',`${this.basePath}/api/Assigment/UpdateToDealer`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
