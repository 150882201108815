/**
 * OskPack.WebApi.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CurrencyDealerModel } from './currencyDealerModel';
import { DealerTaxModel } from './listDealerTaxRQ';
import { DealerCityModel } from './dealerCityModel';

export interface DealerModel { 
    id?: number;
    parentId?: number;
    userId?: number;
    cityId?: number;
    userName?: string;
    userUserName?: string;
    userEmail?: string;
    userPhone?: string;
    address?: string;
    location?: string;
    currencies?: Array<CurrencyDealerModel>;
    cities?: Array<DealerCityModel>;
}

export interface DealersModelExport {        
    Repartidor?: string;   
    Provincia?: string;  
    Ciudades_Asignadas?: Array<string>;       
}

export interface DealersModel { 
    id?: number;    
    userName?: string;   
    location?: string;  
    currencies?: Array<CurrencyDealerModel>;  
    dealerTaxs?: Array<DealerTaxModel>;  
}

export interface DealersModelDetails { 
    id?: number;    
    userName?: string;    
    isActive?: boolean;   
}