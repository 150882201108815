import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyDealerModel, DealerService, GetCurrencyDealerSettingRQ, ListCurrencyDealerSettingRQ } from 'app/open-api';
import { NgxSpinnerService } from 'ngx-spinner';
import { ShippingType, shippingTypes } from '../../../open-api/model/shippingType';

@Component({
	selector: 'app-configure-currency-dealer-modal',
	templateUrl: './configure-currency-dealer-modal.component.html',
	styleUrls: ['./configure-currency-dealer-modal.component.scss']
})
export class ConfigureCurrencyDealerModalComponent implements OnInit {

	@Input() dealerId: number;
	currenciesDealer: CurrencyDealerModel[] = [];
	shippingTypes: Array<ShippingType> = [];
    selectedShippingType: string;

	constructor(
		public activeModal: NgbActiveModal,
		private dealerService: DealerService,
		private spinner: NgxSpinnerService) { 
			this.shippingTypes = shippingTypes;
		}

	async ngOnInit(): Promise<void> {
		await this.GetCurrenciesDealer();
	}

	async GetCurrenciesDealer(): Promise<void> {

		const params: GetCurrencyDealerSettingRQ = {
			dealerId: this.dealerId
		};
		this.spinner.show();
		await this.dealerService.apiDealerGetCurrencyDealerSettingPost(params).toPromise().then(response => {
			this.currenciesDealer = response.list;
			this.validatePrincipalCurrency();
			this.spinner.hide();
		});

	}

	validatePrincipalCurrency() {
		let currenciesArray = this.currenciesDealer.filter((currency) => {
			if(currency.principal) {
				return currency;
			}
		});

		if(currenciesArray.length == 0)
		{
			this.currenciesDealer[0].principal = true;
		}
	}


	changePrincipal(currencyId) {
		this.currenciesDealer = this.currenciesDealer.map(currency => {
			return { ...currency, principal: false };
		})
		this.currenciesDealer = this.currenciesDealer.map(currency => {
			if (currency.currencyId == currencyId)
				return { ...currency, principal: true };
			return currency
		})
	}

	SaveCurrenciesDealer() {
		const params: ListCurrencyDealerSettingRQ = {
			list: this.currenciesDealer
		};
		this.spinner.show();
		this.dealerService.apiDealerSaveCurrencyDealerSettingPost(params).subscribe(response => {
			this.spinner.hide();
			this.activeModal.close();
		},
			error => {
				this.spinner.hide();
				this.activeModal.close();
			});
	}
}
