<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel1">Envíos procesados </h4>
  <button type="button" class="close" (click)="activeModal.close()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
  <div class="row">
    <div class="col">
     <table class="table table-striped pt-2">
       <thead>
         <tr>
           <th class="text-center" scope="col">#</th>
           <th class="text-center" scope="col">No envío</th>
           <th class="text-center" scope="col">Fecha</th>
           <th class="text-center" scope="col">Estado</th>
           <th class="text-center" scope="col">Detalles</th>           
         </tr>
         <tr *ngIf="allOrders?.length === 0">
           <th class="text-center" scope="col" colspan="7">
             <label>Sin información</label>
           </th>
         </tr>
       </thead>
       <tbody>
         <tr *ngFor="let oper of alloperations; let i = index">
           <th class="text-center" scope="row">{{i + 1}}</th>
           <td class="text-center">{{oper?.shipmentId}}</td>
           <td class="text-center">{{oper?.createAt | date:"medium" }}</td>
           <td class="text-center">{{oper?.operationStatus }}</td>
           <td class="text-center"></td>           
         </tr>
       </tbody>
     </table>
     <div class="d-flex justify-content-between p-2" *ngIf="allOrders.length > 0">
      <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" [maxSize]="4"
        [rotate]="true" [boundaryLinks]="true">
      </ngb-pagination>
      <select class="custom-select" style="width: auto" [(ngModel)]="pageSize">
        <option [ngValue]="1">1 órden por página</option>
        <option [ngValue]="5">5 ordenes por página</option>
        <option [ngValue]="10">10 ordenes por página</option>
      </select>
    </div>
    </div>
   </div>
</div>
<div class="modal-footer">
  <div class="d-flex justify-content-end align-items-center">
  </div>
</div>

