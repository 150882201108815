/**
 * OskPack.WebApi.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ShippingExpenseListModel { 
    id?: number;
    shippingId?: number;
    expenseCatalogId?: number;
    shippingCode?: string;
    description?: string;
    expenseCatalogName?: string;
    expenseCatalogColor?: string;
    expenseCatalogIcon?: string;
    shippingCreateAt?: Date;
    cost?: number;
}