/**
 * OskPack.WebApi.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AssigmentDealerModel { 
    id?: number;
    dealerId?: number;
    billId?: number;
    currencyId?: number;
    taxCurrencyId?: number;
    taxShippingType?: number;
    taxType?: number;
    taxId?: number;
    stateCatalogId?: number;
    billSenderModel?: string;
    billCustomerModel?: string;
    billCode?: string;
    serviceCode?: string;
    dealerName?: string;
    billStateCatalogName?: string;
    currencyName?: string;
    billFile?: string;
    flightCode?: string;
    shippingCode?: string;
    shippingType?: number;
    billPounds?: number;
    billPrice?: number;
    billServices?: number;
    billTotal?: number;
    pounds?: number;
    price?: number;
    services?: number;
    total?: number;
    hasBeenSeen?: boolean;
    billCreateAt?: Date;
    billUpdateAt?: Date;
    inUpdate?: boolean;
    isInDispatch?: boolean;
}