<div class="modal-header">
  <h4 class="modal-title">Detalle del Cierre!</h4>
</div>
<div #modalPrint id="modalPrint" class="modal-body">
  <div class="row">
    <div class="col-4">
      <h3 class="text-center"><b>Repartidor: </b>{{ data?.dealerName }}</h3>
    </div>
    <div class="col-4">
      <h3 class="text-center"><b>Envío: </b>{{ data?.shippingCode }}</h3>
    </div>
    <div class="col-4">
      <h3 class="text-center">
        <b>Fecha: </b>{{ data?.createdAt | date : "dd/MM/yyyy" }}
      </h3>
    </div>
    <div class="col-12">
      <div class="divider">
        <div class="divider-text">Facturas</div>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-3 text-center fw-bold">FACTURA</div>
        <div class="col-2 text-center fw-bold">PESO(LBS)</div>
        <div class="col-2 text-center fw-bold">PRECIO x LIBRA</div>
        <div class="col-3 text-center fw-bold">COMISIÓN</div>
        <div class="col-2 text-center fw-bold">MONEDA</div>
        <ng-container *ngFor="let assignment of data?.billIds">
          <div class="col-3 text-center">{{ assignment?.billCode }}</div>
          <div class="col-2 text-center">{{ assignment?.billPounds }}</div>
          <div class="col-2 text-center">
            {{ assignment?.price | currency }}
          </div>
          <div class="col-3 text-center">
            {{ assignment?.total | currency }}
          </div>
          <div class="col-2 text-center">{{ assignment?.currencyName }}</div>
        </ng-container>
      </div>
    </div>
    <div class="col-12">
      <div class="divider">
        <div class="divider-text">Resumen de las Facturas</div>
      </div>
    </div>
    <div class="row w-100 mx-16">
      <div class="col-3"><b>Total de Facturas: </b>{{ data?.billsTotal }}</div>
      <div class="col-3"><b>Total de Libras: </b>{{ totalPound }}</div>
      <ng-container *ngFor="let resumen of currenciesResumen">
        <div class="col-3">
          <b>{{ resumen?.currencyName }}: </b
          >{{ resumen?.currencyTotal | currency }}
        </div>
      </ng-container>
    </div>    
    <!-- <div class="col-12">
      <div class="divider">
        <div class="divider-text">Resumen del Repartidor</div>
      </div>
    </div>
    <ng-container *ngFor="let resumen of currenciesResumen">
      <div class="col-3">
        <b>{{ resumen?.currencyName }}: </b
        >{{ resumen?.currencyTotal | currency }}
      </div>
    </ng-container> -->
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="exportAsPDF()">
    Imprimir
  </button>
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close()"
  >
    Cerrar
  </button>
</div>
