import { Component, OnInit, Input } from "@angular/core";
import { BillOperationRP, GetBillOperationsRP, BillOperationDto } from "app/open-api/model/billOperation";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { BillService } from 'app/open-api';
import Swal from 'sweetalert2';
import {LoadExternalOperationDto,LoadExternalOperation, GetExternalOperationsRQ } from "app/open-api/model/loadExternalOperationDto";

@Component({
  selector: 'app-load-external-operations-modal',
  templateUrl: './load-external-operations-modal.component.html',
  styleUrls: ['./load-external-operations-modal.component.scss'],
  providers: [ DatePipe]
})
export class LoadExternalOperationsModalComponent implements OnInit {

  operations: LoadExternalOperationDto[];
  @Input() name: string;
  allOrders: LoadExternalOperation[];
  page = 1;
  pageSize = 10;
  collectionSize = 0;
  
  constructor(public activeModal: NgbActiveModal,private datePipe: DatePipe, private spinner: NgxSpinnerService, private _billService: BillService) { }

  ngOnInit() {
    this.getTemporaryOperations();
  }

  fetchOperations(){
    //console.log("opera", this.operations)
    this.allOrders = [];
    if(this.operations){
      this.operations.forEach((row) => {
        let now: LoadExternalOperation = this.GetOperationFromRQ(row);
  
        this.allOrders = this.allOrders.concat(now);
      });  
      this.collectionSize = this.allOrders.length;
    }
    
  }

  getTemporaryOperations(){
    this.operations = [];
    this.allOrders = [];

    this.spinner.show();
    const param: GetExternalOperationsRQ = {
      
    };

    this._billService.getExternalOperations(param).subscribe(
      (response) => {
        if(response){
          console.log(response)

          this.operations = response;

          this.operations.forEach((row) => {
            let now: LoadExternalOperation = this.GetOperationFromRQ(row);
      
            this.allOrders = this.allOrders.concat(now);
          });  
          this.collectionSize = this.allOrders.length;
        }
        console.log(this.allOrders)
       // this.operations = response;
              
        this.spinner.hide();
      },
      (error) => {
        //this.currentStep = 1;       

        Swal.fire({
          title: "Ha occurrido un error inesperado al obtener las operaciones.",
          icon: "error",
          showConfirmButton: false,
          timer: 5000,
          position: "top",
        });
        this.spinner.hide();
      }
    );
    
  }
  

  get alloperations(): LoadExternalOperation[] {    
    return this.allOrders.length > 0 ? this.allOrders
      .map((agency, i) => ({ id: i + 1, ...agency }))
      .slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize
      ) : [];   
}  

  GetOperationFromRQ(order: LoadExternalOperationDto) {    
    let ret: LoadExternalOperation = {
      id: order.id,
      createAt: order.createAt,
      details: order.details,
      shipmentId: order.shipmentId,
      totalErrors: order.totalErrors,
      totalRecords: order.totalRecords,
      totalSuccess: order.totalSuccess,
      userId: order.userId,
      operationStatus: ""      
    };
    let status = "";
    switch (order.status) {      
      case 1:
        status = "Procesada";
        break;
      case 2:
        status = "Error";
        break;      
      default:
        console.log("Lo lamentamos, por el momento no disponemos de ");
    }     
    ret.operationStatus = status;
    return ret;
  }

}



