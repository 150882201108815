<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel1">Ciudades del distribuidor: <b>{{dealerName}}</b>  </h4>
  <button type="button" class="close" (click)="activeModal.close()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
  <div class="card">
    <div class="card-header">
      <h5 class="card-title">Ciudades asignadas</h5>
    </div>
    <div class="card-body">
    <div *ngIf="noCitiesAssigned; else citiesAssigned">
      <p>No hay ciudades asignadas.</p>
    </div>
    <ng-template #citiesAssigned>     
      <div class="ml-1" *ngFor="let state of (this.groupedCities | keyvalue)">
        <b> {{ state.key }}</b>
        {{ GetCityNamesByState(state.value) }}
      </div>
    </ng-template>
  </div>
  </div>
  <div class="card">
    <div class="card-body">
  <form [formGroup]="assigmentForm" id="accountInformationForm">
    <div class="row" style="padding-inline: 10px">
      <div class="col-md-6">        
        <ng-select #provincias (clear)="resetProvincias();" placeholder="Provincias" [clearable]="true" notFoundText="No hay datos" bindValue="id" [items]="states" bindLabel="name"
        formControlName="provincia" (change)="OnStateChange()">
                                                  </ng-select>
        <!--<select #provincias id="provincia" class="form-control" formControlName="provincia" (change)="OnStateChange($event)">
          <option [value]="null">Seleccionar</option>       
          <option *ngFor="let state of states" value="{{state.id}}">{{state.name}}</option>
        </select>-->
      
      </div> 
      <div class="col-md-6 " >
          <button type="button" [disabled]="this.cities.length == 0" class="btn btn-primary float-right" (click)="Guardar()" rippleEffect
              >              
              <span class="ml-25 align-middle">Guardar</span>
          </button>
      </div>
  </div>
  <div class="row">
    <div class="col-12 pt-2" *ngIf="this.cities.length > 0">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col-11" class="col-11">Municipios</th>
            <th scope="col-1" class="text-center cursor-pointer align-items-center col-1">
              <div class="d-flex align-items-center"><input type="checkbox" id="input-checkbox-all" class="checkbox-list pl-2 ml-2" (click)="CheckAllCheckbox()"></div>
              
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let city of cities">
            <td scope="col-11" class="col-11">
              {{city?.name}}
            </td>
            <td class="text-center align-items-center col-1" scope="col-1">
              <div class="d-flex align-items-center"><input type="checkbox" class="checkbox-list ml-2" [checked]="isCityMarked(city.id)" (change)="toggleCity(city.id)"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  </form>
</div></div>
</div>
<div class="modal-footer">
</div>
