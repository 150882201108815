import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import {FilterSearchObj} from './../models/filter-search.model';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
   
   private currentFilter: BehaviorSubject<FilterSearchObj|undefined> = new BehaviorSubject<FilterSearchObj|undefined>(undefined)

   get getFilterObservable() {
    return this.currentFilter.asObservable()
  }

  set setFilterObservable(filter) {
    this.currentFilter.next(filter)
  }

    
}
