<!-- vertical-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'vertical'">
  <vertical-layout></vertical-layout>
</ng-container>
<!-- / vertical-layout -->

<!-- horizontal-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'horizontal'">
  <horizontal-layout></horizontal-layout>
  <app-toast-custom></app-toast-custom>
</ng-container>
<!-- / horizontal-layout -->
<ngx-spinner bdColor="rgba(0,0,0,0.9)" size="large" color="#28a745" type="ball-scale-multiple" [fullScreen]="true">
  <p style="color: white"> Cargando ... </p>
</ngx-spinner>