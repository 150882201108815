import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { DealerService, CurrencyService, ListCurrenciesRP, CurrencyModel } from 'app/open-api';
import {
  CityModel,
  ListFlightByShippingRQ,
  ShippingService,
  FlightService,
  ShippingListModel,
  FlightListModel,
  CityService,
  ListCityByStateRQ,
  StateModel,
  StateService,
  ListStateRQ,
} from "app/open-api";
import { DealerCityModel } from 'app/open-api/model/dealerCityModel';
import { UpdateDealerCitiesRQ } from 'app/open-api/model/updateDealerCitiesRQ';

@Component({
  selector: 'app-dealer-cities',
  templateUrl: './dealer-cities.component.html',
  styleUrls: ['./dealer-cities.component.scss']
})
export class DealerCitiesComponent implements OnInit {
  @Input() dealerId: number; 
  @Input() dealerName: string;
  dealerCities: DealerCityModel[];
  assigmentForm: FormGroup; 
  selectedProvince: StateModel;
  selectedMunicipality: string = "";
  states: StateModel[] = [];
  cities: CityModel[] = [];
  assignedCities: CityModel[] = [];
  markedCityIds: number[] = [];
  noCitiesAssigned: boolean = false;
  hasChangeOnView: boolean = false;
  isAllChequed?: boolean;
  groupedCities?: any;  

  constructor(
    public activeModal: NgbActiveModal, 
    private spinner: NgxSpinnerService, 
    private dealerService: DealerService,
    private fb: FormBuilder,
    private stateService: StateService,
    private cityService: CityService,
  ) {
    this.assigmentForm = fb.group({
      provincia: [null],      
    });
   }

   async ngOnInit() : Promise<void> {
    this.spinner.show();
    await this.FetchState();   
    await this.getDealerCities(this.dealerId); 
    await this.GetDealerCities();
    this.spinner.hide();
  }

  async FetchState(): Promise<void> {
    let params: ListStateRQ = {
      type: 1
    }
    await this.stateService
      .apiStateListGet(params)
      .toPromise()
      .then((response) => {
        this.states = response.list;
      })
      .catch((error) => {});
  }

  GetCityNamesByState(cities: any[]): string{    
    return cities.map(city => city.name).join(', ');
  }

  async GetDealerCities() : Promise<void>{
    if(this.dealerCities.length > 0){
      this.dealerCities.map(city => city.name).join(', ');

      this.groupedCities = this.groupBy(
        this.dealerCities,
        "stateName"
      );
    }
    else this.noCitiesAssigned = true;   
   
  }

  groupBy(data: any[], key: string) {
    return data.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  }  

  CheckAllCheckbox() {
    const checkbox = document.getElementsByClassName("checkbox-list");
    const checkboxAll = document.getElementById("input-checkbox-all");
    if ((checkboxAll as HTMLInputElement).checked) {
      this.states.find(
        (value) => value.id == this.selectedProvince.id
      )!.isAllChequed = true;      
      this.cities.forEach((item) => {
        if (!this.isCityMarked(item.id)) {
          this.markedCityIds.push(item.id);
        }
      });
      
      //this.markedCityIds = [...this.cities.map((m) => m.id)];
      for (let i = 0; i < checkbox.length; i++) {
        (checkbox[i] as HTMLInputElement).checked = true;
      }
    } else {
      this.states.find(
        (value) => value.id == this.selectedProvince.id
      )!.isAllChequed = false;

      this.cities.forEach((item) => {
        const index = this.markedCityIds.indexOf(item.id);
        if (index !== -1) {
          this.markedCityIds.splice(index, 1);
        }
      });

      for (let i = 0; i < checkbox.length; i++) {
        (checkbox[i] as HTMLInputElement).checked = false;
      }
    }
  }

  CleacCheckbox() {
    const checkbox = document.getElementsByClassName('checkbox-list');
    for (let i = 0; i < checkbox.length; i++) {
      (checkbox[i] as HTMLInputElement).checked = false;
    }
  }

  async Guardar() {
    this.spinner.show();
    const request: UpdateDealerCitiesRQ = {
      dealerId: this.dealerId,
      cityIds: this.markedCityIds,
    };
    
    // Envía la lista de IDs al servidor para actualizar las ciudades relacionadas con el dealer
    this.dealerService.updateDealerCities(request).subscribe((response) => {
      //this.ShowToast('Ciudades actualizadas');
      Swal.fire({
        title: "Los municipios se asignaron correctamente.",
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
        position: "top-end",
      });
      this.getDealerCities(this.dealerId);
      this.spinner.hide();
    });
  }

  isCityMarked(cityId: number): boolean {    
    return this.markedCityIds.includes(cityId);
  }

  toggleCity(cityId: number) {
    if (this.isCityMarked(cityId)) {
      // Desmarca el municipio y elimina su ID de la matriz
      this.unmarkCity(cityId);
    } else {
      // Marca el municipio y agrega su ID a la matriz
      this.markCity(cityId);
    }
  }

  markCity(cityId: number) {
    if (!this.isCityMarked(cityId)) {
      this.markedCityIds.push(cityId);
    }
  }

  unmarkCity(cityId: number) {
    const index = this.markedCityIds.indexOf(cityId);
    if (index !== -1) {
      this.markedCityIds.splice(index, 1);
    }
  }

  async FetchCities() {
    /*if (
      this.currentFilter.provincia != null &&
      this.currentFilter.provincia != ""
    ) {
      const param: ListCityByStateRQ = {
        stateId: +this.currentFilter.provincia,
      };
      await this.cityService
        .apiCityListByStatePost(param)
        .toPromise()
        .then((res) => {
          this.cities = res.list;
        });
    }*/
  }

   async OnStateChange() {
       if (this.assigmentForm.get("provincia").value != null) {
        const checkboxAll = document.getElementById('input-checkbox-all');
        //if(checkboxAll)
        //(checkboxAll as HTMLInputElement).checked = false;
         //this.selectedMunicipality = "";
         //this.filterForm.get("municipio").setValue(null);
         this.selectedProvince = this.states.find(
          (i) => i.id === +this.assigmentForm.get("provincia").value
        );
         
        if(this.selectedProvince){
          if(this.selectedProvince.isAllChequed){
            if(checkboxAll)
              (checkboxAll as HTMLInputElement).checked = true;
          }            
          else {
            if(checkboxAll)
            (checkboxAll as HTMLInputElement).checked = false;
          } 
        }
   
         const param: ListCityByStateRQ = {
           stateId: this.assigmentForm.get("provincia").value,
         };
         this.cityService.apiCityListByStatePost(param).subscribe((res) => {
           this.cities = res.list;           
         });
         
       } else {
         this.selectedProvince = {};
         this.cities = [];
         this.selectedMunicipality = "";
         //this.assigmentForm.get("municipio").setValue(null);
       }
     }

     getStateName(stateId: number): string {
      const state = this.states.find(s => s.id === stateId);
      return state ? state.name : '';
    }

     async getDealerCities(dealerId: number): Promise<void> {
       this.spinner.show();
       await this.dealerService
         .getDealerCities(dealerId)
         .toPromise()
         .then((reponse) => {
           if (reponse) {
             this.markedCityIds = reponse.cities?.map((city) => city.id);
             this.assignedCities = reponse.cities.map((city) => ({
               id: city.id,
               //stateId: city?.stateId,
               //stateName: this.getStateName(city?.stateId), // Obtener el nombre del estado
               name: city.name,
             }));

             this.dealerCities = reponse.cities;
             this.noCitiesAssigned = this.assignedCities.length === 0;
             this.spinner.hide();
           }
         });
     }

   resetProvincias() {
    /*this.filterForm.controls["provincia"].setValue(null);
    this.filterForm.controls["municipio"].setValue(null);
    this.cities = [];
    this.selectedProvince = "";
    this.selectedMunicipality = "";

    if(this.currentFilter != undefined)
    if (
      this.currentFilter.provincia != null &&
      this.currentFilter.provincia != ""
    ) this.applyFilters();*/
   
  }
  

}
