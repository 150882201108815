export interface ExternalAgency {
  id?: number;
  name?: string;
}

export const externalAgencies: ExternalAgency[] = [  
  {
    id: 32,
    name: "Gran Blue, INC - APACARGOEXPRESS",
  },
  {
    id: 110,
    name: "Brother's Envios - Gran Blue, INC",
  },
];
