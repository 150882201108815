export interface ShippingType {
    id?: number;
    name?: string;
}

export const shippingTypes: ShippingType[] = [
    {
      id: 1,
      name: "Regular",
    },
    {
      id: 2,
      name: "Carga Aérea",
    },
    {
      id: 3,
      name: "Marítimo",
    },
  ]
